import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { Backdrop } from '@mui/material';
import moment from 'moment/moment';

import {
  default as CustomCircularLoader,
  default as CustomLoader,
} from '../components/CommonComponents/CustomLoader';
import { getAuthority } from '../components/LandingPage';
import { ROUTENAME } from '../constants/RoutesConstants';

const AuthLayout = ({ children }) => {
  const { instance, accounts } = useMsal();

  const { isAuthSuccess, isAuthLoading } = useSelector((state) => state.common);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (accounts?.length) {
      setIsAuthenticated(true);
    }
    setIsLoading(false);
  }, [accounts]);

  useEffect(() => {
    const originalSignInAccount = instance.getAllAccounts();

    const callbackId = setInterval(() => {
      const loginType = localStorage.getItem('loginType');
      const userInfo = JSON.parse(localStorage.getItem('user_info'));

      let signUpSignInFlowRequest = {
        authority: getAuthority(loginType),
        account: originalSignInAccount?.find(
          (acc) => acc?.username === userInfo?.email
        ),
      };

      const expirationTime = localStorage.getItem('expirationTime');
      const expDate = moment(expirationTime * 1000);
      const currentTime = moment();
      const timeDifferenceInSeconds = expDate.diff(currentTime, 'seconds');

      if (timeDifferenceInSeconds <= 60) {
        instance
          .ssoSilent(signUpSignInFlowRequest)
          .then((res) => {
            localStorage.setItem('idToken', res?.idToken);
            localStorage.setItem('expirationTime', res?.idTokenClaims?.exp);
          })
          .catch((err) => console.log('ERROR--', err));
      }
    }, 10000);

    return () => {
      clearInterval(callbackId);
    };
  }, [accounts]);

  if (!isAuthSuccess && isAuthLoading) {
    return (
      <Backdrop
        sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
        open={isAuthLoading}
      >
        <CustomCircularLoader />
      </Backdrop>
    );
  }

  return isLoading ? (
    <CustomLoader />
  ) : !isLoading && isAuthenticated ? (
    children
  ) : (
    <Navigate to={ROUTENAME.DEFAULT_ROUTE} />
  );
};

export default AuthLayout;
