import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, Divider, styled, Typography } from '@mui/material';

import { validateAddress } from '../../constants/common';
import { Validation } from '../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../constants/Regex';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import { getSuperAdminById } from '../../store/superAdmin/api';
import { formattedDate } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const SuperAdminForm = ({
  id,
  clearErrors,
  reset,
  control,
  trigger,
  isGlobalAdmin,
  companylistOptions,
  watch,
  setValue,
  isCompanyNameLoading,
  setServerErrors,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, editSuperAdmin } = useSelector(
    (state) => state.superAdmin.edit
  );

  const selectedState = watch('state');

  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);

  useEffect(() => {
    if (id) {
      dispatch(getSuperAdminById(id));
    }
  }, []);

  useEffect(() => {
    if (!isLoading && editSuperAdmin && id) {
      reset({
        uuid: editSuperAdmin.uuid,
        name: editSuperAdmin.name,
        cell_phone: editSuperAdmin.cell_phone,
        email: editSuperAdmin.email,
        address: editSuperAdmin.address,
        state: { label: editSuperAdmin.state, value: editSuperAdmin.state },
        city: { label: editSuperAdmin.city, value: editSuperAdmin.city },
        zip_code: editSuperAdmin.zip_code,
        company_name: {
          label: editSuperAdmin.company_name,
          value: editSuperAdmin.company_uuid,
        },
        active: editSuperAdmin.active,
        created_at: formattedDate(editSuperAdmin.created),
      });
    }
  }, [isLoading, editSuperAdmin]);

  const clearHandler = (name) => setValue(name, '');

  const labels = (label) =>
    t(`label.${isGlobalAdmin ? 'globalAdmin' : 'superAdmin'}.${label}`);

  const postCodeLength = 4;

  const [addressBlur, setAddressBlur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const address = watch('address');
  const state = watch('state');
  const city = watch('city');
  const postcode = watch('zip_code');

  useEffect(() => {
    validateAddress({
      address: address,
      city: city,
      state: state,
      postcode: postcode,
      clearErrors,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'zip_code',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors,
    });
  }, [addressBlur, stateBlur, cityBlur, postcodeBlur]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.contact')} ${t('attributes.information')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: `${t('attributes.name')} ${Validation.general.required}`,
                maxLength: {
                  value: 80,
                  message: Validation.general.contactName,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.name')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name="cell_phone"
              control={control}
              rules={{
                required: `${t('attributes.company.cell_phone')} ${Validation.general.required}`,
                pattern: {
                  value: /^\d{10}$/,
                  message: Validation.general.cellPhone,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.company.cell_phone')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('cell_phone');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          </Box>
          <Controller
            name="email"
            control={control}
            rules={{
              required: `${t('attributes.email')} ${Validation.general.required}`,
              maxLength: {
                value: 254,
                message: Validation.general.email,
              },
              pattern: {
                value: EMAIL_REGEX,
                message: Validation.general.validEmail,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.email')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('email');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
                disabled={editSuperAdmin && id}
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            rules={{
              required: `${t('attributes.address')} ${Validation.general.required}`,
              maxLength: {
                value: 300,
                message: Validation.general.address,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.address')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('address');
                }}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
                setBlur={setAddressBlur}
              />
            )}
          />
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="state"
              control={control}
              rules={{
                required: `${t('common.state')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('common.state')}
                  options={stateList}
                  value={value}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    clearErrors('city');
                    setValue('city', null);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  setBlur={setStateBlur}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              rules={{
                required: `${t('common.city')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('common.city')}
                  options={cityList}
                  value={value}
                  onChange={(e, newValue) => onChange(newValue)}
                  helperText={error ? error.message : ''}
                  error={error}
                  disabledDropdown={!selectedState}
                  setBlur={setCityBlur}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="zip_code"
              control={control}
              rules={{
                required: `${t('attributes.postCode')} ${Validation.general.required}`,
                validate: (value) => {
                  if (value && !/^\d+$/.test(value)) {
                    return Validation.general.numericValue;
                  }

                  return true;
                },
                minLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
                maxLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.postCode')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('zip_code');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                  setBlur={setPostcodeBlur}
                />
              )}
            />
          </Box>
        </FormFieldWrapper>
      </CustomCard>
      {isGlobalAdmin ? (
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">{`${t('attributes.company.company')} ${t('attributes.company.details')}`}</Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="company_name"
              control={control}
              rules={{
                required: `${t('attributes.company.company')} ${t('attributes.name')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={`${t('attributes.company.company')} ${t('attributes.name')}`}
                  options={companylistOptions}
                  value={value}
                  onChange={(e, newValue) => onChange(newValue)}
                  helperText={error ? error.message : ''}
                  error={error}
                  isLoadingData={isCompanyNameLoading}
                />
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
      ) : (
        // Keep the company_name in the form state even when not displayed
        <input
          type="hidden"
          {...control.register('company_name')}
          value={JSON.stringify(watch('company_name'))}
        />
      )}
      {id && (
        <CustomCard>
          <Box sx={{ px: '16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true} // Enable message display
              messageOnChecked={t('label.superAdmin.superAdminActiveCheckbox')}
              messageOnUnchecked={t(
                'label.superAdmin.superAdminInactiveCheckbox'
              )}
            />
          </Box>
        </CustomCard>
      )}
    </FormWrapper>
  );
};

export default SuperAdminForm;
