import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, Divider, styled, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { validateAddress } from '../../../constants/common';
import { Validation } from '../../../constants/FieldValidationMsg';
import getCityByStateHook from '../../../hooks/getCityByStateHook';
import getStatesHook from '../../../hooks/getStatesHook';
import { uploadDocument } from '../../../store/CommonAPI';
import { resetDocumentData } from '../../../store/CommonReducer';
import { getTechnicianById } from '../../../store/technician/api';
import { clearTextfields, formattedDate } from '../../../utils';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CommonCheckbox from '../../CommonComponents/CustomCheckbox';
import CustomFileUploadButton from '../../CommonComponents/CustomFileUploadButton';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import CustomTextField from '../../CommonComponents/CustomTextField';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import UploadedDocumentView from '../../CommonComponents/UploadedDocumentView';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const TechnicianForm = ({
  control,
  setValue,
  getValues,
  sethasFormValues,
  clearErrors,
  isEdit,
  isSuperAdmin = true,
  categoryList,
  isCategoryListLoading,
  zonesList,
  isZonesListLoading,
  skillsList,
  isSkillsListLoading,
  serviceAreaList,
  isServiceListLoading,
  watch,
  trigger,
  reset,
  id,
  setServerErrors,
}) => {
  const { t } = useTranslation();

  const selectedState = watch('state');
  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);
  const dispatch = useDispatch();
  const { isLoading, editTechnician } = useSelector(
    (state) => state.technician.edit
  );

  const { isDocumentLoading, uploadedSuccessfully, documentData } = useSelector(
    (state) => state.common
  );
  const [uploadedDocuments, setUploadedDocuments] = useState({
    name: '',
    size: '',
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [documentError, setdocumentError] = useState('');

  const [isUploadingOther, setIsUploadingOther] = useState(false);
  const postCodeLength = 4;

  const handleFileChange = (type) => (event) => {
    const file = event.target.files[0];
    const isValidFile = file && file.type === 'application/pdf';

    if (isValidFile) {
      const fileSize = (file.size / 1024).toFixed(0) + ' KB';

      setIsUploadingOther(true);
      setUploadedDocuments({
        name: file?.name,
        size: fileSize,
      });
      setdocumentError('');

      dispatch(
        uploadDocument({ document_type: type, uploaded_by: 1, file: file })
      );
    } else {
      setdocumentError('Invalid file type. Please upload a PDF.');
    }
  };

  useEffect(() => {
    if (!isDocumentLoading && uploadedSuccessfully) {
      setIsUploadingOther(false);
      setValue('otherDocument', {
        document_uuid: documentData?.uuid,
        document_type: documentData?.document_type,
        document_name: documentData?.document_name,
        document_size: uploadedDocuments?.size,
      });
    }
  }, [isDocumentLoading, uploadedSuccessfully]);

  const handleDeleteDocument = (type) => {
    setUploadedDocuments((prev) => ({
      ...prev,
      [type]: null,
    }));
    setValue(`${type}Document`, null);

    const input = document.querySelector(`input[name="${type}Document"]`);

    if (input) {
      input.value = ''; // Reset the file input
    }

    setdocumentError('');
  };

  const findLabelByValue = (list, value) => {
    const item = list.find((option) => option.value === value);

    return item ? { label: item.label, value: item.value } : null;
  };

  sethasFormValues(Object.values(watch()).some((value) => value));

  useEffect(() => {
    if (id) {
      dispatch(getTechnicianById(+id));
    }
  }, []);

  useEffect(() => () => dispatch(resetDocumentData()), []);

  useEffect(() => {
    if (!isLoading && editTechnician && id) {
      reset({
        id: editTechnician.id,
        primaryContact: editTechnician.technician_name,
        technician_email: editTechnician.technician_email,
        category: findLabelByValue(categoryList, editTechnician.categories[0]),
        skill:
          editTechnician.skills?.map((val) =>
            findLabelByValue(skillsList, val)
          ) || [],
        serviceArea:
          editTechnician.service_areas?.map((val) =>
            findLabelByValue(serviceAreaList, val)
          ) || [],
        zones:
          editTechnician.zones?.map((val) =>
            findLabelByValue(zonesList, val)
          ) || [],
        notes: editTechnician.notes,
        contactPhone: editTechnician.primary_contact_details?.contact_phone,
        address: editTechnician.primary_contact_details?.address,
        state: editTechnician.primary_contact_details?.state
          ? {
              label: editTechnician.primary_contact_details?.state || '',
              value: editTechnician.primary_contact_details?.state || '',
            }
          : null,
        city: editTechnician.primary_contact_details?.city
          ? {
              label: editTechnician.primary_contact_details?.city || '',
              value: editTechnician.primary_contact_details?.city || '',
            }
          : null,
        postCode: editTechnician.primary_contact_details?.postcode,
        otherDocument:
          editTechnician?.documents?.length > 0
            ? editTechnician.documents[0]
            : {},
        active: editTechnician.status === 'active',
        created_at: formattedDate(editTechnician.created_at),
      });
    }
  }, [isLoading, editTechnician]);

  const labels = (label) =>
    t(`label.${isSuperAdmin ? 'superAdmin' : 'globalAdmin'}.${label}`);

  const technicianLabels = (label) => t(`attributes.technician.${label}`);

  const [addressBlur, setAddressBlur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const address = watch('address');
  const state = watch('state');
  const city = watch('city');
  const postcode = watch('postCode');

  useEffect(() => {
    validateAddress({
      address: address,
      city: city,
      state: state,
      postcode: postcode,
      fieldName: {
        addressKey: 'address',
        addressValue: technicianLabels('contact_address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'postCode',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors,
    });
  }, [addressBlur, stateBlur, cityBlur, postcodeBlur]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <FormWrapper>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">General Details</Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
              <Controller
                name="primaryContact"
                control={control}
                rules={{
                  required: 'Primary contact is required',
                  maxLength: {
                    value: 80,
                    message: 'Primary contact must have maximum 80 characters',
                  },
                }}
                render={({
                  field: { onChange, value, name, onBlur },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label="Primary Contact"
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      trigger('primaryContact');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearTextfields(setValue, name)}
                    onBlur={() => {
                      onChange(value.trim());
                      onBlur();
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              name="technician_email"
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Please enter a valid email address',
                },
                maxLength: {
                  value: 254,
                  message: 'Email must have maximum 254 characters',
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Email"
                  fullWidth
                  value={value}
                  disabled={id ? true : false}
                  onChange={(e) => {
                    onChange(e);
                    trigger('technician_email');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="category"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label="Category"
                  options={categoryList}
                  value={value}
                  onChange={(e, newValue) => onChange(newValue)}
                  helperText={error ? error.message : ''}
                  error={error}
                  isLoadingData={isCategoryListLoading}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
            <Controller
              name="skill"
              control={control}
              rules={{
                required: 'Skills are required',
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={skillsList}
                  fullWidth
                  multiple={true}
                  label={t('attributes.technician.skills')}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    trigger('skill');
                  }}
                  value={value}
                  helperText={error ? error.message : ''}
                  error={error}
                  isLoadingData={isSkillsListLoading}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="serviceArea"
              control={control}
              rules={{
                required: 'Service Area is required',
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={serviceAreaList}
                  fullWidth
                  multiple={true}
                  label="Service Areas"
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    trigger('serviceArea');
                  }}
                  value={value}
                  helperText={error ? error.message : ''}
                  isLoadingData={isServiceListLoading}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="zones"
              control={control}
              rules={{
                required: ` ${t('attributes.technician.zones')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={zonesList}
                  fullWidth
                  multiple={true}
                  label="Zones"
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    trigger('zones');
                  }}
                  value={value}
                  helperText={error ? error.message : ''}
                  isLoadingData={isZonesListLoading}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="notes"
              control={control}
              rules={{
                maxLength: {
                  value: 65535,
                  message: Validation.general.notes,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Notes"
                  multiline
                  rows={3}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('notes');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">Contact Details</Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="contactPhone"
              control={control}
              rules={{
                required: `Contact phone ${Validation.general.required}`,
                pattern: {
                  value: /^\d{10}$/,
                  message: Validation.general.contactPhone,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Contact Phone"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('contactPhone');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              rules={{
                required: `${technicianLabels('contact_address')} ${Validation.general.required}`,
                maxLength: {
                  value: 300,
                  message: 'Field must have maximum 300 characters',
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={technicianLabels('contact_address')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('address');
                  }}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                  setBlur={setAddressBlur}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
            <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
              <Controller
                name="state"
                control={control}
                rules={{
                  required: `${t('common.state')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('common.state')}
                    options={stateList}
                    value={value}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                      trigger('state');
                      setValue('city', null);
                      clearErrors('city');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearTextfields(setValue, name)}
                    isRequired={true}
                    setBlur={setStateBlur}
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                rules={{
                  required: `${t('common.city')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('common.city')}
                    options={cityList}
                    value={value}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                      trigger('city');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearTextfields(setValue, name)}
                    isRequired={true}
                    setBlur={setCityBlur}
                  />
                )}
              />
            </Box>
            <Controller
              name="postCode"
              control={control}
              rules={{
                required: `${t('attributes.postCode')} ${Validation.general.required}`,
                validate: (value) => {
                  if (value && !/^\d+$/.test(value)) {
                    return Validation.general.numericValue;
                  }

                  return true;
                },
                minLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
                maxLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.postCode')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('postCode');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                  setBlur={setPostcodeBlur}
                />
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">Attach Documents</Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="otherDocument"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <>
                  <CustomFileUploadButton
                    name={name}
                    onFileChange={(event) => {
                      handleFileChange('other')(event);
                      onChange(event);
                      trigger('otherDocument');
                    }}
                    multiple={false}
                    buttonText="Click to upload or drag and drop"
                    accept=".pdf"
                    error={!!documentError}
                    errorMessage={documentError}
                  />
                  {!isEmpty(value) && (
                    <UploadedDocumentView
                      fileName={
                        isUploadingOther
                          ? uploadedDocuments?.name
                          : value?.document_name || ''
                      }
                      fileSize={
                        isUploadingOther
                          ? uploadDocument?.size
                          : value?.document_size
                      }
                      isUploading={isUploadingOther}
                      onDelete={() => setOpenConfirmationModal(true)}
                    />
                  )}
                </>
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
        {id && (
          <CustomCard>
            <Box sx={{ padding: '0 16px 0 16px' }}>
              <CommonCheckbox
                control={control}
                name="active"
                label="Active"
                isRequired={false}
                showMessage={true} // Enable message display
                messageOnChecked={labels('technicianActiveCheckbox')}
                messageOnUnchecked={labels('technicianInactiveCheckbox')}
              />
            </Box>
          </CustomCard>
        )}
      </FormWrapper>
      {openConfirmationModal && (
        <ConfirmationModal
          title="Delete Document"
          description="Are you sure you want to delete this document?"
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            handleDeleteDocument('other');
            setOpenConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

export default TechnicianForm;
