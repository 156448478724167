import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { CUSTOMER } from '../actionPrefix';
import { axiosInstanceReporting } from '../axios';

const {
  GET_INVOICE_LIST,
  GET_INVOICE_TYPE,
  GET_INVOICE_PROPERTYNAME,
  GET_INVOICE_STATUS,
} = CUSTOMER;

export const getInvoiceList = createAsyncThunk(
  GET_INVOICE_LIST,
  async (
    {
      page,
      search,
      size,
      type,
      status,
      order,
      orderBy,
      fromDate,
      toDate,
      property_name,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (order) params.append('order', order);
    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (type) params.append('type', type);
    if (status) params.append('status', status);
    if (property_name) params.append('property_name', property_name);
    if (fromDate)
      params.append(
        'created_at__gte',
        moment(fromDate).format('YYYY-MM-DDTHH:mm:ss')
      );
    if (toDate)
      params.append(
        'created_at__lte',
        moment(toDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
      );
    if (search) params.append('search_by', search);
    if (orderBy) params.append('order_by', orderBy);

    try {
      const response = await axiosInstanceReporting.get(
        `${APIs.CUSTOMER.GET_INVOICE_LIST}/?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Invoice Type List API
export const getInvoiceTypeDropdownList = createAsyncThunk(
  GET_INVOICE_TYPE,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_INVOICE_TYPE
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Invoice Property List API
export const getInvoicePropertyDropdownList = createAsyncThunk(
  GET_INVOICE_PROPERTYNAME,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_INVOICE_PROPERTYNAME
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Invoice Status List API
export const getInvoiceStatusDropdownList = createAsyncThunk(
  GET_INVOICE_STATUS,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_INVOICE_STATUS
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
