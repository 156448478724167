import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { filterDateFormatted } from '../../utils';
import { OFFICE_STAFF } from '../actionPrefix';
import { axiosInstanceOfficeStaff } from '../axios';

export const getOfficeStaffList = createAsyncThunk(
  OFFICE_STAFF.GET_OFFICE_STAFFS,
  async (
    {
      page,
      search,
      limit,
      active,
      state,
      city,
      order,
      orderBy,
      list_column_names,
      fromDate,
      toDate,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search_by', search);
    if (limit) params.append('size', limit);
    if (active) params.append('status', active);
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (list_column_names)
      params.append('list_column_names', list_column_names);
    if (fromDate) params.append('from_date', filterDateFormatted(fromDate));
    if (toDate) params.append('to_date', filterDateFormatted(toDate));

    try {
      const response = await axiosInstanceOfficeStaff.get(
        `${APIs.OFFICE_STAFF.GET_OFFICE_STAFFS}/?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOfficeStaffById = createAsyncThunk(
  OFFICE_STAFF.GET_OFFICE_STAFF_BY_ID,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceOfficeStaff.get(
        APIs.OFFICE_STAFF.GET_UPDATE_OFFICE_STAFFS.replace('{id}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOfficeStaff = createAsyncThunk(
  OFFICE_STAFF.UPDATE_OFFICE_STAFF,
  async ({ id: id, req }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceOfficeStaff.patch(
        APIs.OFFICE_STAFF.GET_UPDATE_OFFICE_STAFFS.replace('{id}', id),
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOfficeStaff = createAsyncThunk(
  OFFICE_STAFF.CREATE_OFFICE_STAFF,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceOfficeStaff.post(
        APIs.OFFICE_STAFF.CREATE_OFFICE_STAFFS,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
