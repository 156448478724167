import { configureStore } from '@reduxjs/toolkit';

// Internal
import accountManager from './accountManager/reducer';
import category from './category/reducer';
import CommonReducer from './CommonReducer';
import company from './company/reducer';
import complianceStatus from './complianceStatus/reducer';
import equipmentTypes from './equipmentTypes/reducer';
import invoices from './invoices/reducer';
import officeStaff from './officeStaff/reducer';
import productCatalogue from './productCatalogue/reducer';
import property from './property/reducer';
import quotes from './quotes/reducer';
import serviceAreas from './serviceAreas/reducer';
import skills from './skills/reducer';
import superAdmin from './superAdmin/reducer';
import supplier from './supplier/reducer';
import technician from './technician/reducer';
import customer from './users/customer/reducer';
import workOrder from './workOrder/reducer';
import zones from './zones/reducer';

const store = configureStore({
  reducer: {
    company: company,
    superAdmin: superAdmin,
    common: CommonReducer,
    property: property,
    customer: customer,
    technician: technician,
    category: category,
    skills: skills,
    zones: zones,
    accountManager: accountManager,
    serviceAreas: serviceAreas,
    officeStaff: officeStaff,
    quotes: quotes,
    workOrder: workOrder,
    invoices: invoices,
    complianceStatus: complianceStatus,
    supplier: supplier,
    productCatalogue: productCatalogue,
    equipmentTypes: equipmentTypes,
  },
});

export default store;
