import { DRAFT, INVITE_PENDING, PRIMARY } from './Colors';

export const LOCATION = process.env.REACT_APP_LOCATION;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

export const ADDRESS_REGION_CODE = 'AU';
export const ADDRESS_COUNTRY = 'Australia';

export const COUNTRIES = {
  AUSTRALIA: 'AUS',
  UAE: 'UAE',
};

export const TAB_STATUS = {
  active: 'active',
  inactive: 'inactive',
};

export const INVITATION_STATUS = {
  draft: 'Draft',
  invited: 'Invited',
  invitation_pending: 'Invite Pending',
};

export const STATUS_COLOR_MAP = {
  draft: DRAFT,
  invited: PRIMARY,
  invitation_pending: INVITE_PENDING,
};

export const DASHBOARD = 'dashboard';

export const SIX_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
export const TWELEVE_MONTHS = [
  ...SIX_MONTHS,
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const MONTHS_DROPDOWN = [
  { label: 'Six Months', value: 6 },
  { label: 'Twelve Months', value: 12 },
];
//  CHART_TYPE
export const PIE_CHART = 'pie_chart';
export const BAR_CHART = 'bar_chart';
export const LINE_CHART = 'line_chart';
export const SCATTER_CHART = 'scatter_chart';
export const SPARKLINE_CHART = 'sparkline_chart';
export const GAUGE_CHART = 'gauge_chart';

// Date format
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_RANGE_FORMAT = 'dd/MM/yyyy';
export const DATE_FORMAT_API = 'YYYY-MM-DDTHH:mm';
export const DATE_CONVERT_UTC_FORMAT_API = 'YYYY-MM-DDTHH:mm';
export const DATE_RANGE_FORMAT_API = 'YYYY-MM-DDTHH:mm:ss';

// Font size
export const FONT_SIZE = '14px';
// SSO
export const LOGIN_TYPE = {
  microsoft: 'microsoft',
  google: 'google',
};

export const StaticChartData = [
  {
    id: 1,
    name: 'defects',
    order: 1,
    display_name: 'Defects',
    description: 'Critical, Non-Critical,Non-Conformance',
    is_active: true,
    isRadius: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Group A', value: 40, color: '#F06548' },
          { label: 'Group B', value: 30, color: '#95C020' },
          { label: 'Group C', value: 30, color: '#299CDB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Critical',
            id: 'critical',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Non-Critical',
            id: 'nonCritical',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Non-Conformance',
            id: 'nonConformance',
            stack: 'total',
          },
        ],
        colors: ['#F06548', '#95C020', '#299CDB'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 2,
    name: 'quotes',
    order: 2,
    display_name: 'Quotes',
    description:
      'Open, Pending Approval, Assigned,In Progress, Delayed, Overdue',
    is_active: true,
    isRadius: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Open', value: 25, color: '#95C020' },
          { label: 'Pending Approval', value: 18, color: '#405189' },
          { label: 'Assigned', value: 17, color: '#299CDB' },
          { label: 'In Progress', value: 9, color: '#F7B84B' },
          { label: 'Delayed', value: 9, color: '#F06548' },
          { label: 'Overdue', value: 22, color: '#A85BBB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Open',
            id: 'open',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Pending Approval',
            id: 'pendingApproval',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Assigned',
            id: 'assigned',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgress',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Delayed',
            id: 'delayed',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Overdue',
            id: 'overdue',
            stack: 'total',
          },
        ],
        colors: [
          '#95C020',
          '#405189',
          '#299CDB',
          '#F7B84B',
          '#F06548',
          '#A85BBB',
        ],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 3,
    name: 'invoices',
    order: 3,
    display_name: 'Invoices',
    description: 'Not Sent, In Progress, Pending Payments, Dispute, Overdue',
    is_active: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Not Sent', value: 14, color: '#95C020' },
          { label: 'In Progress', value: 3, color: '#F7B84B' },
          { label: 'Pending Payments', value: 1, color: '#F06548' },
          { label: 'Dispute', value: 1, color: '#299CDB' },
          { label: 'Overdue', value: 1, color: '#A85BBB' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Not Sent',
            id: 'notSent',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgress',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Pending Payments',
            id: 'pendingPayments',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Dispute',
            id: 'dispute',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Overdue',
            id: 'overdue',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#F7B84B', '#F06548', '#299CDB', '#A85BBB'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
  {
    id: 4,
    name: 'routine_services',
    display_name: 'Routine Services',
    description: 'Unassigned, In Progress, Overdue, Blocked',
    is_active: true,
    order: 4,
    default_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    selected_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    supported_types: [
      {
        id: 9,
        name: 'line_chart',
        display_name: 'Line Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Unassigned',
            id: 'unAssigned',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgressId',
            stack: 'total',
          },
          {
            data: [10, 17, 20, 25, 5, 15],
            label: 'Overdue',
            id: 'overDue',
            stack: 'total',
          },
          {
            data: [15, 23, 15, 60, 35, 5],
            label: 'Blocked',
            id: 'blocked',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#F7B84B', '#A85BBB', '#F06548'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
      line_chart: {
        data: [
          {
            data: [2, 5.5, 2, 8.5, 1.5, 5],
            label: 'Unassigned',
            color: '#95C020',
          },
          {
            data: [3, 5, 2, 8, 1.5, 5],
            label: 'In Progress',
            color: '#F7B84B',
          },
          {
            data: [7, 5, 2, 5, 1, 5],
            label: 'Overdue',
            color: '#A85BBB',
          },
          {
            data: [2, 1, 2, 8, 1, 5],
            label: 'Blocked',
            color: '#F06548',
          },
        ],
        labels: [1, 2, 3, 4, 5, 6],
      },
    },
  },
  {
    id: 5,
    name: 'service_request',
    display_name: 'Service Request',
    description: 'Unassigned, In Progress, Overdue, Blocked',
    is_active: true,
    order: 5,
    default_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    selected_type: {
      id: 4,
      name: 'bar_chart',
      display_name: 'Bar Chart',
    },
    supported_types: [
      {
        id: 9,
        name: 'line_chart',
        display_name: 'Line Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Unassigned',
            id: 'unAssigned',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'In Progress',
            id: 'inProgressId',
            stack: 'total',
          },
          {
            data: [10, 17, 20, 25, 5, 15],
            label: 'Overdue',
            id: 'overDue',
            stack: 'total',
          },
          {
            data: [15, 23, 15, 60, 35, 5],
            label: 'Blocked',
            id: 'blocked',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#F7B84B', '#A85BBB', '#F06548'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
      line_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45], // Unassigned
            label: 'Unassigned',
          },
          {
            data: [15, 12, 20, 25, 10, 20], // In Progress
            label: 'In Progress',
          },
          {
            data: [10, 17, 20, 25, 5, 15], // Overdue
            label: 'Overdue',
          },
          {
            data: [15, 23, 15, 60, 35, 5], // Blocked
            label: 'Blocked',
          },
        ],
        labels: [1, 2, 3, 4, 5, 6],
      },
    },
  },
  {
    id: 6,
    name: 'properties',
    order: 6,
    display_name: 'Properties',
    description:
      'Created, Deactivated, Reactivated,Contracts Expired, Contracts Expiring Soon',
    is_active: true,
    default_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    selected_type: {
      id: 5,
      name: 'pie_chart',
      display_name: 'Pie Chart',
    },
    supported_types: [
      {
        id: 5,
        name: 'pie_chart',
        display_name: 'Pie Chart',
      },
      {
        id: 4,
        name: 'bar_chart',
        display_name: 'Bar Chart',
      },
    ],
    widget_data: {
      pie_chart: {
        data: [
          { label: 'Created', value: 14, color: '#95C020' },
          { label: 'Deactivated', value: 3, color: '#405189' },
          { label: 'Reactivated', value: 1, color: '#299CDB' },
          { label: 'Contracts Expired', value: 1, color: '#F06548' },
          { label: 'Contracts Expiring Soon', value: 1, color: '#F7B84B' },
        ],
      },
      bar_chart: {
        data: [
          {
            data: [30, 35, 35, 40, 45, 45],
            label: 'Created',
            id: 'created',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Deactivated',
            id: 'deactivated',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Reactivated',
            id: 'reactivated',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Contracts Expired',
            id: 'contractsExpired',
            stack: 'total',
          },
          {
            data: [15, 12, 20, 25, 10, 20],
            label: 'Contracts Expiring Soon',
            id: 'contractsExpiringSoon',
            stack: 'total',
          },
        ],
        colors: ['#95C020', '#405189', '#299CDB', '#F06548', '#F7B84B'],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      },
    },
  },
];

export const PRODUCT_TYPES_LABEL = {
  EQUIPMENT: 'equipment',
  MATERIAL: 'material',
  EXPENSES: 'expenses',
  LABOUR: 'labour',
};

export const PRODUCT_TYPES = [
  { label: 'Material', value: 'material' },
  { label: 'Equipment', value: 'equipment' },
  { label: 'Expense', value: 'expense' },
  { label: 'Labour', value: 'labour' },
];

export const UNIT_OF_MEASURES = [
  { label: 'Each', value: 'each' },
  { label: 'Length', value: 'length' },
  { label: 'Weight', value: 'weight' },
];
// OTHER CHART

// line_chart: {
//   labels: [1, 2, 3, 5, 8, 10],
//   value: [2, 5.5, 2, 8.5, 1.5, 5],
// },

// scatter_chart: {
//   chart_data: [1, 4, 2, 5, 7, 2, 4, 6],
//   value: [
//     '27/09/2024',
//     '27/09/2024',
//     '28/09/2024',
//     '30/09/2024',
//     '27/09/2024',
//     '21/09/2024',
//     '29/09/2024',
//     '23/09/2024',
//   ],
// },
// gauge_chart: {
//   value: 50,
// },
// spsparkline_chart: {
//   chart_data: [1, 4, 2, 5, 7, 2, 4, 6],
//   value: [
//     '27/09/2024',
//     '27/09/2024',
//     '28/09/2024',
//     '30/09/2024',
//     '27/09/2024',
//     '21/09/2024',
//     '29/09/2024',
//     '23/09/2024',
//   ],
// },
