import React from 'react';

import { Box, styled, Typography } from '@mui/material';

// Internal
import { NODATA_ICON_COLOR } from '../../../constants/Colors';

const Container = styled(Box)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
}));

const IconWrapper = styled(Box)(() => ({
  svg: {
    height: '60px',
    width: '60px',
    fill: NODATA_ICON_COLOR,
  },
}));

const NewNoDataPage = ({
  icon,
  title,
  createBtnText,
  singularText,
  filterHeight,
}) => (
  <Container
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100vh - ${filterHeight}px)`,
      overflow: 'hidden',
    }}
  >
    <IconWrapper>{icon}</IconWrapper>
    <Typography variant="h3">{title}</Typography>
    <Typography variant="body2">
      There are no {title && title?.toLowerCase()} listed currently. Please
      click on the
    </Typography>
    <Typography variant="body2">
      &quot;+ {createBtnText}&quot; button to add a new{' '}
      {singularText ? singularText?.toLowerCase() : title?.toLowerCase()}.
    </Typography>
  </Container>
);

export default NewNoDataPage;
