import { useCallback } from 'react';

/**
 * Custom hook to handle server-side errors in forms managed by react-hook-form.
 */
const useServerSideErrors = (serverErrors, setError, clearErrors) => {
  const handleServerErrors = useCallback(() => {
    if (serverErrors) {
      // Clear existing errors
      clearErrors();

      // Loop through server errors and set them in the form
      serverErrors.forEach((fieldError) => {
        setError(fieldError.field || 'EMPTY', {
          type: 'server',
          message: fieldError.message,
        });
      });
    }
  }, [serverErrors, setError, clearErrors]);

  return { handleServerErrors };
};

export default useServerSideErrors;
