import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, Divider, styled, Typography } from '@mui/material';

// Internal
import { validateAddress } from '../../constants/common';
import { TAB_STATUS } from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../constants/Regex';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import { editSupplier } from '../../store/supplier/api';
import { formattedDate } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const SupplierForm = ({
  id,
  control,
  reset,
  setValue,
  watch,
  trigger,
  setServerErrors,
}) => {
  const dispatch = useDispatch();
  const { isLoading, supplier } = useSelector((state) => state.supplier.edit);

  const selectedState = watch('state');

  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);

  const { t } = useTranslation();

  const labels = (label) => t(`label.superAdmin.${label}`);

  useEffect(() => {
    if (id) {
      dispatch(editSupplier(id));
    }
  }, []);

  useEffect(() => {
    if (!isLoading && supplier && id) {
      reset({
        uuid: supplier.uuid,
        name: supplier.name,
        business_number: supplier.business_number,
        contact_name: supplier.contact_name,
        email: supplier.contact_email,
        phone: supplier.contact_phone,
        address: supplier.address,
        city: { label: supplier.city, value: supplier.city },
        state: { label: supplier.state, value: supplier.state },
        active: supplier.status === TAB_STATUS.active,
        created_at: formattedDate(supplier.created_at),
        post_code: supplier.postcode,
      });
    }
  }, [isLoading, supplier]);

  const clearHandler = (name) => setValue(name, '');

  const postCodeLength = 4;

  const [addressBlur, setAddressBlur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const address = watch('address');
  const state = watch('state');
  const city = watch('city');
  const postcode = watch('zip_code');

  useEffect(() => {
    validateAddress({
      address: address,
      city: city,
      state: state,
      postcode: postcode,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'post_code',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors,
    });
  }, [addressBlur, stateBlur, cityBlur, postcodeBlur]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.supplier.supplier')} ${t('attributes.supplier.details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="name"
            control={control}
            rules={{
              required: `${t('attributes.supplier.supplier')} ${t('attributes.name')} ${Validation.general.required}`,
              minLength: {
                value: 2,
                message: Validation.supplier.name,
              },
              maxLength: {
                value: 255,
                message: Validation.supplier.name,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('attributes.supplier.supplier')} ${t('attributes.name')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="business_number"
            control={control}
            rules={{
              required: `${t('attributes.supplier.business_number')} ${Validation.general.required}`,
              minLength: {
                value: 2,
                message: Validation.supplier.business_number,
              },
              maxLength: {
                value: 255,
                message: Validation.supplier.business_number,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.supplier.business_number')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('business_number');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.contact')} ${t('attributes.supplier.details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="contact_name"
            control={control}
            rules={{
              required: `${t('attributes.contact')} ${t('attributes.name')} ${Validation.general.required}`,
              maxLength: {
                value: 80,
                message: Validation.general.contactName,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('attributes.contact')} ${t('attributes.name')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('contact_name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: `${t('attributes.email')} ${Validation.general.required}`,
              maxLength: {
                value: 254,
                message: Validation.general.email,
              },
              pattern: {
                value: EMAIL_REGEX,
                message: Validation.general.validEmail,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('attributes.contact')} ${t('attributes.email')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('email');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: `${t('attributes.supplier.phone')} ${Validation.general.required}`,
              pattern: {
                value: /^\d{10}$/,
                message: Validation.supplier.phone,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('attributes.contact')} ${t('attributes.supplier.phone')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('phone');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            rules={{
              required: `${t('attributes.address')} ${Validation.general.required}`,
              maxLength: {
                value: 300,
                message: Validation.general.address,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('attributes.contact')} ${t('attributes.address')}`}
                fullWidth
                value={value}
                setBlur={setAddressBlur}
                onChange={(e) => {
                  onChange(e);
                  trigger('address');
                }}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />

          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="state"
              control={control}
              rules={{
                required: `${t('common.state')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={stateList}
                  label={t('common.state')}
                  setBlur={setStateBlur}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    setValue('city', null);
                  }}
                  value={value}
                  error={error}
                  helperText={error ? error.message : ''}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              rules={{
                required: `${t('common.city')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={cityList}
                  label={t('common.city')}
                  setBlur={setCityBlur}
                  onChange={(e, newValue) => onChange(newValue)}
                  value={value}
                  error={error}
                  helperText={error ? error.message : ''}
                  onClear={() => clearHandler(name)}
                  disabledDropdown={!selectedState}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="post_code"
              control={control}
              rules={{
                required: `${t('attributes.postCode')} ${Validation.general.required}`,
                validate: (value) => {
                  if (value && !/^\d+$/.test(value)) {
                    return Validation.general.numericValue;
                  }

                  return true;
                },
                minLength: {
                  value: postCodeLength,
                  message: `${Validation.supplier.postCodeCharacter} ${postCodeLength} ${Validation.supplier.characters}`,
                },
                maxLength: {
                  value: postCodeLength,
                  message: `${Validation.supplier.postCodeCharacter} ${postCodeLength} ${Validation.supplier.characters}`,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.postCode')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('post_code');
                  }}
                  setBlur={setPostcodeBlur}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          </Box>
        </FormFieldWrapper>
      </CustomCard>
      {id && (
        <CustomCard>
          <Box sx={{ padding: '0 16px 0 16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true} // Enable message display
              messageOnChecked={labels('supplierActiveCheckbox')}
              messageOnUnchecked={labels('supplierInactiveCheckbox')}
            />
          </Box>
        </CustomCard>
      )}
    </FormWrapper>
  );
};

export default SupplierForm;
