import * as React from 'react';

import { LineChart as CustomLineChart } from '@mui/x-charts/LineChart';

import { FONT_SIZE } from '../../../constants/Constants';

const LineChart = ({
  data = [
    {
      data: [2, 5.5, 2, 8.5, 1.5, 5],
      label: 'A',
    },
  ],
  labels = [1, 2, 3, 5, 8, 10],
}) => (
  <CustomLineChart
    xAxis={[{ data: labels }]}
    series={data}
    height={300}
    slotProps={{
      legend: {
        position: {
          vertical: 'top',
          horizontal: 'left',
        },
        labelStyle: {
          fontSize: FONT_SIZE,
          fontWeight: 400,
        },
        itemMarkWidth: 12,
        itemMarkHeight: 12,
        markGap: 5,
        itemGap: 10,
      },
    }}
  />
);
export default LineChart;
