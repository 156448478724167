export const APIs = {
  SUPER_ADMIN: {
    GET_SUPER_ADMINS: '/user/super-admin/company',
    GET_SUPER_ADMIN_BY_ID: '/user/super-admin/{uuid}',
    GET_UPDATE_SUPER_ADMINS: '/user/super-admin/{uuid}',
    CREATE_SUPER_ADMINS: '/user/super-admin',
    GET_WIDGETS: 'super-admin/widgets/{uuid}',
    UPDATE_WIDGETS: 'super-admin/widgets/{uuid}',
  },
  PROPERTIES: {
    GET_PROPERTIES: '/detail',
    GET_PROPERTY: '/detail/{propertyUuid}',
    UPDATE_PROPERTY: '/detail/{propertyUuid}',
    CREATE_PROPERTY: '/detail',
    // status api list
    GET_STATUS_LIST: '/status',
    // Tags list api
    GET_TAG_LIST: '/tag',
    // Access schedule api
    GET_ACCESS_SCHEDULE_LIST: '/access_schedule',
    // Access procedure api
    GET_ACCESS_PROCEDURE_LIST: '/access_procedure',
    // Building era
    GET_BUILDING_ERA_LIST: '/building_era',
    // Building class
    GET_BUILDING_CLASS_LIST: '/building_class',
    // Construction list
    GET_CONSTRUCTION_LIST: '/construction_class',
    // Building type list
    GET_BUILDING_TYPE_LIST: '/building_type',
    // Building standard list
    GET_BUILDING_STANDARD_LIST: '/building_standard',
    // Get local government area
    GET_LOCAL_GOVERNMENT_AREA_LIST: '/local_government_area',
    // Get parent property list
    GET_PARENT_PROPERTY_LIST: '/parent_property',
    // Get property account manager list
    GET_PROPERTY_ACCOUNT_MANAGER_LIST: '/account_managers',
    // Get property customer list
    GET_PROPERTY_CUSTOMER_LIST: '/user/customers',
    // Get property billing card list
    GET_PROPERTY_BILLING_CARD_LIST: '/billing_cards',
    GET_PROPERTY_ASSETS_LIST: '/detail/{property_uuid}/asset',
    GET_EQUIPEMENT_TYPE: '/equipment_types',
    GET_PRODUCTS: '/products',
    GET_ASSET_STATUS: '/asset_status',
    CREATE_ASSET: '/asset_new',
    GET_ASSET_DETAILS: '/asset/{asset_id}',
    UPDATE_ASSET_DETAILS: '/asset/{asset_id}',
    GET_PROPERTY_ROUTINE_LIST: '/routine_schedule',
    UPDATE_ROUTINE_DETAILS: '/routine_schedule/{routine_id}',
    GET_COMPLIANCE_STATUS: '/compliance',
  },
  CUSTOMER: {
    GET_CUSTOMERS: '/user/customer',
    GET_CUSTOMER_BY_ID: '/user/customer/{uuid}',
    GET_UPDATE_CUSTOMER: '/user/customer/{uuid}',
    CREATE_CUSTOMER: 'user/customer',
    GET_CUSTOMER_CATEGORY_DROPDOWN_LIST: '/customer/categories',

    GET_QUOTES_LIST: '/quotes',
    GET_QUOTES_TYPE: '/quotes/type',
    GET_QUOTES_STATUS: '/quotes/status',
    GET_QUOTES_PROPERTYNAME: '/quotes/property_name',

    GET_INVOICE_LIST: '/invoice',
    GET_INVOICE_TYPE: '/invoice/type',
    GET_INVOICE_STATUS: '/invoice/status',
    GET_INVOICE_PROPERTYNAME: '/invoice/property_name',
  },
  TECHNICIAN: {
    GET_TECHNICIANS: '/user/technician',
    GET_TECHNICIAN_BY_ID: '/user/technician/{id}',
    GET_UPDATE_TECHNICIANS: '/user/technician/{id}',
    CREATE_TECHNICIANS: '/user/technician',
    // Tags list api
    GET_TAGS_LIST: '/technician/tags',
    // Skills list api
    GET_SKILLS_LIST: '/technician/skills',
    // Zones list api
    GET_ZONES_LIST: '/technician_zones',
    // Service Area list api
    GET_SERVICE_AREAS: '/technician/service-areas',
    GET_TECHNICIAN_NAMES: '/technician_names',
  },
  CATEGORY: {
    GET_CATEGORY_DROPDOWN_LIST: '/technician/category',
  },
  SKILLS: {
    GET_SKILLS_DROPDOWN_LIST: '/technician/skills',
  },
  ZONES: {
    GET_ZONES_DROPDOWN_LIST: '/technician/zones',
  },
  SERVICE_AREAS: {
    GET_SERVICE_AREAS_DROPDOWN_LIST: '/technician/service-areas',
  },
  ACCOUNT_MANAGER: {
    GET_ACCOUNT_MANAGER_DROPDOWN_LIST: 'customer/account-managers',
    GET_ACCOUNT_MANAGER_COMMON_DROPDOWN_LIST: 'account_managers',
  },
  OFFICE_STAFF: {
    GET_OFFICE_STAFFS: '/user/office_staff',
    GET_OFFICE_STAFF_BY_ID: '/user/office_staff/{id}',
    GET_UPDATE_OFFICE_STAFFS: '/user/office_staff/{id}',
    CREATE_OFFICE_STAFFS: '/user/office_staff',
  },
  WORK_ORDER: {
    GET_WORK_ORDERS: '/work_orders',
    GET_WORK_ORDER: '/work_orders/{uuid}',
  },
  SUPPLIER: {
    GET_SUPPLIERS: '/supplier',
    GET_SUPPLIER_BY_ID: '/supplier/{uuid}',
    UPDATE_SUPPLIER: '/supplier/{uuid}',
    CREATE_SUPPLIER: '/supplier',
  },
  PRODUCT_CATALOGUE: {
    GET_PRODUCT_CATALOGUE_LIST: 'product_catalogue',
    GET_PRODUCT_CATALOGUE_DETAILS:
      '/product_catalogue/{product_catalogue_uuid}',
    GET_PRODUCT_CATALOGUE: '/product_catalogue',
    GET_PRODUCT_DETAILS: '/product_catalogue/{uuid}',
    UPDATE_PRODUCT_CATALOGUE: '/product_catalogue/{uuid}',
    CREATE_PRODUCT_CATALOGUE: '/product_catalogue',
  },
};
