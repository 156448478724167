import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { Box, Card, Checkbox, Grid, Typography } from '@mui/material';

import { FlexEnd, FlexStart, SpaceBetween } from '../../assets/commonStyled';
import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { getWidgetData, updateCharts } from '../../store/superAdmin/api';
import { displayChart } from '../../utils';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { RefreshDashboardButton } from '../CommonComponents/FilterButton';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import CustomSearch from '../CommonComponents/Search';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import ChartCard from './ChartCard';
import DraggableChart from './DraggableChart';
import DroppableArea from './DroppableArea';

const SuperAdminDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [layout, setLayout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [activeCharts, setActiveCharts] = useState([]);

  const [isManageDashboard, setIsManageDashboard] = useState(false);

  const { widget } = useSelector((state) => state.superAdmin);
  const { profileDetails } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(getWidgetData(1));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (widget?.data?.length) {
      // Initial layout with names
      const latestLayout = widget?.data
        .map((chart) => ({
          ...chart,
          name: chart?.display_name,
          type: chart?.selected_type?.name,
          selected_type: {
            ...chart?.selected_type,
            label: chart?.selected_type?.display_name,
            value: chart?.selected_type?.name,
          },
          supported_types: chart?.supported_types?.map((st) => ({
            ...st,
            label: st.display_name,
            value: st?.name,
          })),
        }))
        .sort((a, b) => a.order - b.order);

      setLayout(latestLayout?.filter((c) => c?.is_active));
      setActiveCharts(latestLayout);
    }
  }, [widget?.data, isLoading, widget?.isLoading]);

  const restoreDefault = () => {
    const defaultSettings = widget?.data?.map((chart) => ({
      ...chart,
      name: chart?.display_name,
      type: chart?.default_type?.name,
      selected_type: {
        ...chart?.default_type,
        label: chart?.default_type?.display_name,
        value: chart?.default_type?.name,
      },
      supported_types: chart?.supported_types?.map((st) => ({
        ...st,
        label: st.display_name,
        value: st?.name,
      })),
      is_active: true,
    }));

    setLayout(defaultSettings);
    setActiveCharts(defaultSettings);
  };

  const handleDrop = useCallback((draggedChartId, targetAreaId) => {
    setLayout((prevLayout) => {
      const newLayout = prevLayout?.map((pl) => {
        if (pl.id === draggedChartId || pl?.order === targetAreaId) {
          if (pl.id === draggedChartId) {
            return { ...pl, order: targetAreaId };
          }
          const oldItemPosition = prevLayout?.find(
            (pl) => pl?.id === draggedChartId
          )?.order;

          return { ...pl, order: oldItemPosition };
        }

        return pl;
      });

      return newLayout.sort((a, b) => a.order - b.order);
    });
  }, []);

  const onChartRemove = (id) => {
    const updatedActiveCharts = activeCharts?.map((c) => {
      if (c?.id === id) {
        return { ...c, is_active: false };
      }

      return c;
    });

    setLayout(updatedActiveCharts);
    setActiveCharts(updatedActiveCharts);
  };

  const chartTypeHandler = (id, val) => {
    const updatedLayout = layout.map((chart) => {
      if (chart.id === id) {
        let updatedChartData = {
          ...chart,
          type: val.value,
          selected_type: val,
          data: chart?.widget_data[val.value]?.data,
          colors: chart?.widget_data[val.value]?.colors,
          xLabel: chart?.widget_data[val.value]?.labels,
        };

        return updatedChartData;
      }

      return chart;
    });

    setLayout(updatedLayout);
  };

  const displayChartHandler = (id, isChecked) => {
    const updatedCharts = activeCharts?.map((c) => {
      if (c?.id === id) {
        return { ...c, is_active: isChecked };
      }

      return c;
    });

    setActiveCharts(updatedCharts);
  };

  const updateChartDataHandler = () => {
    const updatedData = {
      widgets: layout?.map((l) => ({
        widget_id: l?.id,
        type_id: l?.selected_type?.id,
        order: l?.order,
        is_active: l?.is_active,
      })),
    };

    dispatch(updateCharts({ uuid: 1, data: updatedData }));
  };

  return (
    <>
      <SpaceBetween>
        <Typography variant="body1">
          {t('common.welcome')}, {profileDetails?.name || '-'}
        </Typography>
        <FlexEnd>
          <RefreshDashboardButton />
          {!isManageDashboard && (
            <CustomButton
              startIcon={<WidgetsOutlinedIcon />}
              text="Manage Dashboard"
              onClick={() => setIsManageDashboard(true)}
            />
          )}
        </FlexEnd>
      </SpaceBetween>
      {isManageDashboard && (
        <SpaceBetween
          sx={{
            background: SECONDARY,
            padding: '12px',
            marginTop: '16px',
            borderRadius: '6px',
          }}
        >
          <FlexStart>
            <CustomButton
              startIcon={<AddchartOutlinedIcon />}
              text="Add widget"
              variant="text"
              onClick={() => setOpen(true)}
              color="inherit"
              sx={{
                bgcolor: SECONDARY,
              }}
            />
            <CustomButton
              startIcon={<SaveOutlinedIcon />}
              text="Save"
              variant="text"
              onClick={updateChartDataHandler}
              color="inherit"
              sx={{
                bgcolor: SECONDARY,
              }}
            />
            <CustomButton
              startIcon={<RestoreOutlinedIcon />}
              text="Restore Dashboard"
              variant="text"
              onClick={restoreDefault}
              color="inherit"
              sx={{
                bgcolor: SECONDARY,
              }}
            />
          </FlexStart>
          <CustomButton
            startIcon={<CancelOutlinedIcon />}
            text="Close"
            variant="outlined"
            color="inherit"
            size="small"
            sx={{
              bgcolor: SECONDARY,
              height: '26px',
            }}
            onClick={() => setIsManageDashboard(false)}
          />
        </SpaceBetween>
      )}

      {isLoading || widget?.isLoading ? (
        <CustomCircularLoader />
      ) : !widget?.isLoading && !isLoading && layout?.length == 0 ? (
        <NewNoDataPage
          icon={<DashboardOutlinedIcon />}
          title="Dashboard"
          singularText="chart"
          filterHeight={isManageDashboard ? 422 : 350}
          createBtnText="Add Widget"
        />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <Box style={{ marginTop: '16px' }}>
            <Grid container spacing={2}>
              {/* Dynamically generated droppable areas */}
              {layout
                ?.filter((l) => l?.is_active)
                .map((item, index) => (
                  <Grid item xs={12} lg={6} xl={4} key={`area${index + 1}`}>
                    <DroppableArea id={index + 1} onDrop={handleDrop}>
                      {isManageDashboard ? (
                        <DraggableChart id={item.id}>
                          <ChartCard
                            title={item.name}
                            id={item.id}
                            options={item?.supported_types}
                            isSettingMode={isManageDashboard}
                            selectedType={item?.selected_type}
                            setSelectedType={(id, val) =>
                              chartTypeHandler(id, val)
                            }
                            onChartRemove={(id) => onChartRemove(id)}
                          >
                            {displayChart(item)}
                          </ChartCard>
                        </DraggableChart>
                      ) : (
                        <ChartCard
                          title={item.name}
                          id={item.id}
                          options={item?.supported_types}
                          isSettingMode={isManageDashboard}
                          selectedType={item?.selected_type}
                          setSelectedType={(id, val) =>
                            chartTypeHandler(id, val)
                          }
                          onChartRemove={(id) => onChartRemove(id)}
                        >
                          {displayChart(item)}
                        </ChartCard>
                      )}
                    </DroppableArea>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </DndProvider>
      )}

      <SwipeableDrawer
        open={open}
        title="Add Widget"
        onClose={() => setOpen(false)}
        footerButton={
          <CustomButton
            text="Add"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => {
              setOpen(false);
              setLayout(activeCharts);
            }}
          />
        }
        width="300px"
        bgColor={SECONDARY}
      >
        <CustomSearch width="100%" />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            marginTop: '8px',
          }}
        >
          {activeCharts?.map((chart) => (
            <Card
              sx={{
                padding: '8px',
                borderRadius: '4px',
                border: `1px solid ${DIVIDER_COLOR}`,
                boxShadow: 'none',
                display: 'flex',
                columnGap: '16px',
              }}
            >
              <Checkbox
                checked={chart?.is_active}
                onChange={(e) =>
                  displayChartHandler(chart?.id, e.target.checked)
                }
                color="primary"
                heigth="16px"
              />
              <Box>
                <Typography variant="body1">{chart?.display_name}</Typography>
                <Typography variant="body1">{chart?.description}</Typography>
              </Box>
            </Card>
          ))}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default SuperAdminDashboard;
