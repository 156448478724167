import React from 'react';

import { Box, styled, Typography } from '@mui/material';

const CardContainer = styled(Box)(({ title }) => ({
  backgroundColor: 'white',
  padding: '16px',
  borderRadius: '4px',
  width: '100%',
  '&:hover': {
    ...(title !== 'Total Quote Amounts' && {
      cursor: 'pointer',
      backgroundColor: '#f9f9f9',
    }),
  },
}));

const DashboardCard = ({ title, value, onClick }) => (
  <CardContainer
    title={title} // Pass title as a prop to styled component
    onClick={title !== 'Total Quote Amounts' ? onClick : undefined} // Conditional onClick handler
  >
    <Typography variant="body1">{title}</Typography>
    <Typography
      sx={{ textAlign: 'center', paddingTop: '24px', fontSize: '31px' }}
    >
      {value}
    </Typography>
  </CardContainer>
);

export default DashboardCard;
