import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Card, Divider, styled, Typography } from '@mui/material';

// Internal
import { WARNING, WARNING_HOVER } from '../../constants/Colors';
import { validateAddress } from '../../constants/common';
import { Validation } from '../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../constants/Regex';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import { editCompany } from '../../store/company/api';
import { formattedDate } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';
import UploadLogo from './UploadLogo';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const CompanyForm = ({
  id,
  control,
  reset,
  setValue,
  watch,
  trigger,
  setServerErrors,
  clearErrors,
}) => {
  const dispatch = useDispatch();
  const { isLoading, company } = useSelector((state) => state.company.edit);

  const selectedState = watch('state');

  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);

  const { t } = useTranslation();

  const labels = (label) => t(`label.globalAdmin.${label}`);

  useEffect(() => {
    if (id) {
      dispatch(editCompany(id));
    }
  }, []);

  useEffect(() => {
    if (!isLoading && company && id) {
      reset({
        uuid: company.uuid,
        company_name: company.company_name,
        abn: company.abn,
        address: company.address,
        contact_name: company.contact_name,
        email: company.email,
        cell_phone: company.cell_phone,
        city: { label: company.city, value: company.city },
        state: { label: company.state, value: company.state },
        active: company.active,
        logo_upload: `data:image/*;base64,${company.logo_upload}`,
        created_at: formattedDate(company.created_at),
        zip_code: company.zip_code,
      });
    }
  }, [isLoading, company]);

  const clearHandler = (name) => setValue(name, '');

  const postCodeLength = 4;

  const [addressBlur, setAddressBlur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const address = watch('address');
  const state = watch('state');
  const city = watch('city');
  const postcode = watch('zip_code');

  useEffect(() => {
    validateAddress({
      address: address,
      city: city,
      state: state,
      postcode: postcode,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'zip_code',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors,
      clearErrors,
    });
  }, [addressBlur, stateBlur, cityBlur, postcodeBlur]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.company.company')} ${t('attributes.company.details')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="company_name"
            control={control}
            rules={{
              required: `${t('attributes.company.company')} ${t('attributes.name')} ${Validation.general.required}`,
              minLength: {
                value: 2,
                message: Validation.company.companyName,
              },
              maxLength: {
                value: 255,
                message: Validation.company.companyName,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={`${t('attributes.company.company')} ${t('attributes.name')}`}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('company_name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="abn"
            control={control}
            rules={{
              required: `${t('attributes.company.abnNumber')} ${Validation.general.required}`,
              pattern: {
                value: /^\d{11}$/,
                message: Validation.general.abnNumber,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.company.abnNumber')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('abn');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            rules={{
              required: `${t('attributes.address')} ${Validation.general.required}`,
              maxLength: {
                value: 300,
                message: Validation.general.address,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.address')}
                fullWidth
                value={value}
                setBlur={setAddressBlur}
                onChange={(e) => {
                  onChange(e);
                  trigger('address');
                }}
                multiline
                rows={2}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />

          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="state"
              control={control}
              rules={{
                required: `${t('common.state')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={stateList}
                  label={t('common.state')}
                  setBlur={setStateBlur}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    setValue('city', null);
                    clearErrors('city');
                  }}
                  value={value}
                  error={error}
                  helperText={error ? error.message : ''}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              rules={{
                required: `${t('common.city')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={cityList}
                  label={t('common.city')}
                  setBlur={setCityBlur}
                  onChange={(e, newValue) => onChange(newValue)}
                  value={value}
                  error={error}
                  helperText={error ? error.message : ''}
                  onClear={() => clearHandler(name)}
                  disabledDropdown={!selectedState}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="zip_code"
              control={control}
              rules={{
                required: `${t('attributes.postCode')} ${Validation.general.required}`,
                validate: (value) => {
                  if (value && !/^\d+$/.test(value)) {
                    return Validation.general.numericValue;
                  }

                  return true;
                },
                minLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
                maxLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.postCode')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('zip_code');
                  }}
                  setBlur={setPostcodeBlur}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          </Box>
          <Typography variant="body1">
            Preview
            <span style={{ color: WARNING }}>*</span>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              columnGap: '48px',
              width: '100%',
            }}
          >
            <Controller
              name="logo_upload"
              control={control}
              rules={{
                required: `${t('attributes.company.company')} ${t('attributes.company.logo')} ${Validation.general.required}`,
              }}
              render={({ field: { value }, fieldState: { error } }) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '16px',
                  }}
                >
                  <UploadLogo logoUrl={value} />
                  {!value && error && (
                    <Typography variant="body2" color={WARNING}>
                      {error.message}
                    </Typography>
                  )}
                </Box>
              )}
            />
            <Controller
              name="logo_upload"
              control={control}
              render={({ field: { value } }) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '16px',
                  }}
                >
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-button-file"
                    type="file"
                    onChange={(event) => {
                      const file = event.target.files[0];

                      if (file) {
                        const reader = new FileReader();

                        reader.onload = function (e) {
                          const dataUrl = e.target.result;

                          setValue('logo_upload', dataUrl);
                          event.target.value = '';
                        };

                        reader.readAsDataURL(file); // Read the file as a data URL
                      }
                    }}
                  />
                  <label htmlFor="upload-button-file">
                    <CustomButton
                      text={`${t('attributes.company.upload')} ${t('attributes.company.company')} ${t('attributes.company.logoCapital')}`}
                      startIcon={<UploadFileIcon />}
                      disabled={value ? true : false}
                      component="span"
                    />
                  </label>

                  {value && (
                    <CustomButton
                      text={t('attributes.delete')}
                      startIcon={<DeleteOutlineIcon />}
                      sx={{
                        width: '86px',
                        background: WARNING,
                        '&:hover': {
                          backgroundColor: WARNING_HOVER,
                        },
                      }}
                      onClick={() => setValue('logo_upload', null)}
                    />
                  )}
                </Box>
              )}
            />
          </Box>
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.contact')} ${t('attributes.information')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="contact_name"
              control={control}
              rules={{
                required: `${t('attributes.contact')} ${t('attributes.name')} ${Validation.general.required}`,
                maxLength: {
                  value: 80,
                  message: Validation.general.contactName,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={`${t('attributes.contact')} ${t('attributes.name')}`}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('contact_name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name="cell_phone"
              control={control}
              rules={{
                required: `${t('attributes.company.cell_phone')} ${Validation.general.required}`,
                pattern: {
                  value: /^\d{10}$/,
                  message: Validation.general.cellPhone,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.company.cell_phone')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('cell_phone');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          </Box>
          <Controller
            name="email"
            control={control}
            rules={{
              required: `${t('attributes.email')} ${Validation.general.required}`,
              maxLength: {
                value: 254,
                message: Validation.general.email,
              },
              pattern: {
                value: EMAIL_REGEX,
                message: Validation.general.validEmail,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.email')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('email');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      {id && (
        <CustomCard>
          <Box sx={{ padding: '0 16px 0 16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true} // Enable message display
              messageOnChecked={labels('fireCompanyActiveCheckbox')}
              messageOnUnchecked={labels('fireCompanyInactiveCheckbox')}
            />
          </Box>
        </CustomCard>
      )}
    </FormWrapper>
  );
};

export default CompanyForm;
