export const ROUTENAME = {
  DEFAULT_ROUTE: '/',

  // Global and Super admin
  DASHBOARD: 'dashboard',

  // Global Admin
  COMPANIES: 'companies',
  SUPER_ADMINS: 'super-admins',
  EMAIL_TEMPLATE: 'email-template',
  NOTIFICATION_TEMPLATE: 'notification-template',
  FORMS: 'forms',
  CONTACTS: 'contacts',

  // Super admin
  USERS: 'users',
  CUSTOMERS: 'customers',
  SUPER_ADMIN: 'super-admin',
  OFFICE_STAFF: 'office-staff',
  STAFF_MANAGEMENT: 'staff-management',
  TECHNICIAN: 'technician',
  CUSTOMER: 'customer',
  PROPERTIES: 'properties',

  SCHEDULING: 'scheduling',
  ROUTINE_SERVICES: 'routine-services',
  SERVICE_REQUESTS: 'service-requests',
  SCHEDULE_BOARD: 'schedule-board',
  WORK_ORDERS: 'work-orders',
  QUOTES: 'quotes',
  DEFECTS_QUOTES: 'defects-quotes',
  DEFECTS: 'defects',
  QUOTES_SERVICES: 'quotes-services',
  WAREHOUSE: 'warehouse',
  PRODUCTS: 'products',
  ASSETS: 'assets',
  INVOICES: 'invoices',
  INVOICE: 'invoice',
  CONTROL_PANEL: 'control-panel',
  REPORTS: 'reports',
  FIRE_COMPANY: 'fire-company',
  GLOBAL_ADMIN: 'global-admins',
  TEMPLATES: 'templates',
  PURCHASE_ORDERS: 'purchase-orders',
  // Customer
  MAKE_REQUEST: 'make-request',
  ROUTINE_ACTIVITIES: 'routine-activities',
  REACTIVE_TASK: 'reactive-task',
  DOCUMENTS: 'documents',
  SETTINGS: 'settings',
  SUPPLIERS: 'suppliers',
  PRODUCT_CATALOGUE: 'product-catalogue',
};

export const AUTH_ROUTES = {
  DASHBOARD: 'dashboard',
  ROUTINE_SERVICES: 'routine_services',
  SERVICE_REQUESTS: 'service_requests',
  SCHEDULE_BOARD: 'schedule_board',
  WORK_ORDERS: 'work_orders',
  CUSTOMER_NAME: 'name',
  CONTACTS: 'contacts',
  PROPERTIES: 'properties',
  SUPER_ADMINS: 'super_admins',
  SUPER_ADMIN: 'super_admin',
  OFFICE_STAFFS: 'office_staffs',
  STAFF_MANAGEMENT: 'staff_management',
  TECHNICIANS: 'technicians',
  DEFECTS_QUOTES: 'defects_quotes',
  DEFECTS: 'defects',
  QUOTES_SERVICES: 'quotes_services',
  PRODUCTS: 'products',
  ASSETS: 'assets',
  INVOICES: 'invoices',
  GLOBAL_ADMINS: 'global_admins',
  CONTROL_PANEL: 'control_panel',
  REPORTS: 'reports',
  REPORT: 'report',
  COMPANIES: 'company_list',
  NOTIFICATION_TEMPLATE: 'notification_template',
  EMAIL_TEMPLATE: 'email_template',
  QUOTES: 'quotes',
  QUOTE: 'quote',
  INVOICE: 'invoice',
  PURCHASE_ORDERS: 'purchase_orders',
  MAKE_REQUEST: 'make_a_request',
  ROUTINE_ACTIVITIES: 'routine_activities',
  REACTIVE_TASK: 'reactive_task',
  DOCUMENTS: 'documents',
  SETTINGS: 'settings',
  SUPPLIERS: 'suppliers',
  PRODUCT_CATALOGUE: 'product_catalogue',
};
