import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../constants/APIConstants';
import { CUSTOMER, GLOBAL_ADMIN, SUPER_ADMIN } from '../constants/Roles';
import { GET_ME, GET_STATE_CITY_LIST, UPLOAD_DOCUMENT } from './actionPrefix';
import {
  axiosInstanceAuthAndFilter,
  axiosInstanceBase,
  axiosInstanceCompany,
} from './axios';

export const getMe = createAsyncThunk(GET_ME, async () => {
  const response = await axiosInstanceAuthAndFilter.get('me');
  const { data } = response;

  const isSuccessfulResponse = data.success || response.status === 200;
  const hasWebPortal = data.portals.some((item) => item.field_name === 'web');

  if (isSuccessfulResponse && hasWebPortal) {
    const roleName = data.role_name;
    const profileUUID = data.profile_uuid;

    let APIEndPoint = null;
    let axiosInstance = null;

    switch (roleName) {
      case SUPER_ADMIN:
        APIEndPoint = APIs.SUPER_ADMIN.GET_SUPER_ADMIN_BY_ID.replace(
          '{uuid}',
          profileUUID
        );
        axiosInstance = axiosInstanceAuthAndFilter;
        break;
      case CUSTOMER:
        APIEndPoint = APIs.CUSTOMER.GET_CUSTOMER_BY_ID.replace(
          '{uuid}',
          profileUUID
        );
        axiosInstance = axiosInstanceCompany;
        break;
      default:
        break;
    }

    if (APIEndPoint) {
      const getProfileResponse = await axiosInstance.get(APIEndPoint);
      const { data: profieData } = getProfileResponse;
      const profileData = profieData?.data?.[0];

      localStorage.setItem('user_info', JSON.stringify(data));
      localStorage.setItem('role_name', roleName);

      return { ...profileData, ...response.data };
    }

    if (roleName === GLOBAL_ADMIN) {
      localStorage.setItem('user_info', JSON.stringify(data));
      localStorage.setItem('role_name', roleName);

      return { ...response.data, name: 'Global Admin' };
    }

    return response;
  }

  return response;
});

export const getLocation = createAsyncThunk(GET_STATE_CITY_LIST, async () => {
  const response = await axiosInstanceCompany.get('location');

  return response.data;
});

export const uploadDocument = createAsyncThunk(
  UPLOAD_DOCUMENT,
  async ({ document_type = 'agreement', uploaded_by = 1, file }) => {
    let formData = new FormData();

    formData.append('file', file);
    const response = await axiosInstanceBase.post(
      `documents/upload`,
      formData,
      {
        params: {
          document_type: document_type,
          uploaded_by: uploaded_by,
        },
      }
    );

    return response.data;
  }
);
