import { createSlice } from '@reduxjs/toolkit';

import {
  getInvoiceList,
  getInvoicePropertyDropdownList,
  getInvoiceStatusDropdownList,
  getInvoiceTypeDropdownList,
} from './api';

const invoicesTypesDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const invoicesStatusDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const invoicesPropertyDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  invoiceList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const invoiceSlice = createSlice({
  name: 'invoices',
  initialState: {
    get: { ...initialGetData },
    invoicesTypesDropdownList,
    invoicesStatusDropdownList,
    invoicesPropertyDropdownList,
  },
  reducers: {
    resetInvoiceList(state) {
      state.get = { ...initialGetData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Invoice List
      .addCase(getInvoiceList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getInvoiceList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.get.isLoading = false;
        state.get.invoiceList = data;
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getInvoiceList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })

      // Get Invoice type List
      .addCase(getInvoiceTypeDropdownList.pending, (state) => {
        state.invoicesTypesDropdownList.isLoading = true;
      })
      .addCase(getInvoiceTypeDropdownList.fulfilled, (state, action) => {
        state.invoicesTypesDropdownList.isLoading = false;
        state.invoicesTypesDropdownList.data = action.payload;
      })
      .addCase(getInvoiceTypeDropdownList.rejected, (state, action) => {
        state.invoicesTypesDropdownList.isLoading = false;
        state.invoicesTypesDropdownList.error = action.error.message;
      })

      // Get Invoice Property List
      .addCase(getInvoicePropertyDropdownList.pending, (state) => {
        state.invoicesPropertyDropdownList.isLoading = true;
      })
      .addCase(getInvoicePropertyDropdownList.fulfilled, (state, action) => {
        state.invoicesPropertyDropdownList.isLoading = false;
        state.invoicesPropertyDropdownList.data = action.payload;
      })
      .addCase(getInvoicePropertyDropdownList.rejected, (state, action) => {
        state.invoicesPropertyDropdownList.isLoading = false;
        state.invoicesPropertyDropdownList.error = action.error.message;
      })

      // Get Invoice Status List
      .addCase(getInvoiceStatusDropdownList.pending, (state) => {
        state.invoicesStatusDropdownList.isLoading = true;
      })
      .addCase(getInvoiceStatusDropdownList.fulfilled, (state, action) => {
        state.invoicesStatusDropdownList.isLoading = false;
        state.invoicesStatusDropdownList.data = action.payload;
      })
      .addCase(getInvoiceStatusDropdownList.rejected, (state, action) => {
        state.invoicesStatusDropdownList.isLoading = false;
        state.invoicesStatusDropdownList.error = action.error.message;
      });
  },
});

export const { resetInvoiceList } = invoiceSlice.actions;

export default invoiceSlice.reducer;
