import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const getDropdownListHook = ({
  reducerName = '',
  dropdownListName = '',
  labelName = '',
  valueName = '',
}) => {
  // Get the category dropdown list from Redux store
  const stateDropdownList = useSelector(
    (state) => state?.[reducerName]?.[dropdownListName]?.data || []
  );

  // Get the loading state from Redux store
  const isLoading = useSelector(
    (state) => state?.[reducerName]?.[dropdownListName]?.isLoading
  );

  // Memoize the transformation of category data
  const data = useMemo(() => {
    if (stateDropdownList.length === 0) {
      return [];
    }

    return stateDropdownList.data.map((item) => ({
      label: item[labelName],
      value: item[valueName],
    }));
  }, [stateDropdownList]);

  // Return both the loading status and the transformed data
  return {
    [`${reducerName}DropdownLoading`]: isLoading,
    [`${reducerName}DropdownData`]: data,
  };
};

export default getDropdownListHook;
