import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { validateAddress } from '../../constants/common';
import { PRODUCT_TYPES, TAB_STATUS } from '../../constants/Constants';
import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import { getEquipmentTypesDropdown } from '../../store/equipmentTypes/api';
import {
  createProductCatalogue,
  getproductCatalogueList,
  getProductDetailsById,
  updateProductCatalogue,
} from '../../store/productCatalogue/api';
import { resetProductCatalogueList } from '../../store/productCatalogue/reducer';
import { getSupplierDropdownList } from '../../store/supplier/api';
import { Edit, View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import TabView from '../CommonComponents/TabView';
import { ProductCatalogueForm } from './ProductCatalogueForm';
import ViewProductCatalogue from './ViewProductCatalogue';

//default filters
const defaultFilters = {
  equipmentType: null,
  productType: null,
  supplier: null,
  status: null,
};

const defaultValues = {
  product_name: '',
  type: null,
  equipment_type: null,
  description: '',
  default_sell_price: '',
  supplier: null,
  cost_price: '',
  estimate_time: '',
};

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const ProductCatalogue = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleProductDetaiAPICall = (id) => {
    dispatch(getProductDetailsById(id));
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: `${t('attributes.productCatalogue.product_name')}`,
        field: 'product_name',
        sortable: true,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography
            variant="body1"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {}}
          >
            {row.product_name}
          </Typography>
        ),
      },
      {
        headerName: t('attributes.productCatalogue.product_type'),
        field: 'product_type',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.productCatalogue.supplier'),
        field: 'supplier',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.productCatalogue.cost_price'),
        field: 'cost_price',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.productCatalogue.default_sell_price'),
        field: 'default_sell_price',
        flex: 1,
        sortable: false,
      },
      {
        field: 'actions',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              onClick={() => {
                setOpen(true);
                handleProductDetaiAPICall(row?.uuid);
              }}
            />
            <Edit
              onClick={() => {
                setOpenForm(true);
                setProductCatalogueId(row?.id);
              }}
            />
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  // Redux state
  const {
    productCatalogueActiveList,
    productCatalogueInActiveList,
    size,
    page,
    total,
    totalCount,
  } = useSelector((state) => state.productCatalogue.get);
  const {
    isLoading: createLoading,
    createdProduct,
    error,
  } = useSelector((state) => state.productCatalogue.create);

  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState(defaultFilters);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(page);
  const [perPageData, setPerPageData] = useState(size);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [productCatalogueId, setProductCatalogueId] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const { getDropdown } = useSelector((state) => state.supplier);

  const supplierList = getDropdownDataHook({
    data: getDropdown?.data?.data,
    labelName: 'name',
    valueName: 'uuid',
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
  } = useForm({ defaultValues: defaultValues });
  const [serverErrors, setServerErrors] = useState([]);
  const [hasFormValues, sethasFormValues] = useState(false);

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const { equipmentTypesDropdownLoading, equipmentTypesDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentTypesDropdownList',
      labelName: 'display_name',
      valueName: 'code',
    });

  // Function to fetch data based on search, pagination, and filter
  const getProductCatalogues = useCallback(() => {
    // Set loading state based on active tab
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);
    dispatch(
      getproductCatalogueList({
        page: currentPage,
        search: searchText,
        size: perPageData,
        equipment_type: filters?.equipmentType?.value,
        type: filters?.productType?.value,
        active: activeTab === 0 ? TAB_STATUS.active : TAB_STATUS.inactive,
        supplier_uuid: filters?.supplier?.value,
        order: order,
        orderBy: orderBy,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    currentPage,
    perPageData,
    searchText,
    activeTab,
    filters,
    order,
    orderBy,
    columnVisibilityModel,
  ]);

  useEffect(() => {
    dispatch(getEquipmentTypesDropdown());
    dispatch(getSupplierDropdownList());

    return () => {
      dispatch(resetProductCatalogueList());
    };
  }, []);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getProductCatalogues, 500), [
    getProductCatalogues,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (createdProduct) {
          setOpenForm(false);
          if (!productCatalogueId) setActiveTab(0);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: !productCatalogueId
                ? t('message.productCatalogue.createdSuccessfully')
                : t('message.productCatalogue.updatedSuccessfully'),
            })
          );
        }
      }
    }
  }, [error, createLoading]);

  const rows =
    activeTab === 0
      ? productCatalogueActiveList?.map((cl) => ({
          id: cl.uuid,
          uuid: cl.uuid,
          product_name: cl.product_name,
          product_type: cl.type,
          supplier: cl.supplier,
          cost_price: cl.cost,
          default_sell_price: cl.sell_price,
          created_at: cl.created_at,
        }))
      : productCatalogueInActiveList?.map((cl) => ({
          id: cl.uuid,
          uuid: cl.uuid,
          product_name: cl.product_name,
          product_type: cl.type,
          supplier: cl.supplier,
          cost_price: cl.cost,
          default_sell_price: cl.sell_price,
          created_at: cl.created_at,
        }));

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const resetFilter = () => {
    setSearchText('');
    setFilters(defaultFilters);
  };

  const onSubmit = async (data) => {
    const hasError = await validateAddress({
      address: data.address,
      city: data.city,
      state: data.state,
      postcode: data.zip_code,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'zip_code',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors: setServerErrors,
    });

    if (hasError) return;

    const request = {
      product_name: data.product_name,
      type: data.type?.value,
      description: data.description,
      sell_price: data.sell_price,
      supplier_uuid: data.supplier_uuid?.value,
      um: data.um?.value,
      cost: data.cost,
      estimated_time_hours: data.estimated_time_hours,
      status: productCatalogueId
        ? data.active
          ? TAB_STATUS.active
          : TAB_STATUS.inactive
        : TAB_STATUS.active,
    };

    if (data.type.value !== 'labour') {
      request.equipment_type = data.equipment_type?.length
        ? data.equipment_type.map((equipment) => equipment?.value)
        : [];
    }

    if (!productCatalogueId) {
      dispatch(createProductCatalogue(request));
    } else {
      dispatch(updateProductCatalogue({ uuid: data?.uuid, data: request }));
    }
  };

  const noData = (
    <NewNoDataPage
      icon={<LocalShippingOutlinedIcon />}
      title={t('attributes.productCatalogue.products')}
      singularText={t('attributes.productCatalogue.product')}
      filterHeight={filterHeight(isFilterComponentVisible)}
      createBtnText={`${t('attributes.add')} ${t('attributes.productCatalogue.product')}`}
    />
  );
  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        isRefresh={true}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.equipmentType ||
          filters.productType ||
          filters.supplier ||
          filters.status
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('attributes.productCatalogue.equipment_type')}
            options={equipmentTypesDropdownData}
            value={filters?.equipmentType}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, equipmentType: newValue }))
            }
            width="190px"
            isLoadingData={equipmentTypesDropdownLoading}
          />
          <Autocomplete
            placeholder={t('attributes.productCatalogue.product_type')}
            options={PRODUCT_TYPES}
            value={filters?.productType}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, productType: newValue }))
            }
            width="190px"
            isLoadingData={false}
          />
          <Autocomplete
            placeholder={t('attributes.productCatalogue.supplier')}
            options={supplierList}
            value={filters?.supplier}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, supplier: newValue }))
            }
            width="190px"
            isLoadingData={false}
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const onTabChange = () => {
    setIsDataLoading(true);
    setFilters(defaultFilters);
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpenForm(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}

      <StyledMainWrapper
        title={t('attributes.productCatalogue.product_catalogue')}
        btn={
          <>
            <CustomButton
              text={`${t('attributes.add')} ${t('attributes.productCatalogue.product')}`}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {
                setOpenForm(true);
                setProductCatalogueId('');
                reset(defaultValues);
              }}
            />
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {}}
            />
          </>
        }
      >
        {!isDataLoading && totalCount === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading &&
          totalCount > 0 && (
            <TabView
              tabs={[
                {
                  label: t('common.active'),
                  component: renderedComponent,
                },
                {
                  label: t('common.inactive'),
                  component: renderedComponent,
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onTabChange={onTabChange}
            />
          )
        )}
      </StyledMainWrapper>
      {openForm && (
        <SwipeableDrawer
          open={openForm}
          title={
            !productCatalogueId
              ? `${t('attributes.productCatalogue.add_product')}`
              : `${t('attributes.productCatalogue.edit_product')}`
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpenForm(false);
          }}
          footerButton={
            <CustomButton
              text={createLoading ? t('common.loading') : t('attributes.save')}
              disabled={createLoading}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <ProductCatalogueForm
            id={productCatalogueId}
            control={control}
            reset={reset}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            supplierList={supplierList}
            setServerErrors={setServerErrors}
            equipmentTypeList={equipmentTypesDropdownData}
          />
        </SwipeableDrawer>
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={t('attributes.productCatalogue.view_product')}
          onClose={() => setOpen(false)}
        >
          <ViewProductCatalogue />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default ProductCatalogue;
