import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Internal
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import { isArray } from 'lodash';

import { SWIPEABLE_DRAWER_STEPPER_WIDTH } from '../../constants/Typography';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  getBuildingClassList,
  getBuildingEraList,
  getBuildingStandardList,
  getBuildingTypeList,
  getConstructionClassList,
  getLocalGovernmentAreaList,
  getParentPropertyList,
  getProperty,
  getPropertyAccessProcedureList,
  getPropertyAccessScheduleList,
  getPropertyAccountManagerList,
  getPropertyBillingCardList,
  getPropertyCustomerList,
  getPropertyList,
  getPropertyStatusList,
  getPropertyTagsList,
} from '../../store/property/api';
import {
  resetCreatePropertyList,
  resetUpdatePropertyList,
} from '../../store/property/reducer';
import { getTechnicianNamesList } from '../../store/technician/api';
import { getZonesDropdownList } from '../../store/zones/api';
import { debounce, formattedDate } from '../../utils';
import { Edit, View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
// import TabView from "../CommonComponents/TabView";
// import { Edit, InActive } from "../CommonComponents/ActionComponent";
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import PropertyDetails from './PropertyDetails';
import { PropertyForm } from './PropertyForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 428 : 300);

// Property Component
const Properties = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    propertyList,
    createPropertyList,
    updatePropertyList,
    propertyStatusList,
    propertyTagList,
    propertyAccessScheduleList,
    propertyAccessProcedureList,
    propertyBuildingEraList,
    propertyBuildingClassList,
    propertyConstructionClassList,
    propertyBuildingTypeList,
    propertyBuildingStandardList,
    propertyLocalGovernmentAreaList,
    parentPropertyList,
    propertyAccountManagerList,
    propertyCustomerList,
    propertyBillingCardList,
  } = useSelector((state) => state.property);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [selectStatus, setSelectStatus] = useState(null);
  const [selectTags, setSelectTags] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState(null);
  const [selectBillingCard, setSelectBillingCard] = useState(null);
  const [formMode, setFormMode] = useState('create');
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [open, setOpen] = useState(false);
  const [propertiesData, setPropertiesData] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [serverErrors, setServerErrors] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [formStatusList, setFormStatusList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [accessScheduleList, setAccessScheduleList] = useState([]);
  const [accessProcedureList, setAccessProcedureList] = useState([]);
  const [buildingEraList, setBuildingEraList] = useState([]);
  const [buildingClassList, setBuildingClassList] = useState([]);
  const [constructionClassList, setConstructionClassList] = useState([]);
  const [selectConstructionClass, setSelectConstructionClass] = useState(null);
  const [buildingTypeList, setBuildingTypeList] = useState([]);
  const [buildingStandardList, setBuildingStandardList] = useState([]);
  const [localGovermentAreaList, setLocalGovernmentAreaList] = useState([]);
  const [parentPropertyListData, setParentPropertyListData] = useState([]);
  const [selectParentProperty, setSelectParentProperty] = useState(null);
  const [accountManagerList, setAccountManagerList] = useState([]);
  const [selectAccountManager, setSelectAccountManager] = useState(null);
  const methods = useForm();
  const { clearErrors, setError, watch, setValue } = methods;
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [propertyId, setPropertyId] = useState();
  const [propertyDataloading, setPropertyDataLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isFromDetails, setIsFromDetails] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [propertyNumId, setPropertyNumId] = useState();

  const { zonesDropdownLoading, zonesDropdownData } = getDropdownListHook({
    reducerName: 'zones',
    dropdownListName: 'zonesDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });

  // Use the custom hook
  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(
      Object.values(watchedFields).some((value) => {
        if (isArray(value)) {
          return value.length > 0;
        } else {
          return value;
        }
      })
    );
  }, [watchedFields]);

  useEffect(() => {
    dispatch(getZonesDropdownList());
  }, []);

  const handlePropertyDetaiAPICall = (id) => {
    dispatch(getProperty(id))
      .then((response) => {
        setSelectedProperty(response.payload?.data?.[0]); // Set the complete property data
      })
      .catch((error) => {
        console.error('Error fetching property data:', error);
      })
      .finally(() => {
        setPropertyDataLoading(false); // Set loading to false whether success or error occurs
      });
  };

  const columns = useMemo(() => {
    const baseColumn = [
      {
        headerName: t('attributes.property.propertyId'),
        field: 'pid',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.property.property')} ${t('attributes.name')}`,
        field: 'property_name',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography
            variant="body1"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setPropertyDataLoading(true);
              setPropertyId(row?.uuid);
              setPropertyNumId(row.id);
              handlePropertyDetaiAPICall(row?.uuid);
            }}
          >
            {row.property_name}
          </Typography>
        ),
      },
      {
        headerName: t('attributes.address'),
        field: 'address',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.customer.customer'),
        field: 'customer_uuid',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.account_manager.account_manager'),
        field: 'account_manager_uuid',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.property.status'),
        field: 'status',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.property_status.display_name),
      },
      {
        headerName: t('attributes.customer.created_at'),
        field: 'created_at',
        sortable: true,
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              onClick={() => {
                setPropertyDataLoading(true);
                setPropertyId(row?.uuid);
                setPropertyNumId(row.id);
                handlePropertyDetaiAPICall(row?.uuid);
              }}
            />
            <Edit
              onClick={() => {
                setOpen(true);
                setFormMode('edit');
                setIsFromDetails(false);
                dispatch(getProperty(row.uuid)).then((response) => {
                  setSelectedProperty(response.payload?.data?.[0]); // Set the complete property data
                });
              }}
            />
          </Box>
        ),
      },
    ];

    return baseColumn;
  }, []);

  const getAllProperties = (text = '') => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');
    const selectedTags =
      selectTags.length > 0
        ? selectTags.map((item) => item.value).join(',')
        : null;

    setIsDataLoading(true);
    const req = {
      page: currentPage,
      size: perPageData,
      status: selectStatus?.value,
      tags: selectedTags,
      constructionClass: selectConstructionClass?.value,
      customerUuid: selectCustomer?.value,
      parentProperty: selectParentProperty?.value,
      billingCard: selectBillingCard?.value,
      accountManager: selectAccountManager?.value,
      search: text,
      fromDate: fromDate,
      toDate: toDate,
      order: order,
      orderBy: orderBy,
      list_column_names: visibleFieldsString,
    };

    dispatch(getPropertyList(req)).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  };

  useEffect(() => {
    getAllProperties(searchText);
  }, [
    currentPage,
    perPageData,
    selectStatus,
    selectCustomer,
    selectTags,
    selectConstructionClass,
    createPropertyList.data,
    updatePropertyList.data,
    selectParentProperty,
    selectBillingCard,
    selectAccountManager,
    order,
    orderBy,
    fromDate,
    toDate,
    columnVisibilityModel,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    selectStatus,
    selectCustomer,
    searchText,
    selectTags,
    selectConstructionClass,
    fromDate,
    toDate,
  ]);

  const debounceSearch = useCallback(
    debounce((text) => {
      getAllProperties(text);
    }, 500),
    []
  );

  const handleStatus = (status, color) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          bgcolor: color,
          height: '16px',
          width: '16px',
          borderRadius: '50%',
        }}
      ></Box>
      <Typography
        sx={{
          color: color,
          fontSize: '12px',
          weight: '600',
          marginLeft: '8px',
        }}
      >
        {status}
      </Typography>
    </Box>
  );

  const formatStatus = (status) => {
    if (status?.toLowerCase() == 'inactive') {
      return handleStatus(status, '#C54036');
    }
    if (status?.toLowerCase() == 'active') {
      return handleStatus(status, '#95C020');
    }
    if (status?.toLowerCase() == 'hold') {
      return handleStatus(status, '#FD7E14');
    }
    if (status?.toLowerCase() == 'setup') {
      return handleStatus(status, '#007BFF');
    }
  };

  useEffect(
    () => () => {
      dispatch(resetUpdatePropertyList());
      dispatch(resetCreatePropertyList());
    },
    []
  );

  // Status Filter Dropdown list data

  useEffect(() => {
    dispatch(getPropertyStatusList());
    dispatch(getTechnicianNamesList());
  }, [dispatch]);

  // Set data for filter dropdown list
  useEffect(() => {
    if (propertyStatusList?.data) {
      const statusList = propertyStatusList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setStatusList(statusList);
    }
  }, [propertyStatusList, dispatch]);

  // Set data for form dropdown list
  useEffect(() => {
    if (propertyStatusList?.data) {
      const statusList = propertyStatusList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setFormStatusList(statusList);
    }
  }, [propertyStatusList, dispatch]);

  // Property tags dropdown data
  useEffect(() => {
    dispatch(getPropertyTagsList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyTagList?.data) {
      const tagsList = propertyTagList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setTagsList(tagsList);
    }
  }, [propertyTagList, dispatch]);

  // Property access schedule dropdown data
  useEffect(() => {
    dispatch(getPropertyAccessScheduleList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyAccessScheduleList?.data) {
      const accessSchedule = propertyAccessScheduleList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setAccessScheduleList(accessSchedule);
    }
  }, [propertyAccessScheduleList, dispatch]);

  // Property access procedure dropdown data
  useEffect(() => {
    dispatch(getPropertyAccessProcedureList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyAccessProcedureList?.data) {
      const accessProcedure = propertyAccessProcedureList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setAccessProcedureList(accessProcedure);
    }
  }, [propertyAccessProcedureList, dispatch]);

  // Property Building era dropdown data
  useEffect(() => {
    dispatch(getBuildingEraList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyBuildingEraList?.data) {
      const buildinEra = propertyBuildingEraList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setBuildingEraList(buildinEra);
    }
  }, [propertyBuildingEraList, dispatch]);

  // Property Building era dropdown data
  useEffect(() => {
    dispatch(getBuildingClassList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyBuildingClassList?.data) {
      const buildinClass = propertyBuildingClassList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setBuildingClassList(buildinClass);
    }
  }, [propertyBuildingClassList, dispatch]);

  // Property construction list dropdown data
  useEffect(() => {
    dispatch(getConstructionClassList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyConstructionClassList?.data) {
      const constructionClass = propertyConstructionClassList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setConstructionClassList(constructionClass);
    }
  }, [propertyConstructionClassList, dispatch]);

  // Property building list dropdown data
  useEffect(() => {
    dispatch(getBuildingTypeList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyBuildingTypeList?.data) {
      const buildingTypeList = propertyBuildingTypeList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setBuildingTypeList(buildingTypeList);
    }
  }, [propertyBuildingTypeList, dispatch]);

  // Property building standard dropdown data
  useEffect(() => {
    dispatch(getBuildingStandardList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyBuildingStandardList?.data) {
      const buildingStandardList =
        propertyBuildingStandardList?.data?.data?.map((item) => ({
          label: item.standard_display_name,
          value: item.id,
        }));

      setBuildingStandardList(buildingStandardList);
    }
  }, [propertyBuildingStandardList, dispatch]);

  // Property local government area dropdown data
  useEffect(() => {
    dispatch(getLocalGovernmentAreaList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyLocalGovernmentAreaList?.data) {
      const localGArea = propertyLocalGovernmentAreaList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setLocalGovernmentAreaList(localGArea);
    }
  }, [propertyLocalGovernmentAreaList, dispatch]);

  // Parent property dropdown data
  useEffect(() => {
    dispatch(getParentPropertyList());
  }, [dispatch]);

  useEffect(() => {
    if (parentPropertyList?.data) {
      const parentProperty = parentPropertyList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setParentPropertyListData(parentProperty);
    }
  }, [parentPropertyList, dispatch]);

  // Property account manager dropdown data
  useEffect(() => {
    dispatch(getPropertyAccountManagerList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyAccountManagerList?.data) {
      const accManager = propertyAccountManagerList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setAccountManagerList(accManager);
    }
  }, [propertyAccountManagerList, dispatch]);

  // Property Customer dropdown data
  useEffect(() => {
    dispatch(getPropertyCustomerList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyCustomerList?.data) {
      const customersList = propertyCustomerList?.data?.data?.map((item) => ({
        label: item.customer_name,
        value: item.uuid,
      }));

      setCustomerList(customersList);
    }
  }, [propertyCustomerList, dispatch]);

  // Property billing card dropdown data
  useEffect(() => {
    dispatch(getPropertyBillingCardList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyList.data) {
      const updatedData = propertyList.data.data?.map((item) => {
        const updatedItem = Object.keys(item).reduce((acc, key) => {
          acc[key] = item[key] === ('' || null) ? '-' : item[key];

          return acc;
        }, {});

        updatedItem.customerUuid = item.customer_uuid;
        updatedItem.account_manager_uuid =
          item.property_account_manager?.display_name;
        updatedItem.status = item.property_status.display_name;
        updatedItem.created_at = item.created_at;

        return updatedItem;
      });

      setPropertiesData(updatedData);
    }
  }, [propertyList, dispatch]);

  useEffect(() => {
    if (createPropertyList.loading !== null) {
      if (createPropertyList.error) {
        if (createPropertyList.error.errorDetails) {
          setServerErrors(createPropertyList.error.errorDetails);
        }
      } else {
        if (createPropertyList.data) {
          setOpen(false);
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createPropertyList.data.message,
            })
          );
        }
      }
    }
  }, [createPropertyList.data, createPropertyList.error]);

  useEffect(() => {
    if (updatePropertyList.loading !== null) {
      if (updatePropertyList.error) {
        if (updatePropertyList.error.errorDetails) {
          setServerErrors(updatePropertyList.error.errorDetails);
        }
      } else {
        if (updatePropertyList.data) {
          setPropertyDataLoading(true);
          setOpen(false);
          handlePropertyDetaiAPICall(updatePropertyList.data?.data[0]?.uuid);
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: updatePropertyList.data.message,
            })
          );
        }
      }
    }
  }, [updatePropertyList.data, updatePropertyList.error]);

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const noData = (
    <NewNoDataPage
      icon={<HomeWorkOutlinedIcon />}
      title={t('attributes.property.property')}
      createBtnText={`${t('attributes.add')} ${t('attributes.property.property')}`}
      singularText={t('attributes.property.property')}
      filterHeight={filterHeight(isFilterComponentVisible)}
    />
  );

  const onResetFilter = () => {
    setSelectCustomer(null);
    setSelectStatus(null);
    setSelectTags([]);
    setFromDate(null);
    setToDate(null);
    setSearchText('');
    setSelectBillingCard(null);
    setSelectConstructionClass(null);
    setSelectParentProperty(null);
    setSelectAccountManager(null);
  };

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        searchText={searchText}
        onSearchChange={(e) => {
          setSearchText(e.target.value);
          debounceSearch(e.target.value);
        }}
        isActive={isFilterComponentVisible}
        onResetFilter={onResetFilter}
        isResetButtonVisible={
          selectStatus ||
          selectCustomer ||
          selectTags.length > 0 ||
          fromDate ||
          toDate ||
          selectConstructionClass ||
          selectParentProperty ||
          selectAccountManager
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('attributes.property.status')}
            options={statusList}
            value={selectStatus}
            onChange={(e, newValue) => setSelectStatus(newValue)}
            width="190px"
          />
          <Autocomplete
            placeholder={t('attributes.customer.customer')}
            options={customerList}
            value={selectCustomer}
            onChange={(e, newValue) => setSelectCustomer(newValue)}
            width="190px"
          />
          <Autocomplete
            placeholder={`${t('attributes.property.parent')} ${t('attributes.property.property')}`}
            options={parentPropertyListData}
            value={selectParentProperty}
            onChange={(e, newValue) => setSelectParentProperty(newValue)}
            width="190px"
          />
          <Autocomplete
            placeholder={t('attributes.account_manager.account_manager')}
            options={accountManagerList}
            value={selectAccountManager}
            onChange={(e, newValue) => setSelectAccountManager(newValue)}
            width="190px"
          />
          <CustomDateRangePicker
            onOkClick={(val) => {
              setFromDate(val[0]);
              setToDate(val[1]);
            }}
            onClear={() => {
              setFromDate(null);
              setToDate(null);
            }}
            fromDate={fromDate}
            toDate={toDate}
          />
          <Autocomplete
            placeholder={t('attributes.property.billingCard')}
            options={propertyBillingCardList.data}
            value={selectBillingCard}
            onChange={(e, newValue) => setSelectBillingCard(newValue)}
            width="190px"
            isLoadingData={propertyBillingCardList.loading}
          />
          <Autocomplete
            placeholder={t('attributes.property.tags')}
            options={tagsList}
            value={selectTags}
            handleMultipleFilter={true}
            multiple={true}
            width="190px"
            onChange={(e, newValue) => setSelectTags(newValue)}
          />
          <Autocomplete
            placeholder={t('attributes.property.constructionClass')}
            options={constructionClassList}
            value={selectConstructionClass}
            width="190px"
            onChange={(e, newValue) => setSelectConstructionClass(newValue)}
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={propertiesData}
        total={propertyList.total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const mainWrapper = (
    <StyledMainWrapper
      title={t('attributes.property.property')}
      btn={
        <>
          <CustomButton
            text={`${t('attributes.add')} ${t('attributes.property.property')}`}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => {
              setOpen(true);
              setFormMode('create');
              setActiveStep(0);
            }}
          />
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {}}
          />
        </>
      }
    >
      <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
        {!isDataLoading && propertyList?.totalCount === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading && propertyList?.totalCount > 0 && renderedComponent
        )}
      </Box>
    </StyledMainWrapper>
  );

  const handleEditPropertyForm = (step) => {
    setIsFromDetails(true);
    setActiveStep(step);
    setOpen(true);
    setFormMode('edit');
    dispatch(getProperty(propertyId)).then((response) => {
      setSelectedProperty(response.payload?.data?.[0]); // Set the complete property data
    });
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {!propertyId ? (
        mainWrapper
      ) : (
        <PropertyDetails
          propertyId={propertyId}
          setPropertyId={setPropertyId}
          propertyNumId={propertyNumId}
          selectedProperty={selectedProperty}
          propertyDataloading={propertyDataloading}
          handleEditPropertyForm={handleEditPropertyForm}
        />
      )}
      <SwipeableDrawer
        open={open}
        title={
          formMode === 'create'
            ? `${t('attributes.add')} ${t('attributes.property.property')}`
            : `${t('attributes.edit')} ${t('attributes.property.property')}`
        }
        onClose={() => {
          hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
        }}
        width={SWIPEABLE_DRAWER_STEPPER_WIDTH}
      >
        <FormProvider {...methods}>
          <PropertyForm
            formMode={formMode}
            propertyData={selectedProperty}
            formStatusList={formStatusList}
            tagsList={tagsList}
            accessScheduleList={accessScheduleList}
            accessProcedureList={accessProcedureList}
            buildingEraList={buildingEraList}
            buildingClassList={buildingClassList}
            constructionClassList={constructionClassList}
            buildingTypeList={buildingTypeList}
            buildingStandardList={buildingStandardList}
            localGovermentAreaList={localGovermentAreaList}
            parentPropertyListData={parentPropertyListData}
            accountManagerList={accountManagerList}
            customerList={customerList}
            billingCardList={propertyBillingCardList.data}
            watch={watch}
            setValue={setValue}
            isOpen={open}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isFromDetails={isFromDetails}
            zoneList={zonesDropdownData}
            isZoneLoading={zonesDropdownLoading}
            setServerErrors={setServerErrors}
            createLoading={createPropertyList.loading}
            updateLoading={updatePropertyList.loading}
          />
        </FormProvider>
      </SwipeableDrawer>
    </>
  );
};

export default Properties;
