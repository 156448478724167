import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { WORK_ORDER } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

export const getWorkOrderList = createAsyncThunk(
  WORK_ORDER.GET_WORK_ORDERS,
  async (
    {
      page,
      search,
      limit,
      order,
      orderBy,
      customer,
      property,
      workOrderTypes,
      technician,
      status,
      appoitmentFromDate,
      appoitmentToDate,
      dueFromDate,
      dueToDate,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search_by', search);
    if (limit) params.append('size', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (customer) params.append('customer', customer);
    if (property) params.append('property', property);
    if (workOrderTypes) params.append('workOrderTypes', workOrderTypes);
    if (technician) params.append('technician', technician);
    if (status) params.append('status', status);
    if (appoitmentFromDate)
      params.append('appoitment_from_date', appoitmentFromDate);
    if (dueFromDate) params.append('due_from_date', dueFromDate);
    if (appoitmentToDate) params.append('appoitment_to_date', appoitmentToDate);
    if (dueToDate) params.append('due_to_date', dueToDate);

    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_WORK_ORDERS}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getWorkOrder = createAsyncThunk(
  WORK_ORDER.GET_WORK_ORDER,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_WORK_ORDER.replace('{uuid}', id)}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
