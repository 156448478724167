import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId } from 'lodash';

import { DIVIDER_COLOR, STATUS_COLORS } from '../../constants/Colors';
import {
  DASHBOARD,
  MONTHS_DROPDOWN,
  SIX_MONTHS,
  TWELEVE_MONTHS,
} from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import CustomLineChart from '../Chart/LineChart/CustomLineChart';
import StackedBarChart from '../Chart/StackedBarChart/StackedBarChart';
import DashboardCard from '../CommonComponents/DashboardCard';
import ChartCard from '../Dashboard/ChartCard';

const defaultRangeData = {
  invoiceStatusRange: MONTHS_DROPDOWN[0],
  invoiceAmountRange: MONTHS_DROPDOWN[0],
};

const InvoiceDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [ranges, setRanges] = useState(defaultRangeData);

  const quoteCardData = [
    {
      title: `${t('common.status.pending')} ${t('attributes.invoice.invoice')}`,
      status: t('common.status.pending'),
      value: 35,
    },
    {
      title: `${t('attributes.invoice.paid')} ${t('attributes.invoice.invoice')}`,
      status: t('attributes.invoice.paid'),
      value: 26,
    },
    {
      title: `${t('attributes.total')} ${t('attributes.amount')} ${t('attributes.invoice.due')}`,
      value: '$2202',
    },
    {
      title: `${t('attributes.total')} ${t('attributes.amount')} ${t('attributes.invoice.paid')}`,
      value: '$7560',
    },
    {
      title: `${t('common.status.overdue')} ${t('attributes.invoice.invoice')}`,
      status: t('common.status.overdue'),
      value: 5,
    },
  ];

  const colors = [
    STATUS_COLORS.PENDING,
    STATUS_COLORS.APPROVED,
    STATUS_COLORS.IN_PROGRESS,
    STATUS_COLORS.REJECTED,
  ];

  //Static data for charts
  const sixMonthsData = {
    pending: [40, 100, 140, 200, 200, 250],
    approved: [20, 40, 50, 25, 60, 50],
    quotesAmount: [2, 5.5, 2, 8.5, 1.5, 5],
    xAxisLabels: SIX_MONTHS,
  };
  const twelveMonthsData = {
    pending: [40, 100, 140, 200, 200, 250, 260, 300, 320, 400, 350, 280],
    approved: [20, 40, 50, 25, 60, 50, 55, 70, 80, 90, 60, 45],
    quotesAmount: [2, 5.5, 2, 8.5, 1.5, 5, 7, 6.5, 8, 4, 5, 9],
    xAxisLabels: TWELEVE_MONTHS,
  };

  //Quote status data
  const currentQuoteStatusData =
    ranges.invoiceStatusRange?.value === 6 ? sixMonthsData : twelveMonthsData;
  const quotesStatusXAxisLabels = currentQuoteStatusData.xAxisLabels;
  const quotesStatusData = [
    {
      data: currentQuoteStatusData.pending,
      label: t('common.status.pending'),
      id: 'pendingId',
      stack: 'total',
    },
    {
      data: currentQuoteStatusData.approved,
      label: t('common.status.approved'),
      id: 'approvedId',
      stack: 'total',
    },
  ];

  //Quote amount data
  const currentQuoteAmountData =
    ranges.invoiceAmountRange?.value === 6 ? sixMonthsData : twelveMonthsData;
  const quotesAmountXAxisLabels = currentQuoteAmountData.xAxisLabels;
  const quotesAmountData = currentQuoteAmountData.quotesAmount;

  const handleCardClick = (status) => {
    if (status !== DASHBOARD) {
      const formattedStatus = status.toLowerCase().replaceAll(' ', '-');

      navigate(
        `/${ROUTENAME.DASHBOARD}/${ROUTENAME.INVOICES}/${formattedStatus}`
      );
    } else {
      navigate(`/${ROUTENAME.DASHBOARD}/${ROUTENAME.INVOICES}`);
    }
  };

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR }}>
      <Box sx={{ width: '100%', padding: '16px 0 30px 0px' }}>
        <Grid container spacing={2}>
          {quoteCardData?.map((item) => (
            <Grid xs={12} sm={6} md={3} key={uniqueId('dashboardCard')}>
              <DashboardCard
                title={item.title}
                value={item.value}
                onClick={() => handleCardClick(item.title)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ display: 'flex', gap: '16px' }}>
        {/* Quote Status Chart */}
        <Box sx={{ flex: 1 }}>
          <ChartCard
            title={`${t('attributes.invoice.invoice')} ${t('attributes.invoice.byStatus')}`}
            options={MONTHS_DROPDOWN}
            selectedType={ranges.invoiceStatusRange}
            isEditableChart={false}
            setSelectedType={(id, val) =>
              setRanges((prev) => ({
                ...prev,
                invoiceStatusRange: val,
              }))
            }
            height="257px"
            isSettingMode={true}
          >
            <StackedBarChart
              colors={colors}
              data={quotesStatusData}
              xLabels={quotesStatusXAxisLabels}
              legendPosition={{ vertical: 'top', horizontal: 'center' }}
            />
          </ChartCard>
        </Box>

        {/* Quote Amount Chart */}
        <Box sx={{ flex: 1 }}>
          <ChartCard
            title={`${t('attributes.invoice.invoice')} ${t('attributes.amount')}`}
            options={MONTHS_DROPDOWN}
            selectedType={ranges.invoiceAmountRange}
            isEditableChart={false}
            setSelectedType={(id, val) =>
              setRanges((prev) => ({
                ...prev,
                invoiceAmountRange: val,
              }))
            }
            height="257px"
            isSettingMode={true}
          >
            <CustomLineChart
              xAxisLabels={quotesAmountXAxisLabels}
              chartData={quotesAmountData}
              prefix={'$'}
              height={257}
            />
          </ChartCard>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceDashboard;
