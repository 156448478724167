import { useState } from 'react';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { InputAdornment, TextField, Typography, styled } from '@mui/material';

import { WARNING } from '../../constants/Colors';

const CustomInput = styled(TextField)(({ theme, placeholder }) => ({
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
  '& .MuiInputBase-root': {
    ...theme.typography.body2,
    paddingRight: '8px',
  },
  '& .MuiInputLabel-outlined': {
    top: '-6px',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    top: '3px',
  },
  '& .MuiInputBase-input': {
    ...(placeholder === 'Search'
      ? { padding: '10px 14px', paddingLeft: '0px' }
      : { padding: '10px 14px' }),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    legend: {
      p: {
        fontSize: 'inherit',
      },
    },
  },
  '& .MuiInputBase-root.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.6)',
    background: 'rgba(235, 235, 235, 1)',
  },
}));

const CustomTextField = ({
  name,
  color = 'primary',
  label = '',
  multiline = false,
  placeholder,
  rows = 1,
  size = 'medium',
  type = 'text',
  value = '',
  onChange = () => {},
  isRequired = true,
  setBlur = () => {},
  onClear = () => {},
  onBlur = null,
  helperText,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
    setBlur(false);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    setBlur(true);
    if (onBlur && typeof onBlur === 'function' && e.target?.value) {
      onBlur();
    }
  };

  const valueLength =
    value !== null && value !== undefined ? String(value).length : 0;

  return (
    <CustomInput
      name={name}
      color={color}
      multiline={multiline}
      placeholder={placeholder}
      label={
        label ? (
          <>
            <Typography variant="body2">
              {label}
              {isRequired && <span style={{ color: WARNING }}>*</span>}
            </Typography>
          </>
        ) : (
          ''
        )
      }
      InputProps={{
        endAdornment: isFocused && valueLength > 0 && (
          <InputAdornment position="end">
            <HighlightOffOutlinedIcon
              onClick={onClear}
              onMouseDown={handleMouseDown}
              style={{ cursor: 'pointer' }}
            />
          </InputAdornment>
        ),
      }}
      rows={rows}
      size={size}
      type={type}
      value={value}
      onChange={onChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      helperText={helperText}
      {...rest}
    />
  );
};

export default CustomTextField;
