import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
// MUI
import { Box, Paper } from '@mui/material';

// Internal
import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../constants/Typography';
import { loginRequest } from '../../hoc/authConfig';
import { getLocation, getMe } from '../../store/CommonAPI';
import Header from './Header';
import Sidebar from './Sidebar';
const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accounts } = useMsal();

  const [isNavbarOpen, setIsNavbarOpen] = useState(true);

  const { isUnAuthorised, isAuthLoading } = useSelector(
    (state) => state.common
  );

  const { error } = useMsalAuthentication(InteractionType.Popup, loginRequest);

  useEffect(() => {
    if (accounts.length) {
      dispatch(getMe());
    }
  }, []);

  useEffect(() => {
    if (isUnAuthorised && !isAuthLoading) {
      localStorage.clear();
      navigate(ROUTENAME.DEFAULT_ROUTE);
    }
  }, [isUnAuthorised, isAuthLoading]);

  useEffect(() => {
    if (localStorage.getItem('user_info')) {
      // Store role into localstorage
      dispatch(getLocation());
    }
  }, [localStorage.getItem('user_info')]);

  if (error) {
    navigate(ROUTENAME.DEFAULT_ROUTE);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Header isNavbarOpen={isNavbarOpen} setIsNavbarOpen={setIsNavbarOpen} />
      <Sidebar isNavbarOpen={isNavbarOpen} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
          marginTop: `${HEADER_HEIGHT}px`,
          background: DIVIDER_COLOR,
        }}
      >
        <Paper
          sx={{
            height: '100%',
            bgcolor: SECONDARY,
            boxShadow: 0,
            position: 'relative',
          }}
        >
          <Outlet />
        </Paper>
      </Box>
    </Box>
  );
};

export default Layout;
