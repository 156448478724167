import React from 'react';

import { Box, Typography } from '@mui/material';

import { FONT_SIZE } from '../../constants/Constants';

const StatusLabel = ({
  label,
  color,
  height = '16px',
  width = '16px',
  fontSize = FONT_SIZE,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        bgcolor: color,
        height: height,
        width: width,
        borderRadius: '50%',
      }}
    />
    <Typography
      sx={{
        color: color,
        fontSize: fontSize,
        fontWeight: '600',
        marginLeft: '8px',
      }}
    >
      {label}
    </Typography>
  </Box>
);

export default StatusLabel;
