import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { SECONDARY } from '../../constants/Colors';
import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import {
  getQuotesList,
  getQuotesPropertyDropdownList,
  getQuotesStatusDropdownList,
  getQuotesTypeDropdownList,
} from '../../store/quotes/api';
import { resetQuoteList } from '../../store/quotes/reducer';
import { capitalizeFirstLetter, formattedDate } from '../../utils';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import QuoteTypeLabel from '../CommonComponents/QuoteTypeLabel';
import StatusLabel from '../CommonComponents/StatusLabel';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import QuoteInvoice from './QuoteInvoice';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  property: null,
  quoteType: null,
  status: null,
  dateRange: {
    fromData: null,
    toDate: null,
  },
};

const QuotesList = () => {
  const { status } = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { quoteList, total } = useSelector((state) => state.quotes.get);
  const { data: quoteStatusList, isLoading: quoteStatusListLoading } =
    useSelector((state) => state.quotes.quotesStatusDropdownList);
  const { data: quotePropertyList, isLoading: quotePropertyListLoading } =
    useSelector((state) => state.quotes.quotesPropertyDropdownList);

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const { quotesDropdownLoading, quotesDropdownData } = getDropdownListHook({
    reducerName: 'quotes',
    dropdownListName: 'quotesTypesDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });

  const quotesStatusList = getDropdownDataHook({
    data: quoteStatusList?.data,
    labelName: 'display_name',
    valueName: 'name',
  });
  const quotesPropertyList = getDropdownDataHook({
    data: quotePropertyList?.data,
    labelName: 'display_name',
    valueName: 'name',
  });

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'qid',
        headerName: 'Quote ID',
        flex: 1,
        sortable: true,
      },
      {
        field: 'property_name',
        headerName: 'Property Name',
        flex: 1,
        sortable: false,
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatType(row.type),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      {
        field: 'quote_date',
        headerName: 'Quote Date',
        flex: 1,
        sortable: false,
      },
      {
        field: 'due_date',
        headerName: 'Due Date',
        flex: 1,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
      },
      {
        field: 'edit',
        headerName: 'Actions',
        flex: 0.5,
        sortable: false,
        renderCell: () => (
          <View
            onClick={() => {
              setOpen(true);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  useEffect(() => {
    dispatch(getQuotesTypeDropdownList());
    dispatch(getQuotesPropertyDropdownList());
    dispatch(getQuotesStatusDropdownList());

    return () => {
      dispatch(resetQuoteList());
    };
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllQuoteList = useCallback(() => {
    setIsDataLoading(true);

    dispatch(
      getQuotesList({
        order: order,
        orderBy: orderBy === 'qid' ? 'id' : orderBy,
        page: currentPage,
        size: perPageData,
        search: searchText,
        fromDate: filters.dateRange?.fromData,
        toDate: filters.dateRange?.toDate,
        status: filters.status?.label || capitalizeFirstLetter(status),
        type: filters.quoteType?.label,
        property_name: filters.property?.label,
      })
    ).finally(() => {
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
  ]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllQuoteList, 500), [
    getAllQuoteList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const rows = quoteList?.map((item) => {
    const updatedItem = Object.keys(item).reduce((acc, key) => {
      acc[key] = item[key] === ('' || null) ? '-' : item[key];

      return acc;
    }, {});

    updatedItem.quote_date = formattedDate(item.quote_date);
    updatedItem.due_date = formattedDate(item.due_date);
    updatedItem.amount = `$${item.amount}`;

    return updatedItem;
  });

  const formatStatus = (status) => {
    const lowerCaseStatus = status?.toLowerCase();

    if (lowerCaseStatus === 'rejected') {
      return <StatusLabel label="Rejected" color="#C54036" />;
    }
    if (lowerCaseStatus === 'approved') {
      return <StatusLabel label="Approved" color="#95C020" />;
    }
    if (lowerCaseStatus === 'pending') {
      return <StatusLabel label="Pending" color="#FD7E14" />;
    }
    if (lowerCaseStatus === 'in progress') {
      return <StatusLabel label="In Progress" color="#007BFF" />;
    }
  };

  const formatType = (type) => {
    const lowerCaseStatus = type?.toLowerCase();

    if (lowerCaseStatus === 'defect') {
      return (
        <QuoteTypeLabel
          label="Defect"
          color="#C54036"
          backgroundColor="#c540361f"
          icon={
            <WarningAmberOutlinedIcon
              sx={{ height: '14px', width: '14px', color: '#C54036' }}
            />
          }
        />
      );
    }
    if (lowerCaseStatus === 'service') {
      return (
        <QuoteTypeLabel
          label="Service"
          color="#454545"
          backgroundColor="#4545452b"
          icon={
            <HandymanOutlinedIcon
              sx={{ height: '14px', width: '14px', color: '#454545' }}
            />
          }
        />
      );
    }
  };

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        isRefresh={true}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.property ||
          filters.quoteType ||
          filters.status ||
          filters.dateRange.fromData ||
          filters.dateRange.toDate
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder="Property Name"
            options={quotesPropertyList}
            value={filters?.property}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, property: newValue }))
            }
            width="190px"
            isLoadingData={quotePropertyListLoading}
          />
          <Autocomplete
            placeholder="Quote Type"
            options={quotesDropdownData}
            value={filters?.quoteType}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, quoteType: newValue }))
            }
            width="190px"
            isLoadingData={quotesDropdownLoading}
          />
          <Autocomplete
            placeholder="Status"
            options={quotesStatusList}
            value={filters?.status}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, status: newValue }))
            }
            width="190px"
            isLoadingData={quoteStatusListLoading}
          />
          <CustomDateRangePicker
            onOkClick={(val) => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromData: val[0], toDate: val[1] },
              }));
            }}
            onClear={() => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromData: null, toDate: null },
              }));
            }}
            fromDate={filters.dateRange?.fromData}
            toDate={filters.dateRange?.toDate}
            placement="bottomEnd"
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper
        btn={
          <>
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {}}
            />
            <CustomButton
              text="Email a Link"
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AttachEmailOutlinedIcon />}
              onClick={() => {}}
            />
          </>
        }
      >
        <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
          {renderedComponent}
        </Box>
      </StyledMainWrapper>
      {open && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={open}
          title="QUOTE"
          onClose={() => {
            setOpen(false);
          }}
          footerButton={[
            <CustomButton
              text="Differed"
              color="inherit"
              disabled={false}
              startIcon={<DifferenceOutlinedIcon />}
              onClick={() => {}}
            />,
            <CustomButton
              text="Rejected"
              color="inherit"
              disabled={false}
              startIcon={<CancelOutlinedIcon />}
              onClick={() => {
                setOpen(false);
              }}
            />,
            <CustomButton
              text="Approve"
              disabled={false}
              startIcon={<CheckCircleOutlineIcon />}
              onClick={() => {
                setOpen(false);
              }}
            />,
          ]}
          width={653}
        >
          <QuoteInvoice />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default QuotesList;
