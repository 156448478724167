import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Divider, Typography } from '@mui/material';

import { PRIMARY } from '../../constants/Colors';

const MainWrapper = ({
  children,
  title,
  btn,
  variant,
  isStep = false,
  step,
  handleDefaultStep,
  defaultPadding = '12px',
}) => (
  <Box>
    <Box
      style={{
        padding: defaultPadding,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {isStep && (
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Typography
            variant={variant ? variant : 'h2'}
            onClick={handleDefaultStep}
            sx={{ cursor: 'pointer', color: PRIMARY }}
          >
            {title}
          </Typography>
          <ArrowForwardIosIcon sx={{ height: '12px', width: '12px' }} />
          <Typography variant={variant ? variant : 'h2'}>{step}</Typography>
        </Box>
      )}

      {title && !isStep && (
        <Typography variant={variant ? variant : 'h2'}>{title}</Typography>
      )}
      <Box sx={{ display: 'flex', gap: '16px' }}>
        {Array.isArray(btn)
          ? btn.map((buttonElement, index) => (
              <Box key={index}>{buttonElement}</Box>
            ))
          : btn}
      </Box>
    </Box>
    {title && <Divider />}
    {children}
  </Box>
);

export default MainWrapper;
