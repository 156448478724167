import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { DIVIDER_COLOR } from '../../constants/Colors';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { resetDropdown } from '../../store/company/reducer';
import { getServiceAreasDropdownList } from '../../store/serviceAreas/api';
import { getSkillsDropdownList } from '../../store/skills/api';
import { getWorkOrderList } from '../../store/workOrder/api';
import { resetPagination } from '../../store/workOrder/reducer';
import { formattedDate } from '../../utils';
import ComingSoon from '../ComingSoon';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import StatusLabel from '../CommonComponents/StatusLabel';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import WorkOrderDetails from './WorkOrderDetails';

const defaultValues = {
  work_order_name: '',
  work_order_type: '',
  property: '',
  asset_count: 0,
  technician: '',
  frequency: '',
  status: '',
  appoitment: '',
  due_date: '',
};

const defaultFilters = {
  customer: null,
  property: null,
  workOrderTypes: null,
  technician: null,
  status: null,
};

const listColumnNames = {
  work_order_name: 'work_order_name',
  work_order_type: 'work_order_type',
  property: 'property',
  asset_count: 'asset_count',
  technician: 'technician',
  frequency: 'frequency',
  status: 'status',
  appoitment: 'appoitment',
  due_date: 'due_date',
  edit: 'edit',
};

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const WorkOrder = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formMode, setFormMode] = useState('create');
  const {
    handleSubmit,
    register,
    control,
    reset,
    trigger,
    setError,
    clearErrors,
    watch,
    setValue,
    getValues,
  } = useForm();

  const { workOrderList, total, totalCount } = useSelector(
    (state) => state.workOrder.get
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [serverErrors, setServerErrors] = useState(null);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [workOrderId, setWorkOrderId] = useState('');
  const [appointmentFromDate, setAppointmentFromDate] = useState(null);
  const [appointmentToDate, setAppointmentToDate] = useState(null);
  const [dueFromDate, setDueFromDate] = useState(null);
  const [dueToDate, setDueToDate] = useState(null);

  const [filters, setFilters] = useState(defaultFilters);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );
  // const { customerDropdownLoading, customerDropdownData } = getDropdownListHook(
  //   {
  //     reducerName: 'customer',
  //     dropdownListName: 'customerDropdownList',
  //     labelName: 'display_name',
  //     valueName: 'name',
  //   }
  // );

  // const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
  //   {
  //     reducerName: 'property',
  //     dropdownListName: 'propertyDropdownList',
  //     labelName: 'display_name',
  //     valueName: 'name',
  //   }
  // );

  // const { workOrderTypesDropdownLoading, workOrderTypesDropdownData } =
  //   getDropdownListHook({
  //     reducerName: 'workOrderTypes',
  //     dropdownListName: 'workOrderTypesDropdownList',
  //     labelName: 'display_name',
  //     valueName: 'name',
  //   });

  // const { technicianDropdownLoading, technicianDropdownData } =
  //   getDropdownListHook({
  //     reducerName: 'technician',
  //     dropdownListName: 'technicianDropdownList',
  //     labelName: 'display_name',
  //     valueName: 'name',
  //   });

  // const { statusDropdownLoading, statusDropdownData } = getDropdownListHook({
  //   reducerName: 'status',
  //   dropdownListName: 'statusDropdownList',
  //   labelName: 'display_name',
  //   valueName: 'name',
  // });

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'wid',
        headerName: t('attributes.work_order.id'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'work_order_name',
        headerName: t('attributes.work_order.work_order_name'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'work_order_type',
        headerName: t('attributes.work_order.work_order_type'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'property',
        headerName: t('attributes.property.property'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box>
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {row.property_name}
            </Typography>
            <Box>{`${row.address}`}</Box>
          </Box>
        ),
      },
      {
        field: 'asset_count',
        headerName: t('attributes.work_order.asset_count'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box
            sx={{
              p: '10px',
              height: 20,
              width: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid black',
              borderRadius: '50%',
            }}
          >
            {row.asset_count}
          </Box>
        ),
      },
      {
        field: 'technician',
        headerName: t('attributes.technician.technician'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box>{row.technician ? row.technician.technician_name : '-'}</Box>
        ),
      },
      {
        field: 'frequency',
        headerName: t('attributes.work_order.frequency'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '4px',
            }}
          >
            {row.frequency && (
              <Box
                sx={{
                  px: '16px',
                  py: '4px',
                  border: '1px solid black',
                  borderRadius: '24px',
                  bgcolor: DIVIDER_COLOR,
                }}
              >
                {row.frequency}
              </Box>
            )}
          </Box>
        ),
      },
      {
        field: 'status',
        headerName: t('attributes.work_order.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <Box>{formatStatus(row.status)}</Box>,
      },
      {
        field: 'appointment_date',
        headerName: t('attributes.work_order.appointment'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'due_date',
        headerName: t('attributes.work_order.due_date'),
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'view',
        headerName: t('attributes.work_order.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              setWorkOrderId(row?.uuid);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllWorkOrder = useCallback(() => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getWorkOrderList({
        limit: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        customer: filters.customer?.value,
        property: filters.property?.value,
        workOrderTypes: filters.workOrderTypes?.value,
        technician: filters.technician?.value,
        status: filters.status?.value,
        appointmentFromDate: appointmentFromDate,
        appointmentToDate: appointmentToDate,
        dueFromDate: dueFromDate,
        dueToDate: dueToDate,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsInitialLoading(false);
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
  ]);

  useEffect(
    () => () => {
      dispatch(resetPagination());
      dispatch(resetDropdown());
    },
    []
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllWorkOrder, 500), [
    getAllWorkOrder,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  // Replace list with related list dropdown once API is ready
  useEffect(() => {
    dispatch(getSkillsDropdownList());
    dispatch(getServiceAreasDropdownList());
  }, []);

  const rows = workOrderList?.map((item) => ({
    ...item,
    wid: `W-${item.id}`,
    work_order_type: item.work_order_type.toUpperCase(),
    work_order_name:
      `${
        item.property_equipment_routine_service_schedule?.equipment_type
          ?.display_name
      } - ${item?.work_order_type?.toUpperCase()}` || '',
    asset_count: item.assets_count || 0,
    technician: item.technician || '-',
    status: item.latest_status,
    property_name:
      item?.property_equipment_routine_service_schedule?.property
        ?.property_name || '-',
    address:
      item?.property_equipment_routine_service_schedule?.property?.address ||
      '-',

    frequency:
      item?.property_equipment_routine_service_schedule?.frequencies?.code,
    appointment_date: formattedDate(item.scheduled_date),
    due_date: formattedDate(item.due_date),
    created_at: item?.created_at,
  }));

  console.log(rows);

  useEffect(() => {
    if (serverErrors) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const formatStatus = (status, height = '12px', width = '12px') => {
    const lowerCaseStatus =
      typeof status === 'string' ? status?.toLowerCase() : status;

    if (lowerCaseStatus === 'created') {
      return (
        <StatusLabel
          label="Created"
          color="#454545"
          height={height}
          width={width}
        />
      );
    }
    if (lowerCaseStatus === 'scheduled') {
      return (
        <StatusLabel
          label="Scheduled"
          color="#59C3C3"
          height={height}
          width={width}
        />
      );
    }
    if (lowerCaseStatus === 'in_progress') {
      return (
        <StatusLabel
          label="In Progress"
          color="#F7A142"
          height={height}
          width={width}
        />
      );
    }
    if (lowerCaseStatus === 'completed') {
      return (
        <StatusLabel
          label="Completed"
          color="#95C020"
          height={height}
          width={width}
        />
      );
    }
    if (lowerCaseStatus === 'pending') {
      return (
        <StatusLabel
          label="Pending"
          color="#FD7E14"
          height={height}
          width={width}
        />
      );
    }
  };

  const resetFilter = () => {
    setFilters({
      customer: null,
      property: null,
      workOrderTypes: null,
      status: null,
      technician: null,
      appoitment_date: null,
      due_date: null,
    });
    setSearchText('');
  };

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        searchText={searchText}
        onResetFilter={resetFilter}
        isRefresh={true}
        // After API impl need to include debounce search
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.customer ||
          filters.property ||
          filters.tags ||
          filters.workOrderTypes ||
          filters.status ||
          filters.technician
        }
        isActive={isFilterComponentVisible}
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder="Customers"
            options={[]}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, customer: newVal }))
            }
            value={filters?.customer}
            isLoadingData={false}
            width="190px"
          />
          <Autocomplete
            placeholder="Properties"
            options={[]}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, property: newVal }))
            }
            isLoadingData={false}
            value={filters?.property}
            width="190px"
          />
          <Autocomplete
            placeholder="Work Order Types"
            options={[]}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, workOrderTypes: newVal }))
            }
            value={filters?.workOrderTypes}
            isLoadingData={false}
            width="190px"
          />
          <Autocomplete
            placeholder="Technicians"
            options={[]}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, technician: newVal }))
            }
            value={filters?.technician}
            isLoadingData={false}
            width="190px"
          />
          <Autocomplete
            placeholder="Status"
            options={[]}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, status: newVal }))
            }
            value={filters?.status}
            isLoadingData={false}
            width="190px"
          />

          {/*     Need to see date range picker or normal date picker       */}
          <CustomDateRangePicker
            placeholder="Appointment Date"
            onOkClick={(val) => {
              setAppointmentFromDate(val[0]);
              setAppointmentToDate(val[1]);
            }}
            onClear={() => {
              setAppointmentFromDate(null);
              setAppointmentToDate(null);
            }}
            fromDate={appointmentFromDate}
            toDate={appointmentToDate}
          />
          <CustomDateRangePicker
            placeholder="Due Date"
            onOkClick={(val) => {
              setDueFromDate(val[0]);
              setDueToDate(val[1]);
            }}
            onClear={() => {
              setDueFromDate(null);
              setDueToDate(null);
            }}
            fromDate={dueFromDate}
            toDate={dueToDate}
          />
        </FilterComponent>
      )}

      <CustomGridTable
        columns={columns}
        rows={rows}
        total={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
        // Later just do what has been done in work_order for the same
      />
    </>
  );

  const WorkOrderWrapper = (
    <StyledMainWrapper
      title={t('attributes.work_order.work_orders')}
      btn={
        <>
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {}}
          />
        </>
      }
    >
      {/* {!isDataLoading && totalCount === 0 && noData} */}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading && totalCount > 0 && renderedComponent
      )}
    </StyledMainWrapper>
  );

  return (
    <>
      {!workOrderId ? (
        WorkOrderWrapper
      ) : (
        <WorkOrderDetails
          id={workOrderId}
          setId={setWorkOrderId}
          formatStatus={formatStatus}
        />
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={!workOrderId ? 'Add WorkOrder' : 'Edit WorkOrder'}
          onClose={() => {
            setOpen(false) && setWorkOrderId('');
          }}
          footerButton={
            <CustomButton
              text={'Loading...'}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit()}
            />
          }
        >
          <ComingSoon />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default WorkOrder;
