import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../../constants/APIConstants';
import { CUSTOMER } from '../../actionPrefix';
import { axiosInstanceCompany } from '../../axios';

export const getCustomerList = createAsyncThunk(
  CUSTOMER.GET_CUSTOMERS,
  async (
    {
      page,
      search,
      page_size,
      active,
      state,
      city,
      order,
      orderBy,
      category,
      account_manager,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (search) params.append('search', search);
    if (page) params.append('page', page);
    if (page_size) params.append('items_per_page', page_size);
    if (active) params.append('active', active);
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('sort_order', order);
    if (orderBy) params.append('sort_by', orderBy);
    if (category) params.append('categories', category);
    if (account_manager)
      params.append('account_manager_uuids', account_manager);
    if (list_column_names)
      params.append('list_column_names', list_column_names);
    try {
      const response = await axiosInstanceCompany.get(
        `${APIs.CUSTOMER.GET_CUSTOMERS}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCustomerById = createAsyncThunk(
  CUSTOMER.GET_CUSTOMER_BY_ID,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.get(
        APIs.CUSTOMER.GET_UPDATE_CUSTOMER.replace('{uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  CUSTOMER.UPDATE_CUSTOMER,
  async ({ uuid, req }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.put(
        APIs.CUSTOMER.GET_UPDATE_CUSTOMER.replace('{uuid}', uuid),
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCustomer = createAsyncThunk(
  CUSTOMER.CREATE_CUSTOMER,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.post(
        APIs.CUSTOMER.CREATE_CUSTOMER,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Category List API
export const getCustomerCategoryDropdownList = createAsyncThunk(
  CUSTOMER.GET_CUSTOMER_CATEGORY_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.get(
        APIs.CUSTOMER.GET_CUSTOMER_CATEGORY_DROPDOWN_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
