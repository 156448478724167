import {
  ADDRESS_COUNTRY,
  ADDRESS_REGION_CODE,
  GOOGLE_API_KEY,
} from '../constants/Constants';
import { axiosInstanceGoogleMapAPI } from '../store/axios';

export const PER_PAGE_DATA = 10;
export const FIRST_PAGE = 1;

export const SQFT_TO_SQM = (sqft) => {
  // Check if sqft is a number or a numeric string
  if (!isNaN(sqft) && sqft !== null && sqft !== '') {
    const sqm = parseFloat(sqft) * 0.092903;

    return sqm.toFixed(2);
  }
  // Return 0 if the input is not a valid number

  return 0;
};

// Common function to validate the address using Google API
export const validateAddress = async ({
  address,
  city,
  state,
  postcode,
  fieldName,
  setServerErrors = () => {},
  setCoords = () => {},
  clearErrors = () => {},
}) => {
  if (!address || !city?.value || !state?.value || !(postcode?.length > 3)) {
    clearErrors(fieldName.addressKey);
    clearErrors(fieldName.stateKey);
    clearErrors(fieldName.cityKey);
    clearErrors(fieldName.postCodeKey);
    return false;
  }

  // Check the address against Google API
  const checkAddress = async () => {
    const params = new URLSearchParams();

    params.append('key', GOOGLE_API_KEY);

    try {
      const response = await axiosInstanceGoogleMapAPI.post(
        `?${params.toString()}`,
        {
          address: {
            regionCode: ADDRESS_REGION_CODE,
            addressLines: [
              `${address}, ${city.value} ${state.value} ${postcode}, ${ADDRESS_COUNTRY}`,
            ],
          },
        }
      );

      return response.data;
    } catch (error) {
      return null; // Return null on error
    }
  };

  // Perform the address validation
  const resp = await checkAddress();

  if (!resp) return;

  let hasError = false;
  const { unconfirmedComponentTypes, addressComponents, geocode } =
    resp.result.address;
  clearErrors(fieldName.addressKey);
  clearErrors(fieldName.stateKey);
  clearErrors(fieldName.cityKey);
  clearErrors(fieldName.postCodeKey);
  // Check for specific address errors
  if (unconfirmedComponentTypes) {
    if (unconfirmedComponentTypes.includes('postal_code')) {
      hasError = true;
      setServerErrors((prev) => [
        ...prev,
        {
          field: fieldName.postCodeKey,
          message: `${fieldName.postCodeValue} is not correct.`,
        },
      ]);
    }

    if (unconfirmedComponentTypes.includes('locality')) {
      hasError = true;
      setServerErrors((prev) => [
        ...prev,
        {
          field: fieldName.cityKey,
          message: `Combination of ${fieldName.cityValue} and ${fieldName.postCodeValue} is not valid`,
        },
      ]);
    }

    if (unconfirmedComponentTypes.includes('administrative_area_level_1')) {
      hasError = true;
      setServerErrors((prev) => [
        ...prev,
        {
          field: fieldName.stateKey,
          message: `Combination of ${fieldName.stateValue} and ${fieldName.postCodeValue} is not valid`,
        },
      ]);
    }

    const filteredUnconfirmedComponentTypes = unconfirmedComponentTypes.filter(
      (val) =>
        val !== 'postal_code' ||
        val !== 'locality' ||
        val !== 'administrative_area_level_1'
    );

    const suspiciousComponents = addressComponents
      .filter(
        (component) =>
          filteredUnconfirmedComponentTypes.includes(component.componentType) &&
          component.confirmationLevel === 'UNCONFIRMED_AND_SUSPICIOUS'
      )
      .map((component) => component.componentName.text);

    if (suspiciousComponents.length > 0) {
      hasError = true;
      setServerErrors((prev) => [
        ...prev,
        {
          field: fieldName.addressKey,
          message: `${suspiciousComponents.join(' ')} address not found.`,
        },
      ]);
    }
  }

  // Set the result or clear errors if no issues are found
  if (hasError) {
    setCoords(null);
  } else {
    clearErrors(fieldName.addressKey);
    clearErrors(fieldName.stateKey);
    clearErrors(fieldName.cityKey);
    clearErrors(fieldName.postCodeKey);
    // Check for specific address errors
    setCoords({
      latitude: geocode?.location?.latitude,
      longitude: geocode?.location?.longitude,
    });
  }

  return hasError;
};
