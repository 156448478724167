import { Box, Chip, Divider, Typography, styled } from '@mui/material';

import CustomGoogleMap from '../CommonComponents/GoogleMap';

const StyledHeader = styled(Box)({
  padding: '16px',
  backgroundColor: 'rgba(235, 235, 235, 0.2)',
});

const SideWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  padding: '16px',
  width: '100%',
});

const KeyValueComponent = ({ name, value, minWidth = '180px' }) => (
  <Box sx={{ display: 'flex' }}>
    <Typography variant="body1" minWidth={minWidth}>
      {`${name}:`}
    </Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

const WorkOrderData = ({ data }) => {
  const markers = [
    {
      id: 1,
      lat: '',
      lng: '',
      tooltipText: '',
      color: '#395B50',
    },
  ];

  const center = {
    lat: '',
    lng: '',
  };

  const workOrderDetailKey =
    data[
      data?.type_name
        .split(' ')
        .map((d) => d.toLowerCase())
        .join('_')
    ];
  return (
    <Box sx={{ overflow: 'auto', height: 'calc(100vh - 310px)' }}>
      <Box>
        <StyledHeader>
          <Typography variant="body1">Work Order Details</Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name="Work Order ID"
              value={data?.work_order_status[0]?.work_order_id}
            />
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body1" minWidth="150px">
                Address:
              </Typography>
              <Box>
                <Typography
                  variant="body2"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {workOrderDetailKey?.property?.address}
                </Typography>
                <Box sx={{ height: '100px', width: '200px' }}>
                  <CustomGoogleMap
                    isDashboard={false}
                    markers={markers}
                    center={center}
                  />
                </Box>
              </Box>
            </Box>
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent
              name="Work Order Name"
              value="Portable and wheeled fire extinguishers Maintenance "
            />
            <KeyValueComponent
              name="Tolerance Date Duration"
              value="19/07/24 -19/11/24"
            />
            <KeyValueComponent
              name="Estimated Duration"
              value="No estimated duration set"
            />
            <KeyValueComponent name="Assigned To" value="Fire Tech" />
            <KeyValueComponent name="Type" value={data?.work_order_type} />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">Contract Details</Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name="Appointments Date & Time"
              value={data?.scheduled_start_date_time}
            />
            <KeyValueComponent name="Customer" value="29/08/2024" />
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent
              name="Property Contact"
              value={
                workOrderDetailKey?.property?.property_account_manager
                  ?.display_name
              }
            />
            <KeyValueComponent name="Cell Phone" value="+61 412 345 678" />
            <KeyValueComponent name="Email" value="" />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">Access Details</Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name="Access Schedule"
              value={<Chip label="Appointment" />}
            />
            <KeyValueComponent
              name="Access Procedure"
              value={<Chip label="Report to main reception" />}
            />
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent name="Access Code" value="1234A" />
            <KeyValueComponent name="Access Note" value="John Doe" />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">Site Requirements</Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name="Requirements"
              value="Induction Required, Log Book Entry Required, Police Check Required, Working with Children Check Required "
            />
            <KeyValueComponent
              name="Service Requirements"
              value="Regular maintenance checks for electrical and plumbing systems"
            />
            <KeyValueComponent
              name="Safety Requirements"
              value="High-visibility vests and helmets required on-site at all times."
            />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
      <Box>
        <StyledHeader>
          <Typography variant="body1">Building Attributes</Typography>
        </StyledHeader>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <SideWrapper>
            <KeyValueComponent
              name="Annual Due Certification Date"
              value="30/06/2024"
            />
            <KeyValueComponent name="Building Era" value="Heritage building" />
            <KeyValueComponent
              name="Building Class"
              value="9b (Public Building)"
            />
            <KeyValueComponent
              name="Building Type"
              value="Cultural/Arts Centre"
            />
            <KeyValueComponent name="Building Size" value="7500" />
            <KeyValueComponent name="Building Size (sqm)" value="7500" />
            <KeyValueComponent name="Internal Note" value="No internal notes" />
          </SideWrapper>
          <Divider orientation="vertical" flexItem />
          <SideWrapper>
            <KeyValueComponent name="Stories Above Ground" value="2" />
            <KeyValueComponent name="Stories Below Ground" value="2" />
            <KeyValueComponent name="Construction Class" value="Class A" />
            <KeyValueComponent name="Building Part" value="Building Part" />
            <KeyValueComponent name="Building Tenancies" value="5" />
            <KeyValueComponent name="Building Standards" value="AS1851-2012" />
          </SideWrapper>
        </Box>
        <Divider />
      </Box>
    </Box>
  );
};
export default WorkOrderData;
