import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { getWorkOrder } from '../../store/workOrder/api';
import { resetWorkOrderData } from '../../store/workOrder/reducer';
import { formattedDate } from '../../utils';
import ComingSoon from '../ComingSoon';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import MainWrapper from '../CommonComponents/MainWrapper';
import TabView from '../CommonComponents/TabView';
import WorkOrderData from './WorkOrderData';

const WorkOrderDetails = ({ id, setId, formatStatus }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const { isLoading, workOrder } = useSelector(
    (state) => state.workOrder?.details
  );

  useEffect(() => {
    if (id) {
      dispatch(getWorkOrder({ id: '4d588470-2b99-46b1-963c-cd82fa4517c3' }));
    }
    return () => {
      resetWorkOrderData();
    };
  }, []);

  const workOrderDetailKey =
    workOrder[
      workOrder?.type_name
        ?.split(' ')
        .map((d) => d.toLowerCase())
        .join('_')
    ];

  return isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CustomCircularLoader />
    </Box>
  ) : (
    <>
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title="Work Order"
          variant="body1"
          isStep={true}
          step={'W-01'}
          handleDefaultStep={() => setId('')}
        ></MainWrapper>
      </Box>

      <Box
        sx={{
          width: '100%',
          backgroundColor: DIVIDER_COLOR,
        }}
      >
        <Box
          sx={{
            bgcolor: 'white',
            marginBottom: '16px',
            width: '100%',
            borderRadius: '4px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ padding: '16px' }}>
                <Typography variant="body1">{workOrder?.type_name}</Typography>
              </Box>
              <Box
                sx={{
                  padding: '16px',
                  borderLeft: `1px solid ${DIVIDER_COLOR}`,
                  borderRight: `1px solid ${DIVIDER_COLOR}`,
                }}
              >
                <Typography variant="body1">
                  {formatStatus(workOrder?.latest_status, '10px', '10px')}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', columnGap: '8px', padding: '16px' }}>
              <Typography variant="body1">Frequency</Typography>
              <Box
                sx={{
                  width: '30px',
                  height: '20px',
                  border: '1px solid black',
                  borderRadius: '24px',
                  bgcolor: DIVIDER_COLOR,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {workOrderDetailKey?.frequencies?.code}
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px',
            }}
          >
            <Box>
              <Typography variant="body1">Work Order Type</Typography>
              <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                {workOrder?.work_order_type}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', columnGap: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Typography variant="body1">Appointment Date & Time</Typography>
                <Typography variant="body2">
                  {workOrder?.scheduled_start_date_time}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Typography variant="body1">Created On</Typography>
                <Typography variant="body2">
                  {formattedDate(workOrder?.work_order_status[0]?.created_at)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Typography variant="body1">Due Date</Typography>
                <Typography variant="body2">
                  {formattedDate(workOrder?.due_date)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <TabView
            tabs={[
              {
                label: 'Details',
                component: <WorkOrderData data={workOrder} />,
              },
              {
                label: 'Assets',
                component: <ComingSoon />,
              },
              {
                label: 'Defects Quote',
                component: <ComingSoon />,
              },
              {
                label: 'Purchase Order',
                component: <ComingSoon />,
              },
              {
                label: 'Documents',
                component: <ComingSoon />,
              },
              {
                label: 'Comming Soon...',
                component: <ComingSoon />,
              },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onTabChange={() => {}}
            isSubDetails={true}
          />
        </Box>
      </Box>
    </>
  );
};
export default WorkOrderDetails;
