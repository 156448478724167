import { Navigate, useLocation } from 'react-router-dom';

import { ROUTENAME } from '../constants/RoutesConstants';
import { permissionRoute } from '../constants/SidebarMenu';
import { getUserRole } from './Permission';

const AuthRoute = ({ children }) => {
  const location = useLocation();
  let hasPermission = false;

  const loggedInUserRole = getUserRole();

  if (loggedInUserRole) {
    hasPermission = permissionRoute[loggedInUserRole]?.routes?.includes(
      location?.pathname
    );
  }

  return hasPermission ? children : <Navigate to={ROUTENAME.DEFAULT_ROUTE} />;
};

export default AuthRoute;
