import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';

import { theme } from '../../../assets/theme';
import { SECONDARY } from '../../../constants/Colors';
import { validateAddress } from '../../../constants/common';
import { TAB_STATUS } from '../../../constants/Constants';
import getDropdownListHook from '../../../hooks/getDropdownListHook';
import getStatesHook from '../../../hooks/getStatesHook';
import { getAccountManagerDropdownList } from '../../../store/accountManager/api';
import {
  resetDocumentData,
  snackbarToggle,
} from '../../../store/CommonReducer';
import { resetCreate } from '../../../store/company/reducer';
import {
  createCustomer,
  getCustomerById,
  getCustomerCategoryDropdownList,
  updateCustomer,
} from '../../../store/users/customer/api';
import { resetEdit, resetUpdate } from '../../../store/users/customer/reducer';
import { datePickerFormat, formatDateAPI } from '../../../utils';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import AccountManager from './FormSteps/AccountManager';
import Billing from './FormSteps/Billing';
import CustomerDetails from './FormSteps/CustomerDetails';
import InviteTheCustomer from './FormSteps/InviteTheCustomer';
import PrimaryContacts from './FormSteps/PrimaryContacts';
import Quoting from './FormSteps/Quoting';

const LayoutContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  height: '100%',
}));

const Section = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.secondary.main,
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '4px',
  gap: '16px',
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.grey,
    borderWidth: 2,
    borderLeftStyle: 'dotted',
  },
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? theme.palette.primary.main : 'white',
  color: ownerState.active ? 'white' : 'black',
  zIndex: 1,
  width: 26,
  height: 26,
  border: ownerState.active ? '' : '1px solid black',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundColor: 'black',
    color: 'white',
  }),
}));

const FormWrapper = styled(Box)(() => ({
  maxHeight: '84vh',
  overflowY: 'auto',
  width: '100%',
  scrollbarWidth: 'none',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomerForm = ({
  customerId,
  formMode,
  sethasFormValues,
  setServerErrors,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const watchedFields = watch();

  const [activeStep, setActiveStep] = useState(0);

  const { isLoading: createdCustomerLoading } = useSelector(
    (state) => state.customer.create
  );
  const { isLoading, editCustomer } = useSelector(
    (state) => state.customer.edit
  );
  const { isLoading: updateCustomerLoading } = useSelector(
    (state) => state.customer.update
  );
  const { accountManagerDropdownData } = getDropdownListHook({
    reducerName: 'accountManager',
    dropdownListName: 'accountManagerDropdownList',
    labelName: 'name',
    valueName: 'uuid',
  });

  const steps = [
    'Customer Details',
    'Primary Contact',
    'Billing',
    'Quoting',
    t('attributes.account_manager.account_manager'),
    ...(editCustomer?.invitation_status === 'invited'
      ? []
      : ['Invite The Customer']),
  ];

  // Function to check if any field has a value
  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const stateList = getStatesHook();

  useEffect(() => {
    dispatch(getCustomerCategoryDropdownList());
    dispatch(getAccountManagerDropdownList());
    if (customerId) {
      dispatch(getCustomerById(customerId));
    }

    return () => {
      dispatch(resetCreate());
      dispatch(resetUpdate());
      dispatch(resetEdit());
      dispatch(resetDocumentData());
    };
  }, []);

  const accountManagerById = (id) => {
    const foundItem = accountManagerDropdownData.find(
      (item) => item.value === id
    );

    return foundItem
      ? { label: foundItem.label, value: foundItem.value }
      : { label: '', value: '' };
  };

  useEffect(() => {
    if (!isLoading && editCustomer && customerId) {
      reset({
        customer_name: editCustomer?.customer_name,
        abnNumber: editCustomer?.customer_abn_number,
        category: {
          label: editCustomer?.categories[0],
          value: editCustomer?.categories[0],
        },
        agreementSignedDate: editCustomer?.agreement_signed_date
          ? datePickerFormat(editCustomer?.agreement_signed_date)
          : null,
        registeredAddress: editCustomer?.address,
        state: {
          label: editCustomer?.state || '',
          value: editCustomer?.state || '',
        },
        city: {
          label: editCustomer?.city || '',
          value: editCustomer?.city || '',
        },
        customerPostcode: editCustomer?.postcode,
        notes: editCustomer?.notes || '',
        active: editCustomer.status === 'active' ? true : false,

        contact_name: editCustomer?.primary_contact?.contact_name,
        contact_email: editCustomer?.primary_contact?.contact_email,
        contact_phone: editCustomer?.primary_contact?.contact_phone,
        address: editCustomer?.primary_contact?.address || '',
        contactCity: {
          label: editCustomer?.primary_contact?.city || '',
          value: editCustomer?.primary_contact?.city || '',
        },
        contactState: {
          label: editCustomer?.primary_contact?.state || '',
          value: editCustomer?.primary_contact?.state || '',
        },
        contactPostCode: editCustomer?.primary_contact?.postcode || '',
        agreementDocument:
          editCustomer?.documents.find(
            (d) => d.document_type === 'agreement'
          ) || {},
        otherDocument:
          editCustomer?.documents.find((d) => d.document_type === 'other') ||
          {},

        organization: editCustomer?.billing?.company_name || '',
        billing_contact_name: editCustomer?.billing?.billing_contact_name || '',
        billingEmailTo: editCustomer?.billing?.email_to || '',
        fixedPriceContract:
          editCustomer?.billing?.fixed_price_contract || false,
        manualBillingRequired:
          editCustomer?.billing?.manual_billing_required || false,

        quoting_contact_name: editCustomer?.quoting?.quoting_contact_name || '',
        quotingEmailTo: editCustomer?.quoting?.email_to || '',

        accountManager: accountManagerById(
          editCustomer.account_manager_uuid[0]
        ),
      });
    }
  }, [isLoading, editCustomer]);

  const stepIcons = [
    <AccountBoxOutlinedIcon
      key="icon-01"
      sx={{ height: '15px', width: '15px' }}
    />,
    <ContactPageOutlinedIcon
      key="icon-02"
      sx={{ height: '15px', width: '15px' }}
    />,
    <ReceiptLongOutlinedIcon
      key="icon-03"
      sx={{ height: '15px', width: '15px' }}
    />,
    <MarkChatReadOutlinedIcon
      key="icon-04"
      sx={{ height: '15px', width: '15px' }}
    />,
    <BadgeOutlinedIcon key="icon-05" sx={{ height: '15px', width: '15px' }} />,
    <MarkEmailReadOutlinedIcon
      key="icon-05"
      sx={{ height: '15px', width: '15px' }}
    />,
  ];

  const CustomStepIcon = ({ icon, active, completed, className }) => (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {stepIcons[icon - 1]}
    </CustomStepIconRoot>
  );

  const handleNext = async () => {
    const isValid = await trigger(); // Trigger validation for all fields in the current step

    let hasError = false;

    if (activeStep === 0) {
      hasError = await validateAddress({
        address: watchedFields.registeredAddress,
        city: watchedFields.city,
        state: watchedFields.state,
        postcode: watchedFields.customerPostcode,
        fieldName: {
          addressKey: 'registeredAddress',
          addressValue: t(
            'attributes.customer.customerDetails.registeredAddress'
          ),
          stateKey: 'state',
          stateValue: t('common.state'),
          cityKey: 'city',
          cityValue: t('common.city'),
          postCodeKey: 'customerPostcode',
          postCodeValue: t('attributes.postCode'),
        },
        setServerErrors,
      });
    }

    if (hasError) return;

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = (submitType) => async (data) => {
    const hasError = await validateAddress({
      address: data.registeredAddress,
      city: data.city,
      state: data.state,
      postcode: data.customerPostcode,
      fieldName: {
        addressKey: 'registeredAddress',
        addressValue: t(
          'attributes.customer.customerDetails.registeredAddress'
        ),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'customerPostcode',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors,
    });

    if (hasError) return;
    let documentsCollection = [data?.agreementDocument];

    if (!isEmpty(data?.otherDocument)) {
      documentsCollection = [...documentsCollection, data?.otherDocument];
    }

    const oldDocuments = editCustomer?.documents;
    const newDocumentId = documentsCollection?.map((d) => d?.document_uuid);

    const deletedDocuments = oldDocuments
      ?.filter((dc) => !newDocumentId.includes(dc?.document_uuid))
      ?.map((dd) => ({ ...dd, is_deleted: true }));

    const customerDetailsData = {
      customer_name: data?.customer_name,
      customer_abn_number: data?.abnNumber,
      categories: [data?.category?.label],
      agreement_signed_date: data?.agreementSignedDate
        ? formatDateAPI(data.agreementSignedDate)
        : null,
      address: data?.registeredAddress,
      state: data?.state?.value,
      city: data?.city?.value,
      postcode: data?.customerPostcode,
      notes: data?.notes || '',
      ...(data.active !== undefined && {
        status: data.active ? TAB_STATUS.active : TAB_STATUS.inactive,
      }),
      documents:
        deletedDocuments?.length > 0
          ? [...documentsCollection, ...deletedDocuments]
          : documentsCollection,
    };

    const primaryContactData = {
      contact_name: data?.contact_name,
      contact_email: data?.contact_email,
      contact_phone: data?.contact_phone,
      address: data?.address || '',
      city: data?.contactCity?.value || null,
      state: data?.contactState?.value || null,
      postcode: data?.contactPostCode || null,
    };

    const billingData = {
      company_name: data?.organization || null,
      billing_contact_name: data?.billing_contact_name || null,
      email_to: data?.billingEmailTo || null,
      fixed_price_contract: data?.fixedPriceContract || false,
      manual_billing_required: data?.manualBillingRequired || false,
    };

    const quotingData = {
      quoting_contact_name: data?.quoting_contact_name || null,
      email_to: data?.quotingEmailTo || null,
    };

    const accountManagerData = data?.accountManager?.value
      ? [data.accountManager.value]
      : [];

    const pageDataMapping = {
      0: customerDetailsData,
      1: {
        ...customerDetailsData,
        primary_contact: primaryContactData,
      },
      2: {
        ...customerDetailsData,
        primary_contact: primaryContactData,
        billing: billingData,
      },
      3: {
        ...customerDetailsData,
        primary_contact: primaryContactData,
        billing: billingData,
        quoting: quotingData,
      },
      4: {
        ...customerDetailsData,
        primary_contact: primaryContactData,
        billing: billingData,
        quoting: quotingData,
        account_manager_uuid: accountManagerData,
      },
      5: {
        ...customerDetailsData,
        primary_contact: primaryContactData,
        billing: billingData,
        quoting: quotingData,
        account_manager_uuid: accountManagerData,
      },
    };

    const req = {
      ...pageDataMapping[activeStep],
      invitation_status: submitType,
    };

    dispatch(
      customerId
        ? updateCustomer({ uuid: customerId, req: req })
        : createCustomer(req)
    ).then((res) => {
      if (res.payload?.errorDetails && res.payload.errorDetails?.length > 0) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: 'Something went wrong! Please check the data you entered or try again later.',
          })
        );
      }
    });
  };

  const handleStepClick = async (index) => {
    const isValid = await trigger(); // Optional: Validate the current step

    if (isValid) {
      setActiveStep(index); // Set the step index directly
    }
  };

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <LayoutContainer>
      {/* Steps UI */}
      <Box
        sx={{
          position: 'block',
          width: '259px',
          padding: '16px',
          background: SECONDARY,
          marginRight: '16px',
          borderRadius: '4px',
          height: '84vh',
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<CustomConnector />}
        >
          {steps.map((label, idx) => (
            <Step
              key={label}
              onClick={
                formMode === 'edit' ? () => handleStepClick(idx) : undefined
              }
              sx={{ cursor: formMode === 'edit' ? 'pointer' : 'default' }}
            >
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Typography
                  variant={idx <= activeStep ? 'body2' : 'body2'}
                  sx={{
                    color:
                      idx === activeStep ? theme.palette.primary.main : 'black',
                    cursor: formMode === 'edit' ? 'pointer' : 'default',
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <FormWrapper component="form" onSubmit={handleSubmit(onSubmit)}>
        {activeStep === 0 && (
          <CustomerDetails
            title={steps[0]}
            control={control}
            setValue={setValue}
            setError={setError}
            trigger={trigger}
            stateList={stateList}
            Section={Section}
            watch={watch}
            customerId={customerId}
            formMode={formMode}
            setServerErrors={setServerErrors}
            clearErrors={clearErrors}
          />
        )}
        {activeStep === 1 && (
          <PrimaryContacts
            title={steps[1]}
            control={control}
            trigger={trigger}
            setValue={setValue}
            stateList={stateList}
            Section={Section}
            watch={watch}
            customerStatus={editCustomer?.invitation_status}
          />
        )}
        {activeStep === 2 && (
          <Billing
            title={steps[2]}
            control={control}
            trigger={trigger}
            setValue={setValue}
            Section={Section}
          />
        )}
        {activeStep === 3 && (
          <Quoting
            title={steps[3]}
            control={control}
            trigger={trigger}
            setValue={setValue}
            Section={Section}
          />
        )}
        {activeStep === 4 && (
          <AccountManager
            title={steps[4]}
            control={control}
            trigger={trigger}
            Section={Section}
          />
        )}
        {activeStep === 5 && (
          <InviteTheCustomer
            title={steps[5]}
            control={control}
            trigger={trigger}
            setValue={setValue}
            Section={Section}
            watch={watch}
          />
        )}
      </FormWrapper>

      {/* Form footer */}
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: '54px',
          width: '100%',
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          backgroundColor: SECONDARY,
          padding: '16px',
          gap: 2,
        }}
      >
        {activeStep !== 0 && (
          <CustomButton
            startIcon={<KeyboardArrowLeftIcon />}
            text="Previous"
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          />
        )}
        {formMode === 'create' && (
          <CustomButton
            startIcon={<SaveOutlinedIcon />}
            text="Save as Draft"
            color="inherit"
            onClick={handleSubmit(
              onSubmit(
                activeStep === steps.length - 1 ? 'invitation_pending' : 'draft'
              )
            )}
            disabled={createdCustomerLoading || updateCustomerLoading}
          />
        )}

        {activeStep === steps.length - 1 ? (
          <CustomButton
            startIcon={
              editCustomer?.invitation_status !== 'invited' ? (
                <MarkEmailReadOutlinedIcon />
              ) : (
                <SaveOutlinedIcon />
              )
            }
            text={
              editCustomer?.invitation_status !== 'invited'
                ? 'Send The Invite'
                : 'Save'
            }
            onClick={() => handleSubmit(onSubmit('invited'))()}
            type="submit"
            disabled={createdCustomerLoading || updateCustomerLoading}
          />
        ) : (
          <CustomButton
            endIcon={<ChevronRightIcon />}
            text="Next"
            onClick={handleNext}
          />
        )}
      </Box>
    </LayoutContainer>
  );
};

export default CustomerForm;
