/**
 * NOTE: Please maintain alphabetical order to facilitate easier searching and navigation within the file.
 * Add the values according to the following labels:
 *
 * 1. attributes - This section includes site-wide attribute values, e.g., Email, Name, Password, etc.
 * 2. auth - This section includes all labels related to authentication, with nested objects for each type of authentication (e.g., login, signUp, passwordReset, etc.).
 * 3. common - This section includes common translations used throughout the app.
 * 4. label - This section includes labels for buttons, e.g., Login, Sign Up, etc.
 * 5. message - This section includes common messages to be displayed in the app, e.g., Email verified, etc.
 * 6. modal - This section includes all labels, descriptions, and subheadings for modals, e.g., SignUp modal, Launch setup modal, etc.
 **/

const translation = {
  attributes: {
    email: 'Email',
    name: 'Name',
    address: 'Address',
    description: 'Description',
    create: 'Create',
    new: 'New',
    add: 'Add',
    save: 'Save',
    export: 'Export',
    contact: 'Contact',
    information: 'Information',
    postCode: 'Post Code',
    delete: 'Delete',
    code: 'Code',
    note: 'Note',
    number: 'Number',
    required: 'Required',
    requirements: 'Requirements',
    date: 'Date',
    location: 'Location',
    previous: 'Previous',
    edit: 'Edit',
    search: 'Search',
    actions: 'Actions',
    total: 'Total',
    amount: 'Amount',
    phone: 'Phone',
    customer: {
      agreement_signed_date: 'Agreement Date',
      categories: 'Category',
      contact_email: 'Contact Email',
      contact_name: 'Contact Name',
      created_at: 'Created at',
      customer_name: 'Customer Name',
      invitation_status: 'Status',
      registered_address: 'Registered Address',
      add_customer: 'Add Customer',
      customers: 'Customers',
      customer: 'Customer',
      compliance_status: 'Compliance Status',
      invoices: 'Invoices',
      quotes: 'Quotes',
      defects: 'Defects',
      totalQuoteAmount: 'Total Quote Amounts',
      quotesByStatus: 'Quotes by Status',
      quoteAmount: 'Quote Amount',
      organisation: 'Organisation',
      customerDetails: {
        registeredAddress: 'Registered Address',
      },
    },
    company: {
      company: 'Company',
      cell_phone: 'Cell Phone',
      companies: 'Fire Companies',
      fire: 'Fire',
      details: 'Details',
      abnNumber: 'ABN number',
      logo: 'logo',
      logoCapital: 'Logo',
      upload: 'Upload',
    },
    property: {
      property: 'Property',
      parent: 'Parent',
      properties: 'Properties',
      status: 'Status',
      billingCard: 'Billing Card',
      tags: 'Tags',
      constructionClass: 'Construction Class',
      access: 'Access',
      schedule: 'Schedule',
      siteRequirement: 'Site Requirements',
      buildingAttributes: 'Building Attributes',
      occupancyPermit: 'Occupancy Permit',
      occupancyPermitNumber: 'Occupancy Permit Number',
      occupancyPermitDate: 'Occupancy Permit Date',
      occupancyPermitLocation: 'Occupancy Permit Location',
      propertyId: 'Property ID',
      propertyName: 'Property Name',
      landLotNumber: 'Land/Lot Number',
      ocspNumber: 'OCSP or DP/DN Number',
      accountManager: 'Account Manager',
      parentProperty: 'Parent Property',
      customer: 'Customer',
      agmNewData: 'AGM Date',
      reviewNewDate: 'Review Date',
      contractAndAssignmentDetails: 'Contract and Assignment Details',
      annualComplianceDate: 'Annual Compliance Date',
      contractStartDate: 'Contract Start Date',
      contractEndDate: 'Contract Valid Till',
      defaultTechnician: 'Default Technician',
      accessDetails: 'Access Details',
      accessSchedule: 'Access Schedule',
      accessProcedure: 'Access Procedure',
      accessCode: 'Access Code',
      accessNote: 'Access Note',
      signatureRequired: 'Signature Required',
      inductionRequired: 'Induction Required',
      logBookEntryRequired: 'Log Book Entry Required',
      policeCheckRequired: 'Police Check Required',
      workingWithChildrenCheckRequired: 'Working with Children Check Required',
      serviceRequirements: 'Service Requirements',
      safetyRequirements: 'Safety Requirements',
      organization: 'Organisation',
      agmDate: 'AGM date',
      localGovArea: 'Local Government Area',
      internalNote: 'Internal Note',
      reviewDate: 'Review date',
      procedure: 'Procedure',
      signature: 'Signature',
      induction: 'Induction',
      logBookEntry: 'Log Book Entry',
      policeCheck: 'Police Check',
      workingWithChildren: 'Working With Children Check',
      service: 'Service',
      safety: 'Safety',
      annualDueCertificateDate: 'Annual Due Certification Date',
      buildingEra: 'Building Era',
      buildingClass: 'Building Class',
      buildingType: 'Building Type',
      buildingSize: 'Building Size (sqft)',
      buildingSizeSqm: 'Building Size (sqm)',
      storyAboveGround: 'Stories Above Ground',
      storyBelowGround: 'Stories Below Ground',
      buildingPart: 'Building Part',
      buildingTenancies: 'Building Tenancies',
      buildingStandards: 'Building Standards',
      propertyDetails: 'Property Details',
      zone: 'Zone',
      assets: 'Assets',
      locationInfoText:
        'Provide location information formatted as Building 5A, Floor 8, Unit 1.',
      identificationNumberInfo:
        'Provide Identification number such as Door No.',
      propertyManager: 'Property Manager',
      contact: 'Contact',
      isBooking: 'Is Booking',
    },

    superAdmin: {
      superAdmins: 'Super Admins',
      superAdmin: 'Super Admin',
      agreement_document: 'Agreement document',
    },
    technician: {
      technician_name: 'Technician Name',
      company_name: 'Company Name',
      contact_phone: 'Contact Phone',
      category: 'Category',
      skills: 'Skills',
      created_at: 'Created at',
      technician: 'Technician',
      technicians: 'Technicians',
      add_technician: 'Add Technician',
      contact_address: 'Contact Address',
      zones: 'Zones',
    },
    account_manager: {
      account_manager: 'Account Manager',
    },
    office_staff: {
      office_staff: 'Office Staff',
      office_staffs: 'Office Staffs',
      add_office_staff: 'Add Office Staff ',
      state: 'State',
      city: 'City',
      created_at: 'Created at',
      cell_phone: 'Cell Phone',
      create_new_office_staff: 'Create New OfficeStaff',
      edit_office_staff: 'Edit OfficeStaff',
    },
    work_order: {
      id: 'ID',
      work_order_name: 'Work Order Name',
      work_order_type: 'Work Order Type',
      asset_count: 'Asset Count',
      frequency: 'Frequency',
      status: 'Status',
      appointment: 'Appointment',
      due_date: 'Due Date',
      actions: 'Actions',
      work_orders: 'Work Orders',
      add_work_order: 'Add Work Order',
    },
    invoice: {
      invoices: 'Invoices',
      invoice: 'Invoice',
      paid: 'Paid',
      due: 'Due',
      overdue: 'Overdue',
      byStatus: 'by Status',
    },
    supplier: {
      suppliers: 'Suppliers',
      supplier: 'Supplier',
      business_number: 'Business Number',
      contact_name: 'Contact Name',
      contact_phone: 'Contact Phone',
      details: 'Details',
      phone: 'Phone',
    },
    productCatalogue: {
      product: 'Product',
      products: 'Products',
      product_catalogue: 'Product Catalogue',
      product_name: 'Product Name',
      product_type: 'Product Type',
      supplier: 'Supplier',
      cost_price: 'Cost Price',
      default_sell_price: 'Default Sell Price',
      equipment_type: 'Equipment Type',
      status: 'Status',
      product_details: 'Product Details',
      type: 'Type',
      supplier_details: 'Supplier Details',
      unit_of_measure: 'Unit of Measure',
      services_details: 'Services Details',
      estimate_time: 'Estimate Time',
      view_product: 'View Product',
      edit_product: 'Edit Product',
      add_product: 'Add Product',
      description: 'Description',
      labour_details: 'Labour Details',
      materials: 'Materials',
      equipment: 'Equipment',
      expenses: 'Expenses',
      labour: 'Labour',
      materials_detail_text:
        'should be used for costs that remain (or are consumed) on site after a task is completed.',
      equipment_details_text:
        'should be used for costs that are brought to a site temporarily.',
      expenses_details_text:
        'can be used for costs that are neither brought to site nor left on site, e.g. parking costs or material delivery costs.',
      labour_details_text:
        'costs and charges for routine service inspections, repair or replacement.',
      product_detail_description_note:
        'Leave blank to use the product name. The description is typically shown on reports, invoices and purchase orders.',
      labour_details_cost_price_note:
        "This is your blended average cost for this type of labour. This value is used during quoting, and updated with the individual technician's labour rate as technicians log time to a task.",
      esitmated_time_note:
        'Estimated labour time (in minute) needs for this material product, e.g. 15, 30, 120.',
    },
  },

  auth: {
    login: {
      continueLoginWith: 'Continue Login with',
      login: 'Login',
      unAuthorized: `Sorry, you are not allowed to view this resource.<br />If you need access, please contact an administrator or sign in with the correct account.`,
      notAllowed:
        'Sorry, you are not allowed to login to web portal, Please login to mobile app.',
    },
  },

  common: {
    category: 'Category',
    city: 'City',
    next: 'Next',
    state: 'State',
    active: 'Active',
    inactive: 'Inactive',
    loading: 'Loading...',
    welcome: 'Welcome',
    status: {
      pending: 'Pending',
      approved: 'Approved',
      inProgress: 'In-progress',
      quotesRejected: 'Quotes Rejected',
      rejected: 'Rejected',
      overdue: 'Overdue',
    },
  },

  label: {
    login: 'Login',
    globalAdmin: {
      fireCompanyActiveCheckbox: 'Fire company will be moved to active',
      fireCompanyInactiveCheckbox: 'Fire company will be moved to inactive',
      superAdminActiveCheckbox: 'Super Admin will be moved to active',
      superAdminInactiveCheckbox: 'Super Admin will be moved to inactive',
    },
    superAdmin: {
      superAdminActiveCheckbox: 'Super Admin will be moved to active',
      superAdminInactiveCheckbox: 'Super Admin will be moved to inactive',
      technicianActiveCheckbox: 'Technician will be moved to active',
      technicianInactiveCheckbox: 'Technician will be moved to inactive',
      assetActiveCheckbox: 'Asset will be moved to active',
      assetInactiveCheckbox: 'Asset will be moved to inactive',
      supplierActiveCheckbox: 'Supplier will be moved to active',
      supplierInactiveCheckbox: 'Supplier will be moved to inactive',
      productActiveCheckbox: 'Product will be moved to active',
      productInactiveCheckbox: 'Product will be moved to inactive',
    },
  },

  message: {
    errorPage: {
      status: '404',
      error: 'Page not found',
      errorMessage: 'The page you were looking for does not exist.',
    },
    forbiddenPage: {
      status: '403',
      forbidden: 'Forbidden',
      forbiddenMessage: 'You do not have permission to view this page.',
    },
    customer: {
      customerCreatedSuccessfully: 'Customer created successfully',
      customerUpdatedSuccessfully: 'Customer updated successfully',
    },
    company: {
      companyCreatedSuccessfully: 'Fire company created successfully',
      companyUpdatedSuccessfully: 'Fire company updated successfully',
    },
    superAdmin: {
      superAdminCreatedSuccessfully: 'Super admin created successfully',
      superAdminUpdatedSuccessfully: 'Super admin updated successfully',
    },
    supplier: {
      createdSuccessfully: 'Supplier created successfully',
      updatedSuccessfully: 'Supplier updated successfully',
    },
    productCatalogue: {
      createdSuccessfully: 'Product created successfully',
      updatedSuccessfully: 'Product updated successfully',
    },
  },

  confirmationModal: {
    title: 'Warning: Unsaved Changes',
    description: 'Closing the panel will result in losing any unsaved changes.',
  },

  modal: {
    delete: {
      message: 'Are you sure you want to delete?',
    },
  },
};

export default { translation };
