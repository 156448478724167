import React from 'react';

import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomCircularProgress = styled(CircularProgress)({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  right: '1rem',
  top: '30%',
});

export const CustomButtonLoader = () => <CustomCircularProgress size={16} />;

export default CustomButtonLoader;
