import { createAsyncThunk } from '@reduxjs/toolkit';

import { filterDateFormatted } from '../../utils';
import { COMPANY } from '../actionPrefix';
import { axiosInstanceCompany } from '../axios';

const {
  CREATE_COMPANY,
  EDIT_COMPANY,
  GET_COMPANY_DROPDOWN_LIST,
  GET_COMPANY_LIST,
  UPDATE_COMPANY,
} = COMPANY;

export const getCompanyList = createAsyncThunk(
  GET_COMPANY_LIST,
  async (
    {
      page,
      search,
      size,
      active,
      country,
      state,
      city,
      order,
      orderBy,
      fromDate,
      toDate,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('items_per_page', size);
    if (typeof active === 'boolean') params.append('active', active); // For boolean, explicitly check
    if (country) params.append('country', country);
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (fromDate) params.append('from_date', filterDateFormatted(fromDate));
    if (toDate) params.append('to_date', filterDateFormatted(toDate));
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceCompany.get(
        `company?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCompany = createAsyncThunk(
  UPDATE_COMPANY,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.put(`company/${uuid}`, data);

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const createCompany = createAsyncThunk(
  CREATE_COMPANY,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.post('company', data);

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const editCompany = createAsyncThunk(
  EDIT_COMPANY,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.get(`company/${uuid}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompanyDropdownList = createAsyncThunk(
  GET_COMPANY_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.get(`company_name`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
