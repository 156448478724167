import React from 'react';

import { Box, TablePagination } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

import { DIVIDER_COLOR, ROW_HIGHLIGHTER_COLOR } from '../../constants/Colors';
import { FONT_SIZE } from '../../constants/Constants';
import { isCreatedWithinLastFiveMinutes } from '../../utils';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  boxShadow: 'none',
  '.MuiDataGrid-cell': {
    borderBottom: `1px solid ${DIVIDER_COLOR}`,
    alignContent: 'center',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    fontSize: FONT_SIZE,
    fontWeight: 400,
  },
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
    border: 'none',
  },
  '.MuiDataGrid-cell:focus-within': {
    outline: 'none',
    border: 'none',
  },
  '.MuiDataGrid-columnHeader:focus': {
    outline: 'none',
    border: 'none',
  },
  '.MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
    border: 'none',
  },
  '.MuiDataGrid-footerContainer': {
    display: 'none',
  },
  '.highlight-row': {
    backgroundColor: ROW_HIGHLIGHTER_COLOR,
  },
}));

export default function CustomGridTable({
  columns,
  rows,
  total,
  currentPage,
  setCurrentPage,
  perPageData,
  setPerPageData,
  orderBy,
  setOrderBy,
  order,
  setOrder,
  isLoading,
  columnVisibilityModel,
  setColumnVisibilityModel,
  noData,
  filterHeight = 400,
  rowsPerPageOptions = [10, 20, 30],
  checkboxSelection = true,
  paginationRequired = true,
  ...rest
}) {
  const handleChangePage = (e, val) => {
    setCurrentPage(val + 1);
  };

  const handleChangeRowsPerPage = (e) => {
    setPerPageData(e?.target?.value);
    setCurrentPage(1);
  };

  const handleSortModelChange = (newModel) => {
    if (newModel?.[0]?.field) {
      setOrderBy(newModel?.[0]?.field);
      setOrder(newModel?.[0]?.sort);
    }
  };

  const handleColumnVisibilityChange = (model) => {
    setColumnVisibilityModel(model);
  };

  // Highlight rows based on the `created_at` value
  const getRowClassName = (params) => {
    const createdAt = params.row?.created_at; // Access the `created_at` value from the row data

    return isCreatedWithinLastFiveMinutes(createdAt) ? 'highlight-row' : ''; // Return class name conditionally
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: `calc(100vh - ${filterHeight}px)`,
          overflow: 'hidden',
        }}
      >
        <StyledDataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={rowsPerPageOptions}
          checkboxSelection={checkboxSelection}
          disableColumnFilter
          sortingMode="server"
          sortingOrder={['desc', 'asc']}
          sortModel={[{ field: orderBy, sort: order }]}
          onSortModelChange={handleSortModelChange}
          rowHeight={50}
          disableColumnResize
          disableRowSelectionOnClick
          hideFooterPagination
          getRowHeight={() => 'auto'}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          loading={isLoading}
          getRowClassName={getRowClassName}
          slots={{
            noRowsOverlay: () => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: `calc(100vh - ${filterHeight}px)`,
                }}
              >
                {noData}
              </Box>
            ),
          }}
          slotProps={{
            panel: {
              sx: {
                '.MuiDataGrid-columnsManagementFooter .MuiButton-root': {
                  height: '100% !important',
                },
              },
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          {...rest}
        />
        {paginationRequired && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={total}
            rowsPerPage={perPageData}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            sx={{
              '& .MuiSelect-select': {
                fontSize: FONT_SIZE,
                paddingRight: '8px',
              },
              '& .MuiSelect-icon': {
                fontSize: '16px',
              },
              '& .MuiTablePagination-actions .MuiSvgIcon-root': {
                fontSize: '16px',
              },
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </>
  );
}
