import { createSlice } from '@reduxjs/toolkit';

import {
  createSuperAdmin,
  getSuperAdminById,
  getSuperAdminList,
  getWidgetData,
  updateCharts,
  updateSuperAdmin,
} from './api';

const initialCreateData = {
  isLoading: false,
  error: false,
  createdSuperAdmin: false,
};

const initialEditData = {
  isLoading: false,
  error: false,
  editSuperAdmin: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  superAdminActiveList: [],
  superAdminInactiveList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const initialWidgetData = {
  isLoading: false,
  error: null,
  data: [],
};

const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    edit: {
      ...initialEditData,
    },
    widget: {
      ...initialWidgetData,
    },
  },
  reducers: {
    resetCreate(state) {
      state.create = { ...initialCreateData };
    },
    resetEdit(state) {
      state.edit = { ...initialEditData };
    },
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Super admin List
      .addCase(getSuperAdminList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getSuperAdminList.fulfilled, (state, action) => {
        const { data, active, pagination } = action.payload;

        state.get.isLoading = false;
        if (active) {
          state.get.superAdminActiveList = data;
        } else {
          state.get.superAdminInactiveList = data;
        }
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getSuperAdminList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Create super admin
      .addCase(createSuperAdmin.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createSuperAdmin.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdSuperAdmin = action.payload.data[0];
      })
      .addCase(createSuperAdmin.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Update super admin
      .addCase(updateSuperAdmin.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(updateSuperAdmin.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdSuperAdmin = action.payload.data[0];
      })
      .addCase(updateSuperAdmin.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Edit
      .addCase(getSuperAdminById.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(getSuperAdminById.fulfilled, (state, action) => {
        state.edit.isLoading = false;
        state.edit.editSuperAdmin = action.payload.data[0];
      })
      .addCase(getSuperAdminById.rejected, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = action.payload;
      })
      // GetWidget
      .addCase(getWidgetData.pending, (state) => {
        state.widget.isLoading = true;
      })
      .addCase(getWidgetData.fulfilled, (state, action) => {
        state.widget.isLoading = false;
        state.widget.data = action.payload.data;
      })
      .addCase(getWidgetData.rejected, (state, action) => {
        state.widget.isLoading = false;
        state.widget.error = action.payload;
      })
      // UPDATE Widget
      .addCase(updateCharts.pending, (state) => {
        state.widget.isLoading = true;
      })
      .addCase(updateCharts.fulfilled, (state, action) => {
        state.widget.isLoading = false;
        state.widget.data = action.payload.data;
      })
      .addCase(updateCharts.rejected, (state, action) => {
        state.widget.isLoading = false;
        state.widget.error = action.payload;
      });
  },
});

export const { resetCreate, resetEdit, resetPagination } =
  superAdminSlice.actions;

export default superAdminSlice.reducer;
