import React from 'react';
import { Controller } from 'react-hook-form';

import { Box, styled, Typography } from '@mui/material';
import {
  DatePicker,
  DateRangePicker,
  DateTimePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';

import { DISABLE, LIGHT_RED, WARNING } from '../../constants/Colors';
import { DATE_FORMAT } from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import CustomTextField from './CustomTextField';

// Styled picker component
const CustomisedDatePicker = styled(
  ({ pickerComponent: PickerComponent, ...props }) => (
    <PickerComponent {...props} />
  )
)(({ theme, hasValue, isForFilter, error }) => ({
  '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
    top: '4px',
    ...(isForFilter && { color: theme.palette.primary.main }),
    ...(error && { color: LIGHT_RED }),
  },
  '& .MuiFormLabel-root': {
    top: hasValue ? '5px !important' : '-6px',
    ...(isForFilter && { color: DISABLE }),
    ...(error && { color: LIGHT_RED }),
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.common.white,
    fontWeight: 500,
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
    '& fieldset': {
      borderColor: error ? LIGHT_RED : theme.palette.grey[400],
    },
    '&:hover fieldset': {
      borderColor: error ? LIGHT_RED : theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? LIGHT_RED : theme.palette.primary.main,
      color: error ? LIGHT_RED : theme.palette.primary.main,
    },
  },
}));

const CustomDatePicker = ({
  control,
  name,
  label,
  pickerType = 'date', // default to 'date', can be 'date', 'datetime', or 'daterange'
  defaultValue = moment(),
  isRequired = false,
  trigger,
  isForFilter = false,
  defaultFormat = DATE_FORMAT,
  ...props
}) => {
  // Function to render the appropriate picker based on pickerType
  const renderPicker = ({ value, onChange, error }) => {
    const hasValue = Boolean(value);

    switch (pickerType) {
      case 'datetime':
        return (
          <CustomisedDatePicker
            pickerComponent={DateTimePicker}
            isForFilter={isForFilter}
            hasValue={hasValue}
            label={<Typography variant="body2">{label}</Typography>}
            value={value}
            onChange={onChange}
            renderInput={(params) => <CustomTextField {...params} />}
            {...props}
          />
        );
      case 'daterange':
        return (
          <DateRangePicker
            startText="Start"
            endText="End"
            value={value}
            onChange={onChange}
            renderInput={(startProps, endProps) => (
              <>
                <CustomTextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <CustomTextField {...endProps} />
              </>
            )}
            {...props}
          />
        );
      case 'date':
      default:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <CustomisedDatePicker
              pickerComponent={DatePicker}
              isForFilter={isForFilter}
              label={
                <Typography variant="body2">
                  {label}
                  {isRequired && <span style={{ color: WARNING }}>*</span>}
                </Typography>
              }
              hasValue={hasValue}
              labelStyle={{
                top: '-9px',
              }}
              value={value ? moment(value) : null}
              onChange={(e) => {
                onChange(e);
                trigger && trigger(name);
              }}
              renderInput={(params) => <CustomTextField {...params} />}
              error={error}
              format={defaultFormat}
              {...props}
            />
            {error && (
              <Typography color="error" variant="caption">
                {error?.message}
              </Typography>
            )}
          </Box>
        );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: isRequired
            ? `${label} ${Validation.general.required}`
            : false,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) =>
          renderPicker({ value, onChange, error })
        }
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
