import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { SWIPEABLE_DRAWER_WIDTH } from '../../constants/Typography';
import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  createAsset,
  getAssetStatus,
  getEquipmentTypes,
  getProducts,
  getPropertyAssetsList,
  updateAsset,
} from '../../store/property/api';
import {
  resetAssetsList,
  resetcreateAsset,
  resetUpdateAsset,
} from '../../store/property/reducer';
import { formatDateAPI, formattedDate } from '../../utils';
import { Edit, View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StatusLabel from '../CommonComponents/StatusLabel';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import AssetForm from './AssetForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  equipmentType: null,
  product: null,
  status: null,
  active: null,
};

const defaultValues = {
  equipment_type: null,
  product: null,
  location: null,
  identification_number: null,
  barcode: null,
  make: null,
  model: null,
  size: null,
  quantity: null,
  base_date: null,
  installation_date: null,
  internal_note: null,
  active: null,
};

const AssetsList = ({ propertyId, isEditable = true }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [searchText, setSearchText] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [open, setOpen] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);
  const [assetId, setAssetId] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);

  const { equipmentTypesList, assetStatusList, products, assetsList } =
    useSelector((state) => state.property);
  const {
    data: createdAsset,
    loading: createAssetLoading,
    error: createAssetError,
  } = useSelector((state) => state.property.createAsset);

  const {
    data: updatedAsset,
    loading: updatedAssetLoading,
    error: updatedAssetError,
  } = useSelector((state) => state.property.updatedAsset);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    trigger,
    setError,
    clearErrors,
  } = useForm();

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const selectedEquipmentType = watch('equipment_type');
  const selectedProduct = watch('product');

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const equipmentTypes = getDropdownDataHook({
    data: equipmentTypesList?.data?.data,
    labelName: 'display_name',
    valueName: 'code',
  });

  const assetStatus = getDropdownDataHook({
    data: assetStatusList?.data?.data,
    labelName: 'display_name',
    valueName: 'id',
  });

  const productsList = getDropdownDataHook({
    data: products?.data?.data,
    labelName: 'display_name',
    valueName: 'code',
  });

  useEffect(() => {
    dispatch(getProducts({ equipment_type: filters.equipmentType?.value }));
  }, [filters.equipmentType]);

  const findIdByCode = (data, selectedData) => {
    const matchedItem = data?.find(
      (item) => item?.code === selectedData?.value
    );

    return matchedItem ? matchedItem.id : null;
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography
            variant="body1"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {row.asset_name}
          </Typography>
        ),
      },
      {
        field: 'equipment_type',
        headerName: 'Equipment Type',
        flex: 1,
        sortable: true,
      },
      {
        field: 'product',
        headerName: 'Product',
        flex: 1,
        sortable: false,
      },
      {
        field: 'barcode',
        headerName: 'Barcode',
        flex: 1,
        sortable: false,
      },
      {
        field: 'location',
        headerName: 'Location',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      {
        field: 'active',
        headerName: 'Active',
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.active),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View onClick={() => {}} />
            <Edit
              onClick={() => {
                setOpen(true);
                setAssetId(row?.id);
              }}
            />
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  useEffect(() => {
    if (serverErrors) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  useEffect(() => {
    dispatch(getEquipmentTypes());
    dispatch(getAssetStatus());

    return () => {
      dispatch(resetUpdateAsset());
      dispatch(resetcreateAsset());
      dispatch(resetAssetsList());
    };
  }, []);

  const getAllAssetsList = useCallback(() => {
    const req = {
      order: order,
      orderBy: orderBy,
      page: currentPage,
      size: perPageData,
      search: searchText,
      status: filters.status?.value,
      is_active: filters.active?.value,
      status_id: filters.status?.value,
      product_id: findIdByCode(products?.data?.data, filters?.product),
      equipment_type_id: findIdByCode(
        equipmentTypesList?.data?.data,
        filters?.equipmentType
      ),
      property_uuid: propertyId,
    };

    dispatch(getPropertyAssetsList(req)).finally(() => {});
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    propertyId,
  ]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllAssetsList, 500), [
    getAllAssetsList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const rows = assetsList?.data?.data?.map((item) => ({
    ...item,
    active: item.is_active,
    asset_name: item.label,
    base_date: formattedDate(item.base_date),
    installation_date: formattedDate(item.installation_date),
    equipment_type: item.equipment_type.display_name,
    product: item.product.display_name,
    status: item.status?.display_name ?? '-',
  }));

  useEffect(() => {
    if (createAssetLoading !== null && !createAssetLoading) {
      if (createAssetError) {
        if (createAssetError.errorDetails) {
          setServerErrors(createAssetError.errorDetails);
        }
      } else {
        if (createdAsset) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createdAsset.message,
            })
          );
        }
      }
    }
  }, [createAssetError, createAssetLoading]);

  useEffect(() => {
    if (updatedAssetLoading !== null && !updatedAssetLoading) {
      if (updatedAssetError) {
        if (updatedAssetError.errorDetails) {
          setServerErrors(updatedAssetError.errorDetails);
        }
      } else {
        if (updatedAsset) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: updatedAsset.message,
            })
          );
        }
      }
    }
  }, [updatedAssetError, updatedAssetLoading]);

  const resetFilter = () => {
    setSearchText('');
    setFilters(defaultFilters);
  };

  const formatStatus = (status) => {
    const lowerCaseStatus =
      typeof status === 'string' ? status?.toLowerCase() : status;

    if (lowerCaseStatus === 'fail') {
      return <StatusLabel label="Fail" color="#C54036" />;
    }
    if (lowerCaseStatus === 'pass') {
      return <StatusLabel label="Pass" color="#95C020" />;
    }
    if (lowerCaseStatus === 'no check') {
      return <StatusLabel label="No Check" color="#59366B" />;
    }

    if (lowerCaseStatus === true) {
      return <StatusLabel label="Yes" color="#59C3C3" />;
    }
    if (lowerCaseStatus === false) {
      return <StatusLabel label="No" color="#454545" />;
    }
  };

  const onSubmit = (data) => {
    const requestData = {
      equipment_type_id: findIdByCode(
        equipmentTypesList?.data?.data,
        data?.equipment_type
      ),
      product_id: findIdByCode(products?.data?.data, data?.product),
      label: `${selectedEquipmentType?.label || ''}-${selectedProduct?.label || ''}`,

      location: data?.location,
      identification_number: data?.identification_number,
      barcode: data?.barcode,

      make: data?.make,
      model: data?.model,

      size: data?.size,
      quantity: data?.quantity,

      base_date: data?.base_date ? formatDateAPI(data?.base_date) : null,
      installation_date: data?.installation_date
        ? formatDateAPI(data?.installation_date)
        : null,
      internal_note: data?.internal_note,

      property_uuid: propertyId,
      is_active: data?.active,
      status_id: data?.status_id?.value,
      // standard_id: 1,
      // routine_type_id: 1,
      // frequency_id: 1,
      // form_id: 1,
      // created_type: '1',
      // description: '1',
    };

    if (!assetId) {
      dispatch(createAsset(requestData));
    } else {
      dispatch(updateAsset({ assetId: assetId, data: requestData }));
    }
  };

  const styledButtonDispalyer = isEditable && (
    <>
      <CustomButton
        text="Add Asset"
        color="secondary"
        sx={{ height: '52%' }}
        startIcon={<AddCircleOutlineOutlinedIcon />}
        onClick={() => {
          setOpen(true);
          setAssetId('');
          reset(defaultValues);
        }}
      />
      <CustomButton
        text="Export"
        color="secondary"
        sx={{ height: '52%' }}
        startIcon={<OpenInNewIcon />}
        onClick={() => {}}
      />
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      <StyledMainWrapper btn={styledButtonDispalyer} isSubDetails={true}>
        <Box sx={{ paddingTop: '16px' }}>
          <FilterSection
            onFilterBtnClick={() =>
              setIsFilterComponentVisible(!isFilterComponentVisible)
            }
            isRefresh={true}
            searchText={searchText}
            isActive={isFilterComponentVisible}
            onRefreshFilter={() => window.location.reload()}
            onResetFilter={resetFilter}
            onSearchChange={(e) => setSearchText(e.target.value)}
            isResetButtonVisible={
              searchText ||
              filters.equipmentType ||
              filters.product ||
              filters.status ||
              filters.active
            }
            sx={{ marginRight: '16px' }}
          />
          {isFilterComponentVisible && (
            <FilterComponent>
              <Autocomplete
                placeholder="Equipment Type"
                options={equipmentTypes}
                value={filters?.equipmentType}
                onChange={(e, newValue) =>
                  setFilters((prev) => ({
                    ...prev,
                    product: null,
                    equipmentType: newValue,
                  }))
                }
                width="190px"
                isLoadingData={equipmentTypesList?.loading}
              />
              <Autocomplete
                placeholder="Product"
                options={productsList}
                value={filters?.product}
                onChange={(e, newValue) =>
                  setFilters((prev) => ({ ...prev, product: newValue }))
                }
                width="190px"
                isLoadingData={products?.loading}
                disabledDropdown={!filters?.equipmentType}
              />
              <Autocomplete
                placeholder="Status"
                options={assetStatus}
                value={filters?.status}
                onChange={(e, newValue) =>
                  setFilters((prev) => ({ ...prev, status: newValue }))
                }
                width="190px"
                isLoadingData={assetStatusList?.loading}
              />
              <Autocomplete
                placeholder="Active"
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                value={filters?.active}
                onChange={(e, newValue) =>
                  setFilters((prev) => ({ ...prev, active: newValue }))
                }
                width="190px"
                isLoadingData={false}
              />
            </FilterComponent>
          )}
          <CustomGridTable
            columns={columns}
            rows={rows}
            total={assetsList?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPageData={perPageData}
            setPerPageData={setPerPageData}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            noData={<NoRecordFound />}
            isLoading={assetsList?.loading}
            filterHeight={filterHeight(isFilterComponentVisible)}
          />
        </Box>
      </StyledMainWrapper>

      {open && (
        <SwipeableDrawer
          open={open}
          title={!assetId ? 'Add Asset' : 'Edit Asset'}
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.save')}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={createAssetLoading || updatedAssetLoading}
            />
          }
          width={SWIPEABLE_DRAWER_WIDTH}
        >
          <AssetForm
            id={assetId}
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            equipmentTypes={equipmentTypes}
            assetStatusList={assetStatus}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default AssetsList;
