import BlockIcon from '@mui/icons-material/Block';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

// Internal
import { WARNING } from '../../constants/Colors';
import CustomButton from './CustomButton';

export const Edit = ({ onClick = () => {} }) => (
  <CustomButton
    startIcon={<ModeEditOutlineOutlinedIcon />}
    variant="outlined"
    onClick={onClick}
    sx={{
      '&.MuiButton-root': {
        minWidth: '24px',
        height: '24px',
        padding: '5px',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    }}
  />
);

export const InActive = ({ onClick = () => {} }) => (
  <CustomButton
    startIcon={<BlockIcon />}
    text="InActive"
    variant="outlined"
    onClick={onClick}
    sx={{
      '&.MuiButton-root': {
        minWidth: '50px',
        padding: '5px',
        border: `1px solid ${WARNING}`,
        color: WARNING,
        fontWeight: 600,
      },
      '& .MuiButton-startIcon': {
        margin: 0,
        marginRight: '5px',
      },
    }}
  />
);
export const View = ({ onClick = () => {} }) => (
  <CustomButton
    startIcon={<VisibilityOutlinedIcon />}
    // startIcon={<RemoveRedEyeOutlinedIcon />}
    variant="outlined"
    onClick={onClick}
    sx={{
      '&.MuiButton-root': {
        minWidth: '24px',
        height: '24px',
        padding: '5px',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    }}
  />
);
