import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';

// Internal
import { LOGIN_TYPE } from '../constants/Constants';
import { CUSTOMER } from '../constants/Roles';
import { ROUTENAME } from '../constants/RoutesConstants';
import { google_auth, loginRequest, microsoft_auth } from '../hoc/authConfig';
import { getUserRole } from '../hoc/Permission';
import { getMe } from '../store/CommonAPI';
import LandingPageBody from './LandingPageLayout/LandingPageBody';
import LandingPageFooter from './LandingPageLayout/LandingPageFooter';
import LandingPageHeader from './LandingPageLayout/LandingPageHeader';
import LandingPageLoginModal from './LandingPageLayout/LandingPageLoginModal';

export const getAuthority = (provider) => {
  if (provider === LOGIN_TYPE.google) {
    return google_auth;
  } else if (provider === LOGIN_TYPE.microsoft) {
    return microsoft_auth;
  }
};

export default function ButtonAppBar() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isSSOPopupOpen, setIsSSOPopupOpen] = useState(false);
  const [isUserInfoAvailable, setIsUserInfoAvailable] = useState('');
  const [loginType, setLoginType] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isAuthSuccess, isAuthLoading } = useSelector((state) => state.common);

  const getAuthority = (provider) => {
    if (provider === LOGIN_TYPE.google) {
      return google_auth;
    } else if (provider === LOGIN_TYPE.microsoft) {
      return microsoft_auth;
    }
  };

  const handleLogin = (type) => {
    setIsSSOPopupOpen(true);
    instance
      .loginPopup({
        ...loginRequest,
        prompt: 'login',
        authority: getAuthority(type),
      })
      .then((res) => {
        localStorage.setItem('idToken', res?.idToken);
        localStorage.setItem('expirationTime', res?.idTokenClaims?.exp);
        setLoginType(type);
        setIsUserInfoAvailable(res?.idToken);
        localStorage.setItem('loginType', type);
      })
      .catch((e) => {
        console.error(e);
        navigate(ROUTENAME.DEFAULT_ROUTE);
      })
      .finally(() => {
        setIsSSOPopupOpen(false);
      });
  };

  useEffect(() => {
    if (isAuthSuccess && localStorage.getItem('user_info')) {
      const userRole = getUserRole();
      const redirectPath =
        CUSTOMER === userRole
          ? `${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}`
          : ROUTENAME.DASHBOARD;

      navigate(redirectPath);
      handleClose();
    }
  }, [
    isSSOPopupOpen,
    isAuthLoading,
    isAuthSuccess,
    localStorage.getItem('user_info'),
  ]);

  useEffect(() => {
    if (isUserInfoAvailable) {
      dispatch(getMe());
    }
  }, [isUserInfoAvailable]);

  return (
    <>
      <LandingPageHeader openModal={handleOpen} />
      <LandingPageBody />
      <LandingPageFooter />
      <LandingPageLoginModal
        closeModal={handleClose}
        open={open}
        handleLogin={handleLogin}
        isSSOPopupOpen={isSSOPopupOpen}
        loginType={loginType}
      />
    </>
  );
}
