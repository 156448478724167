import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SaveIcon from '@mui/icons-material/Save';
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import {
  Box,
  Divider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';

import { theme } from '../../assets/theme';
import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { SQFT_TO_SQM, validateAddress } from '../../constants/common';
import { Validation } from '../../constants/FieldValidationMsg';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import getStatesHook from '../../hooks/getStatesHook';
import useAddressValidationHook from '../../hooks/useAddressValidationHook';
import { snackbarToggle } from '../../store/CommonReducer';
import { createProperty, updateProperty } from '../../store/property/api';
import { clearTextfields, datePickerFormat, formatDateAPI } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomDatePicker from '../CommonComponents/CustomDatePicker';
import CustomTextField from '../CommonComponents/CustomTextField';

const postCodeLength = 4;

const stepIcons = [
  <AddHomeWorkOutlinedIcon
    key="icon-01"
    sx={{ height: '15px', width: '15px' }}
  />,
  <SettingsAccessibilityOutlinedIcon
    key="icon-02"
    sx={{ height: '15px', width: '15px' }}
  />,
  <ChecklistOutlinedIcon
    key="icon-03"
    sx={{ height: '15px', width: '15px' }}
  />,
  <DomainAddOutlinedIcon
    key="icon-04"
    sx={{ height: '15px', width: '15px' }}
  />,
  <WorkspacePremiumOutlinedIcon
    key="icon-05"
    sx={{ height: '15px', width: '15px' }}
  />,
];

// Custom Connector with dotted line
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.grey,
    borderWidth: 2,
    borderLeftStyle: 'dotted',
  },
}));

// Custom Step Icon
const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? theme.palette.primary.main : 'white',
  color: ownerState.active ? 'white' : 'black',
  zIndex: 1,
  width: 26,
  height: 26,
  border: ownerState.active ? '' : '1px solid black',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundColor: 'black',
    color: 'white',
  }),
}));

const CustomStepIcon = ({ icon, active, completed, className }) => (
  <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
    {stepIcons[icon - 1]}
  </CustomStepIconRoot>
);
const LayoutContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  height: '100%',
}));

const Section = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.secondary.main,
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '4px',
  gap: '16px',
}));

const RowBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
}));

export const PropertyForm = ({
  formMode,
  propertyData,
  formStatusList,
  tagsList,
  accessScheduleList,
  accessProcedureList,
  buildingEraList,
  buildingClassList,
  constructionClassList,
  buildingTypeList,
  buildingStandardList,
  localGovermentAreaList,
  parentPropertyListData,
  accountManagerList,
  customerList,
  billingCardList,
  isOpen,
  activeStep,
  setActiveStep,
  isFromDetails,
  zoneList,
  isZoneLoading,
  setServerErrors,
  createLoading,
  updateLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useFormContext();

  const [addressBlur, setAddressBlur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const [coords, setCoords] = useState(null);
  const state = watch('stateId');
  const city = watch('cityId');
  const address = watch('propertyAddress');
  const postcode = watch('postCode');

  useAddressValidationHook({
    address,
    addressBlur,
    city,
    cityBlur,
    clearErrors,
    postcode,
    postcodeBlur,
    setCoords,
    setError,
    state,
    stateBlur,
    fieldName: {
      addressKey: 'propertyAddress',
      addressValue: t('attributes.address'),
      stateKey: 'stateId',
      stateValue: t('common.state'),
      cityKey: 'cityId',
      cityValue: t('common.city'),
      postCodeKey: 'postCode',
      postCodeValue: t('attributes.postCode'),
    },
  });

  const { technicianDropdownData } = getDropdownListHook({
    reducerName: 'technician',
    dropdownListName: 'technicianNamesDropdownList',
    labelName: 'name',
    valueName: 'uuid',
  });

  const selectedState = watch('stateId');

  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);

  const formRef = useRef(null);

  useEffect(() => {
    if (isOpen && formRef.current) {
      formRef.current.scrollTop = 0; // Scroll to top
    }
  }, [isOpen]);

  useEffect(() => {
    if (formMode) {
      !isFromDetails && setActiveStep(0);
      reset({
        propertyName: '',
        propertyAddress: '',
        cityId: null,
        stateId: null,
        postCode: '',
        customerId: null,
        accountManagerId: null,
        statusId: null,
        landLotNumber: '',
        parentPropertyId: null,
        ocspDpDnNumber: '',
        organization: '',
        agmDate: null,
        reviewDate: null,
        annualComplianceDate: null,
        contractStartDate: null,
        contractEndDate: null,
        technician: null,
        localGovermentArea: '',
        internalNote: '',
        tags: [],
        accessCode: '',
        accessNote: '',
        accessSchedule: [],
        signatureRequired: false,
        inductionRequired: false,
        logBookEntryRequired: false,
        policeCheckRequired: false,
        workingWithChildernCheckRequired: false,
        serviceRequirement: '',
        safetyRequirement: '',
        annualDueCertificateDate: null,
        buildingSize: '',
        storiesAboveGround: '',
        storiesBelowGround: '',
        buildingPart: '',
        buildingTenancies: '',
        occupancyPermitNumber: '',
        occupancyPermitDate: null,
        occupancyPermitLocation: '',
      });
    }

    const findLabelByValue = (list, value) => {
      const item = list.find((option) => option.value === value);

      return item ? { label: item.label, value: item.value } : null;
    };

    if (formMode === 'edit' && propertyData) {
      setCoords({
        longitude: propertyData.longitude || '',
        latitude: propertyData.latitude || '',
      });
      setAddressBlur(true);
      setCityBlur(true);
      setStateBlur(true);
      setPostcodeBlur(true);
      reset({
        propertyName: propertyData.property_name || '',
        propertyAddress: propertyData.address || '',
        stateId: { label: propertyData?.state, value: propertyData?.state },
        cityId: { label: propertyData?.city, value: propertyData?.city },
        postCode: propertyData.zip_code || '',
        propertyManagerName: propertyData?.property_contact_info?.name || '',
        propertyManagerEmail: propertyData?.property_contact_info?.email || '',
        propertyManagerPhone: propertyData?.property_contact_info?.phone || '',
        customerId: findLabelByValue(customerList, propertyData?.customer_uuid),
        accountManagerId: findLabelByValue(
          accountManagerList,
          propertyData?.property_account_manager?.id
        ),
        billingCardId: findLabelByValue(
          billingCardList,
          propertyData?.property_billing_card?.id
        ),
        parentPropertyId: findLabelByValue(
          parentPropertyListData,
          propertyData?.property_parent?.id
        ),
        zones: findLabelByValue(zoneList, propertyData?.zone) || '',
        statusId: findLabelByValue(
          formStatusList,
          propertyData?.property_status?.id
        ),
        landLotNumber: propertyData?.lot_number || '',
        ocspDpDnNumber: propertyData?.ocsp_number || '',
        organization: propertyData?.organization || '',
        agmDate: propertyData.agm_date
          ? datePickerFormat(propertyData?.agm_date)
          : null,
        reviewDate: propertyData.review_date
          ? datePickerFormat(propertyData.review_date)
          : null,
        annualComplianceDate: propertyData?.contract_assignment_details
          ?.annual_compliance_date
          ? datePickerFormat(
              propertyData?.contract_assignment_details?.annual_compliance_date
            )
          : null,
        contractStartDate: propertyData?.contract_assignment_details
          ?.contract_start_date
          ? datePickerFormat(
              propertyData?.contract_assignment_details?.contract_start_date
            )
          : null,
        contractEndDate: propertyData?.contract_assignment_details
          ?.contract_expiry_date
          ? datePickerFormat(
              propertyData?.contract_assignment_details?.contract_expiry_date
            )
          : null,
        technician: {
          label: propertyData?.contract_assignment_details?.technician,
          value: propertyData?.contract_assignment_details?.technician_uuid,
        },
        localGovermentArea: findLabelByValue(
          localGovermentAreaList,
          propertyData?.property_local_government_area?.id
        ),
        internalNote: propertyData?.internal_note || '',
        tags:
          propertyData.property_tags?.map((tag) =>
            findLabelByValue(tagsList, tag?.tags?.id)
          ) || [],
        accessCode: propertyData.access_details?.[0]?.access_code || '',
        accessNote: propertyData.access_details?.[0]?.access_note || '',
        accessSchedule:
          propertyData.access_details?.[0]?.access_detail_schedule?.map((ads) =>
            findLabelByValue(accessScheduleList, ads.schedules?.id)
          ) || [],
        accessProcedure:
          propertyData.access_details?.[0]?.access_detail_procedure?.map(
            (adp) => findLabelByValue(accessProcedureList, adp.procedures?.id)
          ) || [],
        signatureRequired:
          propertyData.site_requirements?.[0]?.signature_required || false,
        inductionRequired:
          propertyData.site_requirements?.[0]?.induction_required || false,
        logBookEntryRequired:
          propertyData.site_requirements?.[0]?.log_book_entry_required || false,
        policeCheckRequired:
          propertyData.site_requirements?.[0]?.police_check_required || false,
        isBookingRequired: propertyData?.is_booking_required || false,
        workingWithChildernCheckRequired:
          propertyData.site_requirements?.[0]
            ?.working_with_children_check_required || false,
        serviceRequirement:
          propertyData.site_requirements?.[0]?.service_requirements || '',
        safetyRequirement:
          propertyData.site_requirements?.[0]?.technician_requirements || '',
        annualDueCertificateDate: propertyData.building_attributes?.[0]
          ?.annual_due_certification_date
          ? datePickerFormat(
              propertyData.building_attributes[0].annual_due_certification_date
            )
          : null,
        buildingEra: findLabelByValue(
          buildingEraList,
          propertyData.building_attributes?.[0]?.property_building_era?.id
        ),
        buildingClass: findLabelByValue(
          buildingClassList,
          propertyData.building_attributes?.[0]?.property_building_class?.id
        ),
        buildingType: findLabelByValue(
          buildingTypeList,
          propertyData.building_attributes?.[0]?.property_building_type?.id
        ),
        buildingSize:
          propertyData.building_attributes?.[0]?.building_size || '',
        storiesAboveGround:
          propertyData.building_attributes?.[0]?.stories_above_ground || '',
        storiesBelowGround:
          propertyData.building_attributes?.[0]?.stories_below_ground || '',
        constructionClass: findLabelByValue(
          constructionClassList,
          propertyData.building_attributes?.[0]?.property_construction_class?.id
        ),
        buildingPart:
          propertyData.building_attributes?.[0]?.building_part || '',
        buildingTenancies:
          propertyData.building_attributes?.[0]?.building_tenancies_no || '',
        buildingStandards: findLabelByValue(
          buildingStandardList,
          propertyData.building_attributes?.[0]?.property_building_standard?.id
        ),
        occupancyPermitNumber:
          propertyData.occupancy_permit?.[0]?.op_number || '',
        occupancyPermitDate: propertyData.occupancy_permit?.[0]?.op_date
          ? datePickerFormat(propertyData.occupancy_permit[0]?.op_date)
          : null,
        occupancyPermitLocation:
          propertyData.occupancy_permit?.[0]?.op_location || '',
      });
    }
  }, [isOpen, formMode, propertyData, reset]);

  const onSubmit = (data) => {
    // Initialize nested objects
    let accessDetail = {};
    let siteRequirements = {};
    let occupancyPermit = {};
    let buildingAttributes = {};
    let contract_detail = {};

    // Conditionally add fields to accessDetail
    if (data.accessCode) accessDetail.access_code = data.accessCode;
    if (data.accessNote) accessDetail.access_note = data.accessNote;
    if (data.accessSchedule?.length) {
      accessDetail.property_access_schedule = data.accessSchedule.map(
        (accSchedule) => accSchedule?.value
      );
    }

    if (data.accessProcedure?.length) {
      accessDetail.property_access_procedure = data.accessProcedure.map(
        (accProcedure) => accProcedure?.value
      );
    }
    if (data.safetyRequirement)
      // Conditionally add fields to siteRequirements
      siteRequirements.technician_requirements = data.safetyRequirement;
    if (data.serviceRequirement)
      siteRequirements.service_requirements = data.serviceRequirement;
    if (data.signatureRequired !== undefined)
      siteRequirements.signature_required = data.signatureRequired;
    if (data.inductionRequired !== undefined)
      siteRequirements.induction_required = data.inductionRequired;
    if (data.logBookEntryRequired !== undefined)
      siteRequirements.log_book_entry_required = data.logBookEntryRequired;
    if (data.policeCheckRequired !== undefined)
      siteRequirements.police_check_required = data.policeCheckRequired;
    if (data.workingWithChildernCheckRequired !== undefined)
      siteRequirements.working_with_children_check_required =
        data.workingWithChildernCheckRequired;

    // Conditionally add fields to occupancyPermit
    if (data.occupancyPermitNumber)
      occupancyPermit.op_number = data.occupancyPermitNumber;
    if (data.occupancyPermitDate)
      occupancyPermit.op_date = formatDateAPI(data.occupancyPermitDate);
    if (data.occupancyPermitLocation)
      occupancyPermit.op_location = data.occupancyPermitLocation;

    // Conditionally add fields to buildingAttributes
    if (data.annualDueCertificateDate)
      buildingAttributes.annual_due_certification_date = formatDateAPI(
        data.annualDueCertificateDate
      );
    if (data.buildingSize)
      buildingAttributes.building_size = parseInt(data.buildingSize);
    if (data.storiesAboveGround)
      buildingAttributes.stories_above_ground = parseInt(
        data.storiesAboveGround
      );
    if (data.storiesBelowGround)
      buildingAttributes.stories_below_ground = parseInt(
        data.storiesBelowGround
      );
    if (data.buildingPart)
      buildingAttributes.building_part = parseInt(data.buildingPart);
    if (data.buildingTenancies)
      buildingAttributes.building_tenancies_no = data.buildingTenancies;

    if (data.buildingStandards)
      buildingAttributes.building_standard = data.buildingStandards?.value;

    if (data.buildingEra)
      buildingAttributes.building_era = data.buildingEra?.value;
    if (data.buildingClass)
      buildingAttributes.building_class = data.buildingClass?.value;
    if (data.buildingType)
      buildingAttributes.building_type = data.buildingType?.value;
    if (data.constructionClass)
      buildingAttributes.construction_class = data.constructionClass?.value;

    if (data.annualComplianceDate)
      contract_detail.annual_compliance_date = formatDateAPI(
        data.annualComplianceDate
      );
    if (data.contractStartDate)
      contract_detail.contract_start_date = formatDateAPI(
        data.contractStartDate
      );
    if (data.contractEndDate)
      contract_detail.contract_expiry_date = formatDateAPI(
        data.contractEndDate
      );
    if (data.technician)
      contract_detail.technician_uuid = data.technician?.value;

    // Prepare the main preparedData object
    const preparedData = {
      ...(data.propertyName ? { property_name: data.propertyName } : {}),
      customer_reference: 'customer reference came here',
      longitude: coords?.longitude?.toString() || '',
      latitude: coords?.latitude?.toString() || '',
      ...(data.propertyAddress ? { address: data.propertyAddress } : {}),
      ...(data.stateId || data.stateId?.value
        ? { state: data.stateId?.value || data.stateId }
        : {}),
      ...(data.cityId || data.cityId?.value
        ? { city: data.cityId?.value || data.cityId }
        : {}),
      ...(data.postCode ? { zip_code: data.postCode } : {}),
      ...(data.customerReference
        ? { customer_reference: data.customerReference }
        : {}),
      zone: data.zones?.value,
      is_booking_required: data?.isBookingRequired ? true : false,
      ...(data.ocspDpDnNumber ? { ocsp_number: data.ocspDpDnNumber } : {}),
      ...(data.organization ? { organization: data.organization } : {}),
      ...(data.localGovermentArea?.value
        ? { local_gov_area: data.localGovermentArea.value }
        : {}),
      ...(data.internalNote ? { internal_note: data.internalNote } : {}),
      ...(data.agmDate ? { agm_date: formatDateAPI(data.agmDate) } : {}),
      ...(data.reviewDate
        ? { review_date: formatDateAPI(data.reviewDate) }
        : {}),
      ...(data.landLotNumber ? { lot_number: data.landLotNumber } : {}),
      ...(data.parentPropertyId
        ? { parent_property: data.parentPropertyId?.value }
        : {}),
      ...(data.statusId?.value ? { status: data.statusId.value } : {}),
      ...(data.customerId?.value
        ? { customer_uuid: data.customerId.value }
        : {}),
      ...(data.accountManagerId?.value
        ? { account_manager: data.accountManagerId.value }
        : {}),
      ...(data.billingCardId?.value
        ? { billing_card: data.billingCardId.value }
        : {}),
      ...(data.tags?.length ? { tags: data.tags.map((tag) => tag.value) } : {}),
      ...(Object.keys(accessDetail).length
        ? { access_detail: accessDetail }
        : {}),
      ...(Object.keys(siteRequirements).length
        ? { site_requirements: siteRequirements }
        : {}),
      ...(Object.keys(occupancyPermit).length
        ? { occupancy_permit: occupancyPermit }
        : {}),
      ...(Object.keys(buildingAttributes).length
        ? { building_attributes: buildingAttributes }
        : {}),
      ...(Object.keys(contract_detail).length
        ? { contract_detail: contract_detail }
        : {}),
      property_contact_info: {
        name: data?.propertyManagerName,
        phone: data?.propertyManagerPhone,
        email: data?.propertyManagerEmail,
      },
    };

    // Dispatch the prepared data
    dispatch(
      formMode === 'edit'
        ? updateProperty({ uuid: propertyData?.uuid, data: preparedData })
        : createProperty(preparedData)
    ).then((res) => {
      if (res.payload?.errorDetails && res.payload.errorDetails?.length > 0) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: 'Something went wrong! Please check the data you entered or try again later.',
          })
        );
      }
    });
  };

  const handleNext = async () => {
    const isValid = await trigger(); // Trigger validation for all fields in the current step
    let hasError = false;

    if (activeStep === 0) {
      hasError = await validateAddress({
        address: address,
        city: city,
        state: state,
        postcode: postcode,
        fieldName: {
          addressKey: 'propertyAddress',
          addressValue: t('attributes.address'),
          stateKey: 'stateId',
          stateValue: t('common.state'),
          cityKey: 'cityId',
          cityValue: t('common.city'),
          postCodeKey: 'postCode',
          postCodeValue: t('attributes.postCode'),
        },
        setServerErrors: setServerErrors,
      });
    }

    if (hasError) return;

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    `${t('attributes.property.property')} ${t('attributes.company.details')}`,
    `${t('attributes.property.access')} ${t('attributes.company.details')}`,
    t('attributes.property.siteRequirement'),
    t('attributes.property.buildingAttributes'),
    t('attributes.property.occupancyPermit'),
  ];

  const handleStepClick = async (index) => {
    const isValid = await trigger(); // Optional: Validate the current step

    if (isValid) {
      setActiveStep(index); // Set the step index directly
    }
  };

  return (
    <LayoutContainer>
      {/* Custom Stepper */}
      <Box
        sx={{
          position: 'block',
          width: '259px',
          padding: '16px',
          background: SECONDARY,
          marginRight: '16px',
          borderRadius: '4px',
          height: '84vh',
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<CustomConnector />}
        >
          {steps.map((label, idx) => (
            <Step
              key={label}
              onClick={
                formMode === 'edit' ? () => handleStepClick(idx) : undefined
              }
              sx={{ cursor: formMode === 'edit' ? 'pointer' : 'default' }}
            >
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Typography
                  variant={idx <= activeStep ? 'body1' : 'body2'}
                  sx={{
                    color:
                      idx === activeStep ? theme.palette.primary.main : 'black',
                    cursor: formMode === 'edit' ? 'pointer' : 'default',
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        component="form"
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          maxHeight: '84vh',
          overflowY: 'auto',
          width: '100%',
          scrollbarWidth: 'none',
        }}
      >
        {/* Property details section */}
        <Box
          sx={{
            background: SECONDARY,
            borderRadius: '4px',
            width: '468px',
          }}
        >
          {activeStep === 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                background: DIVIDER_COLOR,
              }}
            >
              <Box>
                <Box
                  sx={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: SECONDARY,
                    borderRadius: '4px 4px 0 0',
                  }}
                >
                  <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                    {steps[0]}
                  </Typography>
                  {formMode === 'edit' && (
                    <Typography variant="h2" style={{ paddingRight: '16px' }}>
                      {t('attributes.property.propertyId')}: {propertyData?.pid}
                    </Typography>
                  )}
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    background: theme.palette.secondary.main,
                    flexDirection: 'column',
                    padding: '16px',
                    borderRadius: '0 0 4px 4px',
                    gap: '16px',
                  }}
                >
                  <Controller
                    name="propertyName"
                    control={control}
                    rules={{
                      required: `${t('attributes.property.property')} ${t('attributes.name')} ${Validation.general.required}`,
                      maxLength: {
                        value: 100,
                        message: Validation.property.name,
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('attributes.property.property')} ${t('attributes.name')}`}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('propertyName');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="propertyAddress"
                    control={control}
                    rules={{
                      required: `${t('attributes.address')} ${Validation.general.required}`,

                      maxLength: {
                        value: 300,
                        message: Validation.general.address,
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.address')}
                        multiline
                        rows={3}
                        value={value}
                        setBlur={setAddressBlur}
                        onChange={(e) => {
                          onChange(e);
                          trigger('propertyAddress');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <RowBox>
                    <Controller
                      name="stateId"
                      control={control}
                      rules={{
                        required: `${t('common.state')} ${Validation.general.required}`,
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          options={stateList}
                          fullWidth
                          label={t('common.state')}
                          setBlur={setStateBlur}
                          onChange={(e, newValue) => {
                            onChange(newValue);
                            trigger('stateId');
                            setValue('cityId', null);
                            clearErrors('cityId');
                          }}
                          value={value}
                          helperText={error ? error.message : ''}
                          onClear={() => clearTextfields(setValue, name)}
                          error={error}
                        />
                      )}
                    />
                    <Controller
                      name="cityId"
                      control={control}
                      rules={{
                        required: `${t('common.city')} ${Validation.general.required}`,
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          options={cityList}
                          label={t('common.city')}
                          setBlur={setCityBlur}
                          onChange={(e, newValue) => {
                            onChange(newValue);
                            trigger('cityId');
                          }}
                          value={value}
                          helperText={error ? error.message : ''}
                          error={error}
                          disabledDropdown={!selectedState}
                          onClear={() => clearTextfields(setValue, name)}
                        />
                      )}
                    />
                  </RowBox>
                  <Controller
                    name="postCode"
                    control={control}
                    rules={{
                      required: `${t('attributes.postCode')} ${Validation.general.required}`,
                      validate: (value) => {
                        if (value && !/^\d+$/.test(value)) {
                          return Validation.general.numericValue;
                        }

                        return true;
                      },
                      minLength: {
                        value: postCodeLength,
                        message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                      },
                      maxLength: {
                        value: postCodeLength,
                        message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        fullWidth
                        label={t('attributes.postCode')}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ''
                          );

                          onChange(numericValue);
                          trigger('postCode');
                        }}
                        value={value}
                        setBlur={setPostcodeBlur}
                        onClear={() => clearTextfields(setValue, name)}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="zones"
                    control={control}
                    rules={{
                      required: ` ${t('attributes.technician.zones')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={zoneList}
                        fullWidth
                        label="Zones"
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          trigger('zones');
                        }}
                        value={value}
                        helperText={error ? error.message : ''}
                        isLoadingData={isZoneLoading}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                      />
                    )}
                  />
                  <Controller
                    name="landLotNumber"
                    rules={{
                      maxLength: {
                        value: 20,
                        message: Validation.property.landLotNumber,
                      },
                    }}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        isRequired={false}
                        label={t('attributes.property.landLotNumber')}
                        onChange={(e) => {
                          onChange(e);
                          trigger('landLotNumber');
                        }}
                        value={value}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="statusId"
                    control={control}
                    rules={{
                      required: `${t('attributes.property.status')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={formStatusList}
                        fullWidth
                        label={t('attributes.property.status')}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          trigger('statusId');
                        }}
                        value={value}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Controller
                      name="customerId"
                      control={control}
                      rules={{
                        required: `${t('attributes.customer.customer')} ${Validation.general.required}`,
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          options={customerList}
                          useSearchIcon={true}
                          fullWidth
                          label={t('attributes.customer.customer')}
                          onChange={(e, newValue) => {
                            onChange(newValue);
                            trigger('customerId');
                          }}
                          value={value}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                  </Box>
                  <Controller
                    name="accountManagerId"
                    control={control}
                    rules={{
                      required: `${t('attributes.account_manager.account_manager')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={accountManagerList}
                        fullWidth
                        label={t('attributes.account_manager.account_manager')}
                        placeholder={t(
                          'attributes.account_manager.account_manager'
                        )}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          trigger('accountManagerId');
                        }}
                        value={value}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Controller
                      name="billingCardId"
                      control={control}
                      rules={{
                        required: `${t('attributes.property.billingCard')} ${Validation.general.required}`,
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          options={billingCardList}
                          fullWidth
                          label={t('attributes.property.billingCard')}
                          useSearchIcon={true}
                          onChange={(e, newValue) => {
                            onChange(newValue);
                            trigger('billingCardId');
                          }}
                          value={value}
                          helperText={error ? error.message : ''}
                          error={error}
                        />
                      )}
                    />
                  </Box>
                  <Controller
                    name="parentPropertyId"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={parentPropertyListData}
                        fullWidth
                        isRequired={false}
                        label={`${t('attributes.property.parent')} ${t('attributes.property.property')}`}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          trigger('parentPropertyId');
                        }}
                        value={value}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="ocspDpDnNumber"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 30,
                        message: Validation.property.ocspNumber,
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        fullWidth
                        label={t('attributes.property.ocspNumber')}
                        onChange={(e) => {
                          onChange(e);
                          trigger('ocspDpDnNumber');
                        }}
                        isRequired={false}
                        value={value}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="organization"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 100,
                        message: Validation.property.organization,
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        fullWidth
                        isRequired={false}
                        label={t('attributes.property.organization')}
                        onClear={() => {
                          onChange('');
                        }}
                        onChange={(e) => {
                          onChange(e);
                          trigger('organization');
                        }}
                        value={value}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <CustomDatePicker
                    control={control}
                    name="agmDate"
                    isRequired={false}
                    label={t('attributes.property.agmDate')}
                    pickerType="date"
                  />
                  <Controller
                    name="localGovermentArea"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={localGovermentAreaList}
                        fullWidth
                        isRequired={false}
                        label={t('attributes.property.localGovArea')}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          trigger('localGovermentArea');
                        }}
                        value={value}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="internalNote"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 65535,
                        message: Validation.property.internalNote,
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        multiline
                        rows={2}
                        fullWidth
                        isRequired={false}
                        label={t('attributes.property.internalNote')}
                        onChange={(e) => {
                          onChange(e);
                          trigger('internalNote');
                        }}
                        value={value}
                        onClear={() => {
                          onChange('');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="tags"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={tagsList}
                        fullWidth
                        isRequired={false}
                        multiple={true}
                        label={t('attributes.property.tags')}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          trigger('tags');
                        }}
                        value={value}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <CustomDatePicker
                    control={control}
                    name="reviewDate"
                    label={t('attributes.property.reviewDate')}
                    pickerType="date"
                    isRequired={false}
                  />
                </Box>
              </Box>

              <Box>
                <Box
                  sx={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: SECONDARY,
                    borderRadius: '4px 4px 0 0',
                  }}
                >
                  <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                    {t('attributes.property.contractAndAssignmentDetails')}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    background: theme.palette.secondary.main,
                    flexDirection: 'column',
                    padding: '16px',
                    borderRadius: '0 0 4px 4px',
                    gap: '16px',
                  }}
                >
                  <CustomDatePicker
                    control={control}
                    name="annualComplianceDate"
                    label={t('attributes.property.annualComplianceDate')}
                    pickerType="date"
                    isRequired={true}
                    trigger={trigger}
                  />
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CustomDatePicker
                      control={control}
                      name="contractStartDate"
                      label={t('attributes.property.contractStartDate')}
                      pickerType="date"
                      isRequired={true}
                      trigger={trigger}
                    />
                    <CustomDatePicker
                      control={control}
                      name="contractEndDate"
                      label={t('attributes.property.contractEndDate')}
                      pickerType="date"
                      isRequired={true}
                      trigger={trigger}
                    />
                  </Box>
                  <Controller
                    name="technician"
                    control={control}
                    rules={{
                      required: `${t('attributes.property.defaultTechnician')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={technicianDropdownData}
                        fullWidth
                        label={t('attributes.property.defaultTechnician')}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          trigger('technician');
                        }}
                        value={value}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box>
                <Box
                  sx={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: SECONDARY,
                    borderRadius: '4px 4px 0 0',
                  }}
                >
                  <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                    {t('attributes.property.propertyManager')}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    background: theme.palette.secondary.main,
                    flexDirection: 'column',
                    padding: '16px',
                    borderRadius: '0 0 4px 4px',
                    gap: '16px',
                  }}
                >
                  <Controller
                    name="propertyManagerName"
                    control={control}
                    rules={{
                      required: `${t('attributes.property.contact')} ${t('attributes.name')} ${Validation.general.required}`,
                      maxLength: {
                        value: 100,
                        message: Validation.property.name,
                      },
                    }}
                    render={({
                      field: { onChange, value, onBlur },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('attributes.property.contact')} ${t('attributes.name')}`}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('propertyManagerName');
                        }}
                        onClear={() => {
                          onChange('');
                        }}
                        onBlur={() => {
                          onChange(value.trim());
                          onBlur();
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    name="propertyManagerEmail"
                    control={control}
                    rules={{
                      required: `${t('attributes.email')} ${Validation.general.required}`,
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: Validation.general.validEmail,
                      },
                      maxLength: {
                        value: 254,
                        message: Validation.general.email,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('attributes.property.contact')} ${t('attributes.email')}`}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('propertyManagerEmail');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                      />
                    )}
                  />
                  <Controller
                    name="propertyManagerPhone"
                    control={control}
                    rules={{
                      required: `Contact phone ${Validation.general.required}`,
                      pattern: {
                        value: /^\d{10}$/,
                        message: Validation.general.contactPhone,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('attributes.property.contact')} ${t('attributes.phone')}`}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ''
                          );

                          onChange(numericValue);
                          trigger('propertyManagerPhone');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearTextfields(setValue, name)}
                        isRequired={true}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {activeStep === 1 && (
            <Box>
              <Box
                sx={{
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                  {steps[1]}
                </Typography>
                {formMode === 'edit' && (
                  <Typography variant="h2" style={{ paddingRight: '16px' }}>
                    {t('attributes.property.propertyId')}: {propertyData?.pid}
                  </Typography>
                )}
              </Box>
              <Divider />
              <Section>
                <Controller
                  name="accessSchedule"
                  control={control}
                  rules={{
                    required: `${t('attributes.property.access')} ${t('attributes.property.schedule')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={accessScheduleList}
                      label={`${t('attributes.property.access')} ${t('attributes.property.schedule')}`}
                      multiple={true}
                      value={value}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        trigger('accessSchedule');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="accessProcedure"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={accessProcedureList}
                      isRequired={false}
                      multiple={true}
                      label={`${t('attributes.property.access')} ${t('attributes.property.procedure')}`}
                      value={value}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        trigger('accessProcedure');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="accessCode"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 15,
                      message: Validation.property.accessCode,
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={`${t('attributes.property.access')} ${t('attributes.code')}`}
                      isRequired={false}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('accessCode');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="accessNote"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 4000,
                      message: Validation.property.accessNote,
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      multiline
                      rows={2}
                      isRequired={false}
                      label={`${t('attributes.property.access')} ${t('attributes.note')}`}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('accessNote');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
              </Section>
            </Box>
          )}
          {activeStep === 2 && (
            <Box>
              <Box
                sx={{
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                  {steps[2]}
                </Typography>
                {formMode === 'edit' && (
                  <Typography variant="h2" style={{ paddingRight: '16px' }}>
                    {t('attributes.property.propertyId')}: {propertyData?.pid}
                  </Typography>
                )}
              </Box>
              <Divider />
              <Section>
                <Box sx={{ gap: 0, display: 'flex', flexDirection: 'column' }}>
                  <CommonCheckbox
                    control={control}
                    name="signatureRequired"
                    label={`${t('attributes.property.signature')} ${t('attributes.required')}`}
                    isRequired={false}
                  />
                  <CommonCheckbox
                    control={control}
                    name="inductionRequired"
                    label={`${t('attributes.property.induction')} ${t('attributes.required')}`}
                    isRequired={false}
                  />
                  <CommonCheckbox
                    control={control}
                    name="logBookEntryRequired"
                    label={`${t('attributes.property.logBookEntry')} ${t('attributes.required')}`}
                    isRequired={false}
                  />
                  <CommonCheckbox
                    control={control}
                    name="policeCheckRequired"
                    label={`${t('attributes.property.policeCheck')} ${t('attributes.required')}`}
                    isRequired={false}
                  />
                  <CommonCheckbox
                    control={control}
                    name="isBookingRequired"
                    label={`${t('attributes.property.isBooking')} ${t('attributes.required')}`}
                    isRequired={false}
                  />
                  <CommonCheckbox
                    control={control}
                    name="workingWithChildernCheckRequired"
                    label={`${t('attributes.property.workingWithChildren')} ${t('attributes.required')}`}
                    isRequired={false}
                  />
                </Box>
                <Controller
                  name="serviceRequirement"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 65535,
                      message: Validation.property.serviceRequirements,
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={`${t('attributes.property.service')} ${t('attributes.requirements')}`}
                      isRequired={false}
                      multiline
                      rows={2}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('serviceRequirement');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="safetyRequirement"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 65535,
                      message: Validation.property.safetyRequirements,
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={`${t('attributes.property.safety')} ${t('attributes.requirements')}`}
                      multiline
                      rows={2}
                      isRequired={false}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('safetyRequirement');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
              </Section>
            </Box>
          )}
          {activeStep === 3 && (
            <Box>
              <Box
                sx={{
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                  {steps[3]}
                </Typography>
                {formMode === 'edit' && (
                  <Typography variant="h2" style={{ paddingRight: '16px' }}>
                    {t('attributes.property.propertyId')}: {propertyData?.pid}
                  </Typography>
                )}
              </Box>
              <Divider />
              <Section>
                <CustomDatePicker
                  control={control}
                  name="annualDueCertificateDate"
                  label={t('attributes.property.annualDueCertificateDate')}
                  pickerType="date"
                />
                <Controller
                  name="buildingEra"
                  control={control}
                  rules={{
                    required: `${t('attributes.property.buildingEra')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={buildingEraList}
                      label={t('attributes.property.buildingEra')}
                      value={value}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        trigger('buildingEra');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="buildingClass"
                  control={control}
                  rules={{
                    required: `${t('attributes.property.buildingClass')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={buildingClassList}
                      label={t('attributes.property.buildingClass')}
                      value={value}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        trigger('buildingClass');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="buildingType"
                  control={control}
                  rules={{
                    required: `${t('attributes.property.buildingType')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={buildingTypeList}
                      label={t('attributes.property.buildingType')}
                      value={value}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        trigger('buildingType');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="buildingSize"
                  rules={{
                    maxLength: {
                      value: 40,
                      message: Validation.property.buildingSize,
                    },
                    validate: (value) => {
                      if (value && !/^\d+$/.test(value)) {
                        return Validation.general.numericValue;
                      }

                      return true;
                    },
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.property.buildingSize')}
                      isRequired={false}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('buildingSize');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="buildingSizeSqm"
                  rules={{
                    maxLength: {
                      value: 20,
                      message: Validation.property.buildingSizeSqm,
                    },
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.property.buildingSizeSqm')}
                      isRequired={false}
                      value={SQFT_TO_SQM(watch('buildingSize'))}
                      onChange={(e) => {
                        onChange(e);
                        trigger('buildingSizeSqm');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      disabled={true}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="storiesAboveGround"
                  control={control}
                  rules={{
                    required: `${t('attributes.property.storyAboveGround')} ${Validation.general.required}`,
                    maxLength: {
                      value: 3,
                      message: Validation.property.storyAboveGround,
                    },
                    validate: (value) => {
                      if (value && !/^\d+$/.test(value)) {
                        return Validation.general.numericValue;
                      }

                      return true;
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.property.storyAboveGround')}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('storiesAboveGround');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="storiesBelowGround"
                  rules={{
                    required: `${t('attributes.property.storyBelowGround')} ${Validation.general.required}`,
                    maxLength: {
                      value: 3,
                      message: Validation.property.storyBelowGround,
                    },
                    validate: (value) => {
                      if (value && !/^\d+$/.test(value)) {
                        return Validation.general.numericValue;
                      }

                      return true;
                    },
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.property.storyBelowGround')}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('storiesBelowGround');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="constructionClass"
                  control={control}
                  rules={{
                    required: `${t('attributes.property.constructionClass')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={constructionClassList}
                      label={t('attributes.property.constructionClass')}
                      value={value}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        trigger('constructionClass');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="buildingPart"
                  rules={{
                    validate: (value) => {
                      if (value && !/^\d+$/.test(value)) {
                        return Validation.general.numericValue;
                      }

                      return true;
                    },
                    maxLength: {
                      value: 200,
                      message: Validation.property.buildingPart,
                    },
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.property.buildingPart')}
                      isRequired={false}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('buildingPart');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="buildingTenancies"
                  rules={{
                    maxLength: {
                      value: 200,
                      message: Validation.property.buildingTenancies,
                    },
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.property.buildingTenancies')}
                      isRequired={false}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('buildingTenancies');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="buildingStandards"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={buildingStandardList}
                      label={t('attributes.property.buildingStandards')}
                      isRequired={false}
                      value={value}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        trigger('buildingStandards');
                      }}
                    />
                  )}
                />
              </Section>
            </Box>
          )}
          {activeStep === 4 && (
            <Box>
              <Box
                sx={{
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h2" style={{ paddingLeft: '16px' }}>
                  {steps[4]}
                </Typography>
                {formMode === 'edit' && (
                  <Typography variant="h2" style={{ paddingRight: '16px' }}>
                    {t('attributes.property.propertyId')}: {propertyData?.pid}
                  </Typography>
                )}
              </Box>
              <Divider />
              <Section>
                <Controller
                  name="occupancyPermitNumber"
                  rules={{
                    maxLength: {
                      value: 30,
                      message: Validation.property.occupancyPermitNumber,
                    },
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={`${t('attributes.property.occupancyPermit')} ${t('attributes.number')}`}
                      isRequired={false}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('occupancyPermitNumber');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <CustomDatePicker
                  control={control}
                  name="occupancyPermitDate"
                  label={`${t('attributes.property.occupancyPermit')} ${t('attributes.date')}`}
                  pickerType="date"
                />
                <Controller
                  name="occupancyPermitLocation"
                  rules={{
                    maxLength: {
                      value: 300,
                      message: Validation.property.occupancyPermitLocation,
                    },
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={`${t('attributes.property.occupancyPermit')} ${t('attributes.location')}`}
                      isRequired={false}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('occupancyPermitLocation');
                      }}
                      onClear={() => {
                        onChange('');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
              </Section>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: '54px',
          width: '100%',
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          backgroundColor: SECONDARY,
          padding: '16px',
          gap: 2,
        }}
      >
        {activeStep !== 0 && (
          <CustomButton
            startIcon={<KeyboardArrowLeftIcon />}
            text={t('attributes.previous')}
            color="inherit"
            disabled={activeStep === 0 || createLoading || updateLoading}
            onClick={handleBack}
          />
        )}

        {activeStep === steps.length - 1 ? (
          <CustomButton
            startIcon={<SaveIcon />}
            text={t('attributes.save')}
            disabled={createLoading || updateLoading}
            onClick={handleSubmit(onSubmit)} // Attach the form submission handler
            type="submit" // Ensure the button is of type submit
          />
        ) : (
          <CustomButton
            endIcon={<ChevronRightIcon />}
            text={t('common.next')}
            onClick={handleNext}
          />
        )}
      </Box>
    </LayoutContainer>
  );
};
