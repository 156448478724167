import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { SECONDARY, WARNING } from '../../../../constants/Colors';
import { Validation } from '../../../../constants/FieldValidationMsg';
import getCityByStateHook from '../../../../hooks/getCityByStateHook';
import getDropdownListHook from '../../../../hooks/getDropdownListHook';
import useAddressValidationHook from '../../../../hooks/useAddressValidationHook';
import { uploadDocument } from '../../../../store/CommonAPI';
import { clearTextfields } from '../../../../utils';
import Autocomplete from '../../../CommonComponents/AutoComplete';
import CommonCheckbox from '../../../CommonComponents/CustomCheckbox';
import CustomDatePicker from '../../../CommonComponents/CustomDatePicker';
import CustomFileUploadButton from '../../../CommonComponents/CustomFileUploadButton';
import CustomTextField from '../../../CommonComponents/CustomTextField';
import { ConfirmationModal } from '../../../CommonComponents/Modal';
import UploadedDocumentView from '../../../CommonComponents/UploadedDocumentView';

const CustomerDetails = ({
  title,
  control,
  setValue,
  setError,
  trigger,
  stateList,
  Section,
  watch,
  customerId,
  formMode,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [documentType, setDocumentType] = useState('');

  const { isDocumentLoading, uploadedSuccessfully, documentData } = useSelector(
    (state) => state.common
  );

  const [uploadedDocuments, setUploadedDocuments] = useState({
    agreement: null,
    other: null,
  });
  const [agreementError, setAgreementError] = useState('');
  const [otherError, setOtherError] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [isUploadingAgreement, setIsUploadingAgreement] = useState(false);
  const [isUploadingOther, setIsUploadingOther] = useState(false);
  const [addressRecheck, setAddressRecheck] = useState(true);

  const selectedState = watch('state');
  const cityList = getCityByStateHook(selectedState?.value);
  const {
    customerDropdownLoading: categoryDropdownLoading,
    customerDropdownData: categoryDropdownData,
  } = getDropdownListHook({
    reducerName: 'customer',
    dropdownListName: 'customerCategoryDropdownList',
    labelName: 'name',
    valueName: 'uuid',
  });

  const handleFileChange = (type) => (event) => {
    const file = event.target.files[0];
    const isValidFile = file && file.type === 'application/pdf';

    setDocumentType(type);
    if (isValidFile) {
      setIsUploadingAgreement(type === 'agreement');
      setIsUploadingOther(type === 'other');
      setAddressRecheck(false);
      const fileSize = (file.size / 1024).toFixed(0) + ' KB';

      setUploadedDocuments((prev) => ({
        ...prev,
        [type]: { name: file.name, size: fileSize },
      }));
      type === 'agreement' ? setAgreementError('') : setOtherError('');

      dispatch(
        uploadDocument({ document_type: type, uploaded_by: 1, file: file })
      );
    } else {
      type === 'agreement'
        ? setAgreementError('Invalid file type. Please upload a PDF.')
        : setOtherError('Invalid file type. Please upload a PDF.');
    }
  };

  useEffect(() => {
    if (!isDocumentLoading && uploadedSuccessfully) {
      setIsUploadingAgreement(false);
      setIsUploadingOther(false);

      const handleDocument = (document, documentData, documentType) => {
        if (typeof document === 'object' && document?.document_uuid) {
          setValue(`${document.document_type}Document`, {
            document_uuid: document?.document_uuid,
            document_type: document?.document_type,
            document_name: document?.document_name,
            document_size: document?.document_size,
          });
          setError(`${document.document_type}Document`, '');
        } else if (typeof document === 'string') {
          setValue(`${documentType}Document`, {
            document_uuid: documentData?.uuid,
            document_type: documentData?.document_type,
            document_name: documentData?.document_name,
            document_size: uploadedDocuments?.size,
          });
          setError(`${documentType}Document`, '');
        }
      };

      handleDocument(watch(`agreementDocument`), documentData, 'agreement');
      handleDocument(watch(`otherDocument`), documentData, 'other');

      setAddressRecheck(false);
    }
  }, [isDocumentLoading, uploadedSuccessfully]);

  const handleDeleteDocument = (type) => {
    setAddressRecheck(false);
    setUploadedDocuments((prev) => ({
      ...prev,
      [type]: null,
    }));

    setValue(`${type}Document`, null);
    const input = document.querySelector(`input[name="${type}Document"]`);

    if (input) {
      input.value = ''; // Reset the file input
    }

    type === 'agreement' ? setAgreementError('') : setOtherError('');
  };

  const [addressRegisteredBlur, setRegisteredAddressBlur] = useState(false);
  const [stateRegisteredBlur, setRegisteredStateBlur] = useState(false);
  const [cityRegisteredBlur, setRegisteredCityBlur] = useState(false);
  const [postcodeRegisteredBlur, setRegisteredPostcodeBlur] = useState(false);
  const registerAddress = watch('registeredAddress');
  const registerState = watch('state');
  const registerCity = watch('city');
  const registerPostcode = watch('customerPostcode');

  useEffect(() => {
    if (registerAddress && registerState && registerCity && registerPostcode) {
      setRegisteredAddressBlur(true);
      setRegisteredStateBlur(true);
      setRegisteredCityBlur(true);
      setRegisteredPostcodeBlur(true);
      setAddressRecheck(true);
    }
  }, [registerAddress, registerState, registerCity, registerPostcode]);

  useAddressValidationHook({
    address: registerAddress,
    addressBlur: addressRegisteredBlur,
    state: registerState,
    stateBlur: stateRegisteredBlur,
    clearErrors,
    city: registerCity,
    cityBlur: cityRegisteredBlur,
    setError,
    postcode: registerPostcode,
    postcodeBlur: postcodeRegisteredBlur,
    addressRecheck,
    fieldName: {
      addressKey: 'registeredAddress',
      addressValue: t('attributes.address'),
      stateKey: 'state',
      stateValue: t('common.state'),
      cityKey: 'city',
      cityValue: t('common.city'),
      postCodeKey: 'customerPostcode',
      postCodeValue: t('attributes.postCode'),
    },
  });

  return (
    <>
      {/* Contact details */}
      <Box sx={{ background: SECONDARY, borderRadius: '4px' }}>
        <Box>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="h2">{title}</Typography>
          </Box>
          <Divider />
          <Section>
            <Controller
              name="customer_name"
              control={control}
              rules={{
                required: `Customer Name ${Validation.general.required}`,
                maxLength: {
                  value: 80,
                  message: Validation.general.customerName,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Customer Name"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('customer_name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
            <Controller
              name="abnNumber"
              control={control}
              rules={{
                required: `ABN Number ${Validation.general.required}`,
                pattern: {
                  value: /^\d{11}$/,
                  message: Validation.general.abnNumber,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="ABN Number"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('abnNumber');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
            <Controller
              name="category"
              control={control}
              rules={{ required: `Category ${Validation.general.required}` }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label="Category"
                  options={categoryDropdownData}
                  value={value}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    trigger('category');
                  }}
                  isLoadingData={categoryDropdownLoading}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
            <CustomDatePicker
              control={control}
              name="agreementSignedDate"
              isRequired={true}
              label="Agreement Signed Date"
              pickerType="date"
              defaultValue={null}
              trigger={trigger}
            />
            <Controller
              name="registeredAddress"
              control={control}
              rules={{
                required: `Registered Address ${Validation.general.required}`,
                maxLength: {
                  value: 300,
                  message: Validation.general.address,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Registered Address"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('registeredAddress');
                  }}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  setBlur={setRegisteredAddressBlur}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
            <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
              <Controller
                name="state"
                control={control}
                rules={{
                  required: `State ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label="State"
                    options={stateList}
                    value={value}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                      trigger('state');
                      clearErrors('city');
                      setValue('city', null);
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    setBlur={setRegisteredStateBlur}
                    onClear={() => clearTextfields(setValue, name)}
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                rules={{
                  required: `City ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label="City"
                    options={cityList}
                    value={value}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                      trigger('city');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    disabledDropdown={!selectedState}
                    setBlur={setRegisteredCityBlur}
                  />
                )}
              />
            </Box>
            <Controller
              name="customerPostcode"
              control={control}
              rules={{
                required: `Postal code ${Validation.general.required}`,
                pattern: {
                  value: /^\d{4}$/,
                  message: Validation.general.postCode,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Post Code"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('customerPostcode');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  setBlur={setRegisteredPostcodeBlur}
                />
              )}
            />
            <Controller
              name="notes"
              control={control}
              rules={{
                maxLength: {
                  value: 65535,
                  message: Validation.general.notes,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Notes"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('notes');
                  }}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
          </Section>
        </Box>
      </Box>

      {customerId && formMode === 'edit' && (
        <Box
          sx={{
            background: SECONDARY,
            borderRadius: '4px',
            width: '468px',
          }}
        >
          <Box sx={{ padding: '16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label="Active"
              isRequired={false}
              showMessage={true}
              messageOnChecked="Customer will be moved to active"
              messageOnUnchecked="Customer will be moved to inactive"
            />
          </Box>
        </Box>
      )}

      {/* Attachment Form */}
      <Box
        sx={{
          background: SECONDARY,
          borderRadius: '4px',
          width: '468px',
        }}
      >
        <Box sx={{ padding: '16px' }}>
          <Typography variant="h2">
            Attach Agreement Documents
            <span style={{ color: WARNING }}>*</span>
          </Typography>
        </Box>
        <Divider />
        <Section>
          <Controller
            name="agreementDocument"
            control={control}
            rules={{
              required: `${t('attributes.superAdmin.agreement_document')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, name, value },
              fieldState: { error },
            }) => (
              <>
                <CustomFileUploadButton
                  name={name}
                  onFileChange={(event) => {
                    handleFileChange('agreement')(event);
                    onChange(event);
                    trigger('agreementDocument');
                  }}
                  multiple={false}
                  buttonText="Click to upload or drag and drop"
                  accept=".pdf"
                  error={!!agreementError || error}
                  errorMessage={agreementError || error?.message}
                />
                {!isEmpty(value) && (
                  <UploadedDocumentView
                    fileName={
                      isUploadingAgreement
                        ? uploadedDocuments?.agreement?.name
                        : value?.document_name || ''
                    }
                    fileSize={
                      isUploadingAgreement
                        ? uploadDocument?.agreement?.name
                        : value?.document_size || ''
                    }
                    isUploading={isUploadingAgreement}
                    onDelete={() => {
                      setOpenConfirmationModal(true);
                      setDocumentType('agreement');
                    }}
                  />
                )}
              </>
            )}
          />
        </Section>
      </Box>

      {/* Other Documents */}
      <Box
        sx={{
          background: SECONDARY,
          borderRadius: '4px',
          width: '468px',
        }}
      >
        <Box sx={{ padding: '16px' }}>
          <Typography variant="h2">Other Documents</Typography>
        </Box>
        <Divider />
        <Section>
          <Controller
            name="otherDocument"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <>
                <CustomFileUploadButton
                  name={name}
                  onFileChange={(event) => {
                    handleFileChange('other')(event);
                    onChange(event);
                    trigger('otherDocument');
                  }}
                  multiple={false}
                  buttonText="Click to upload or drag and drop"
                  accept=".pdf"
                  error={!!otherError}
                  errorMessage={otherError}
                />
                {!isEmpty(value) && (
                  <UploadedDocumentView
                    fileName={
                      isUploadingOther
                        ? uploadedDocuments?.other?.name
                        : value?.document_name || ''
                    }
                    fileSize={
                      isUploadingOther
                        ? uploadDocument?.other?.name
                        : value?.document_size || ''
                    }
                    isUploading={isUploadingOther}
                    onDelete={() => {
                      setOpenConfirmationModal(true);
                      setDocumentType('other');
                    }}
                  />
                )}
              </>
            )}
          />
        </Section>
      </Box>

      {openConfirmationModal && (
        <ConfirmationModal
          title="Delete Document"
          description="Are you sure you want to delete this document?"
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            handleDeleteDocument(documentType);
            setOpenConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

export default CustomerDetails;
