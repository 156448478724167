// Get Me
export const GET_ME = 'GET_ME';

// Company
export const COMPANY = {
  GET_COMPANY_LIST: 'GET_COMPANY_LIST',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  CREATE_COMPANY: 'CREATE_COMPANY',
  EDIT_COMPANY: 'EDIT_COMPANY',
  GET_COMPANY_DROPDOWN_LIST: 'GET_COMPANY_DROPDOWN_LIST',
};

// Supplier
export const SUPPLIER = {
  GET_SUPPLIER_LIST: 'GET_SUPPLIER_LIST',
  UPDATE_SUPPLIER: 'UPDATE_SUPPLIER',
  CREATE_SUPPLIER: 'CREATE_SUPPLIER',
  EDIT_SUPPLIER: 'EDIT_SUPPLIER',
  GET_SUPPLIER_DROPDOWN_LIST: 'GET_SUPPLIER_DROPDOWN_LIST',
};

// Product Catalogue
export const PRODUCT_CATALOGUE = {
  GET_PRODUCT_CATALOGUE_LIST: 'GET_PRODUCT_CATALOGUE_LIST',
  GET_PRODUCT_DETAILS: 'GET_PRODUCT_DETAILS',
  CREATE_PRODUCT_CATALOGUE: 'CREATE_PRODUCT_CATALOGUE',
  UPDATE_PRODUCT_CATALOGUE: 'UPDATE_PRODUCT_CATALOGUE',
};

// Country State City
export const GET_STATE_CITY_LIST = 'GET_STATE_CITY_LIST';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';

//Super admins
export const SUPER_ADMIN = {
  GET_SUPER_ADMINS: 'GET_SUPER_ADMINS',
  GET_SUPER_ADMIN_BY_ID: 'GET_SUPER_ADMIN_BY_ID',
  UPDATE_SUPER_ADMIN: 'UPDATE_SUPER_ADMIN',
  CREATE_SUPER_ADMIN: 'CREATE_SUPER_ADMIN',
  GET_WIDGETS: 'GET_WIDGETS',
};

//Properties
export const PROPERTIES = {
  GET_PROPERTY: 'GET_PROPERTY',
  GET_PROPERTIES: 'GET_PROPERTIES',
  GET_PROPERTIES_MAP: 'GET_PROPERTIES_MAP',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  CREATE_PROPERTY: 'CREATE_PROPERTY',
  // status
  GET_STATUS_LIST: 'GET_STATUS_LIST',
  // Tags
  GET_TAG_LIST: 'GET_TAG_LIST',
  // Access schedule
  GET_ACCESS_SCHEDULE_LIST: 'GET_ACCESS_SCHEDULE_LIST',
  // Access procedure
  GET_ACCESS_PROCEDURE_LIST: 'GET_ACCESS_PROCEDURE_LIST',
  // Building era
  GET_BUILDING_ERA_LIST: 'GET_BUILDING_ERA_LIST',
  // Building class
  GET_BUILDING_CLASS_LIST: 'GET_BUILDING_CLASS_LIST',
  // Construction list
  GET_CONSTRUCTION_LIST: 'GET_CONSTRUCTION_LIST',
  // Building type list
  GET_BUILDING_TYPE_LIST: 'GET_BUILDING_TYPE_LIST',
  // Building standard list
  GET_BUILDING_STANDARD_LIST: 'GET_BUILDING_STANDARD_LIST',
  // Local governmant area
  GET_LOCAL_GOVERNMENT_AREA_LIST: 'GET_LOCAL_GOVERNMENT_AREA_LIST',
  // Parent Property List
  GET_PARENT_PROPERTY_LIST: 'GET_PARENT_PROPERTY_LIST',
  // Get property account manager list
  GET_PROPERTY_ACCOUNT_MANAGER_LIST: 'GET_PROPERTY_ACCOUNT_MANAGER_LIST',
  // Property customer list
  GET_PROPERTY_CUSTOMER_LIST: 'GET_PROPERTY_CUSTOMER_LIST',
  // Property billing card list
  GET_PROPERTY_BILLING_CARD_LIST: 'GET_PROPERTY_BILLING_CARD_LIST',
  GET_PROPERTY_ASSETS_LIST: 'GET_PROPERTY_ASSETS_LIST',
  CREATE_ASSET: 'CREATE_ASSET',
  UPDATE_ASSET: 'UPDATE_ASSET',
  GET_ASSET_DETAILS: 'GET_ASSET_DETAILS',
  GET_EQUIPEMENT_TYPE: 'GET_EQUIPEMENT_TYPE',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_ASSET_STATUS: 'GET_ASSET_STATUS',
  GET_PROPERTY_ROUTINE_LIST: 'GET_PROPERTY_ROUTINE_LIST',
  UPDATE_ROUTINE: 'UPDATE_ROUTINE',
  GET_COMPLIANCE_STATUS: 'GET_COMPLIANCE_STATUS',
};

// Customers
export const CUSTOMER = {
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMER_BY_ID: 'GET_CUSTOMER_BY_ID',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  GET_CUSTOMER_CATEGORY_DROPDOWN_LIST: 'GET_CUSTOMER_CATEGORY_DROPDOWN_LIST',

  GET_QUOTES_LIST: 'GET_QUOTES_LIST',
  GET_QUOTES_TYPE: 'GET_QUOTES_TYPE',
  GET_QUOTES_STATUS: 'GET_QUOTES_STATUS',
  GET_QUOTES_PROPERTYNAME: 'GET_QUOTES_PROPERTYNAME',

  GET_INVOICE_LIST: 'GET_INVOICE_LIST',
  GET_INVOICE_TYPE: 'GET_INVOICE_TYPE',
  GET_INVOICE_STATUS: 'GET_INVOICE_STATUS',
  GET_INVOICE_PROPERTYNAME: 'GET_INVOICE_PROPERTYNAME',
};

//Super admins
export const TECHNICIAN = {
  GET_TECHNICIANS: 'GET_TECHNICIANS',
  GET_TECHNICIAN_BY_ID: 'GET_TECHNICIAN_BY_ID',
  UPDATE_TECHNICIAN: 'UPDATE_TECHNICIAN',
  CREATE_TECHNICIAN: 'CREATE_TECHNICIAN',
  // Tags
  GET_TAGS_LIST: 'GET_TAGS_LIST',
  // Skills
  GET_SKILLS_LIST: 'GET_SKILLS_LIST',
  // Zones
  GET_ZONES_LIST: 'GET_ZONES_LIST',
  // Service Area
  GET_SERVICE_AREA_LIST: 'GET_SERVICE_AREA',

  GET_TECHNICIAN_NAMES: 'GET_TECHNICIAN_NAMES',
};

// Category
export const CATEGORY = {
  GET_CATEGORY_DROPDOWN_LIST: 'GET_CATEGORY_DROPDOWN_LIST',
};

// Skills
export const SKILLS = {
  GET_SKILLS_DROPDOWN_LIST: 'GET_SKILLS_DROPDOWN_LIST',
};

// Zones
export const ZONES = {
  GET_ZONES_DROPDOWN_LIST: 'GET_ZONES_DROPDOWN_LIST',
};

export const SERVICE_AREAS = {
  GET_SERVICE_AREAS_DROPDOWN_LIST: 'GET_SERVICE_AREAS_DROPDOWN_LIST',
};

// Account Manager
export const ACCOUNT_MANAGER = {
  GET_ACCOUNT_MANAGER_DROPDOWN_LIST: 'GET_ACCOUNT_MANAGER_DROPDOWN_LIST',
  GET_ACCOUNT_MANAGER_COMMON_DROPDOWN_LIST:
    'GET_ACCOUNT_MANAGER_COMMON_DROPDOWN_LIST',
};

// Office Staff
export const OFFICE_STAFF = {
  GET_OFFICE_STAFFS: 'GET_OFFICE_STAFFS',
  GET_OFFICE_STAFF_BY_ID: 'GET_OFFICE_STAFF_BY_ID',
  UPDATE_OFFICE_STAFF: 'UPDATE_OFFICE_STAFF',
  CREATE_OFFICE_STAFF: 'CREATE_OFFICE_STAFF',
};

// Work Order
export const WORK_ORDER = {
  GET_WORK_ORDERS: 'GET_WORK_ORDERS',
  GET_WORK_ORDER: 'GET_WORK_ORDER',
};
