import { createSlice } from '@reduxjs/toolkit';

import { getWorkOrder, getWorkOrderList } from './api';

const initialGetData = {
  isLoading: true,
  error: null,
  workOrderList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};
const initialDetails = {
  isLoading: true,
  error: null,
  workOrder: {},
};

const workOrderSlice = createSlice({
  name: 'workOrder',
  initialState: {
    get: { ...initialGetData },
    details: { ...initialDetails },
  },
  reducers: {
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
    resetWorkOrderData(state) {
      state.details = { ...initialDetails };
    },
  },
  extraReducers: (builder) => {
    builder
      // WorkOrder List
      .addCase(getWorkOrderList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getWorkOrderList.fulfilled, (state, action) => {
        state.get.isLoading = false;

        state.get.workOrderList = action.payload.data;

        state.get.total = action.payload.pagination.total_items;
        state.get.totalCount = action.payload.pagination.total_count;
      })
      .addCase(getWorkOrderList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Get Workorder
      .addCase(getWorkOrder.pending, (state) => {
        state.details.isLoading = true;
      })
      .addCase(getWorkOrder.fulfilled, (state, action) => {
        state.details.isLoading = false;
        state.details.workOrder = action.payload?.data?.length
          ? action.payload?.data[0]
          : {};
      })
      .addCase(getWorkOrder.rejected, (state, action) => {
        state.details.isLoading = false;
        state.details.error = action.payload;
      });
  },
});

export const { resetPagination, resetWorkOrderData } = workOrderSlice.actions;

export default workOrderSlice.reducer;
