import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { SWIPEABLE_DRAWER_WIDTH } from '../../constants/Typography';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import { getRoutineList, updateRoutine } from '../../store/property/api';
import { resetRoutineList } from '../../store/property/reducer';
import { formattedDate } from '../../utils';
import { Edit } from '../CommonComponents/ActionComponent';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import { FilterSection } from '../CommonComponents/FilterComponent';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import RoutineForm from './RoutineForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  equipmentType: null,
  product: null,
  status: null,
  active: null,
};

const RoutineList = ({ propertyId }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [searchText, setSearchText] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [open, setOpen] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);
  const [routineId, setRoutineId] = useState('');

  const {
    data: updatedRoutine,
    loading: updatedRoutineLoading,
    error: updatedRoutineError,
  } = useSelector((state) => state.property.updatedRoutine);

  const { routinesList } = useSelector((state) => state.property);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    trigger,
    setError,
    clearErrors,
  } = useForm();

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'routine_name',
        headerName: 'Routines',
        flex: 1,
        sortable: true,
      },
      {
        field: 'annual_maintenance_date',
        headerName: 'Annual Compliance Date',
        flex: 1,
        sortable: false,
      },
      {
        field: 'contract_expiry_date',
        headerName: t('attributes.property.contractEndDate'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'Frequency',
        headerName: 'Frequency',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '4px',
            }}
          >
            {row.frequency.map((item) => (
              <Box
                sx={{
                  px: '16px',
                  py: '4px',
                  border: '1px solid black',
                  borderRadius: '24px',
                }}
              >
                {item}
              </Box>
            ))}
          </Box>
        ),
      },
      {
        field: 'edit',
        headerName: 'Edit',
        flex: 1,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setOpen(true);
              setRoutineId(row?.id);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const getAllRoutineList = useCallback(() => {
    if (!propertyId) {
      return;
    }
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');
    const req = {
      order: order,
      orderBy: orderBy,
      page: currentPage,
      size: perPageData,
      search: searchText,
      property_id: propertyId,
      list_columns_names: visibleFieldsString,
    };

    dispatch(getRoutineList(req)).finally(() => {});
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    propertyId,
  ]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllRoutineList, 500), [
    getAllRoutineList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    if (serverErrors) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const rows = routinesList?.data
    ? routinesList?.data?.map((item) => ({
        ...item,
        routine_name: item.routine_types.display_name,
        frequency: item.frequencies_list,
        annual_maintenance_date: formattedDate(
          item?.property_standard?.annual_maintenance_date
        ),
        contract_expiry_date: formattedDate(
          item?.property_standard?.contract_expiry_date
        ),
      }))
    : null;

  useEffect(() => {
    if (updatedRoutineLoading !== null && !updatedRoutineLoading) {
      if (updatedRoutineError) {
        if (updatedRoutineError.errorDetails) {
          setServerErrors(updatedRoutineError.errorDetails);
        }
      } else {
        if (updatedRoutine) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: updatedRoutine.message,
            })
          );
        }
      }
    }
  }, [updatedRoutineError, updatedRoutineLoading]);

  useEffect(
    () => () => {
      dispatch(resetRoutineList());
    },
    []
  );

  const resetFilter = () => {
    setSearchText('');
  };

  const onSubmit = (data) => {
    const requestData = {
      property_uuid: propertyId,
      is_active: data?.active,
    };

    dispatch(updateRoutine({ routineId: routineId, data: requestData }));
  };

  return (
    <>
      <StyledMainWrapper
        btn={
          <>
            <CustomButton
              text="Export"
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {}}
            />
            <CustomButton
              text="Coming soon..."
              color="secondary"
              sx={{ height: '52%' }}
              onClick={() => {}}
            />
          </>
        }
        isSubDetails={true}
      >
        <Box sx={{ paddingTop: '16px' }}>
          <FilterSection
            onFilterBtnClick={() => {}}
            isRefresh={false}
            isFilter={false}
            isReset={false}
            searchText={searchText}
            onResetFilter={resetFilter}
            onSearchChange={(e) => setSearchText(e.target.value)}
            isResetButtonVisible={searchText}
            sx={{ marginRight: '16px' }}
          />
          <CustomGridTable
            columns={columns}
            rows={rows}
            total={routinesList?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPageData={perPageData}
            setPerPageData={setPerPageData}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            noData={<NoRecordFound />}
            isLoading={routinesList?.loading}
            paginationRequired={false}
            filterHeight={filterHeight(isFilterComponentVisible)}
          />
        </Box>
      </StyledMainWrapper>

      {open && (
        <SwipeableDrawer
          open={open}
          title={'Edit Routine'}
          onClose={() => {
            setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.edit')}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={true}
            />
          }
          width={SWIPEABLE_DRAWER_WIDTH}
        >
          <RoutineForm
            id={routineId}
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default RoutineList;
