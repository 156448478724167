import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const CustomCircularLoader = ({ ...rest }) => (
  <Box
    sx={{
      display: 'flex',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress {...rest} />
  </Box>
);

export default CustomCircularLoader;
